<template>
  <section class="SHFjOP">
    <div class="wrapper">
      <div class="BgdvZg">
        <h2 class="SusZuB aos-init " data-aos="fade-up" id="title" :class="{'aos-animate': isVisibleTitle}">AMLBot’s services</h2>
        <p class="LBlJfW aos-init" data-aos="fade-up" data-aos-delay="100" id="description" :class="{'aos-animate': isVisibleDescription}">
          We provide full pack of options for safe work with crypto
        </p>
      </div>
      <div class="w4kXtB">
        <div
          class="WyuUXz wqvKe4 is-hovered aos-init"
          data-aos="fade-up"
          data-aos-delay="0"
          id="screening"
          :class="{'aos-animate': isVisibleScreening}"
        >
          <div class="tsamQD">
            <img
              alt=""
              loading="lazy"
              width="144"
              height="139"
              decoding="async"
              data-nimg="1"
              style="color: transparent"
              src="../../assets/image/services/icon-1.webp"
            />
          </div>
          <h3 class="E_P4Gn">AML/KYT screening</h3>
          <p class="aBoVgz">
            API solutions that empower AML compliance tools within your current
            system. All transactions are automatically verified to comply with
            AML and FATF requirements and reduce your business risk exposure.
          </p>
          <div class="_0xK5Yr">
            <button class="i6oszE _8dDVll DvWUkr hX3WJv jyhTNG ayslAO">
              <span class="pOE1_y">Learn more</span
              ><svg class="icon icon-arrow-right-long mj71Cs">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-arrow-right-long"
                ></use>
              </svg>
            </button>
          </div>
        </div>
        <div
          class="WyuUXz is-hovered aos-init"
          data-aos="fade-up"
          data-aos-delay="100"
          id="business"
          :class="{'aos-animate': isVisibleBusiness}"
        >
          <div class="tsamQD">
            <img
              alt=""
              loading="lazy"
              width="120"
              height="120"
              decoding="async"
              data-nimg="1"
              style="color: transparent"
              src="../../assets/image/services/icon-2.webp"
            />
          </div>
          <h3 class="E_P4Gn">KYC for business</h3>
          <p class="aBoVgz">
            The streamlined and automated verification process empowers your
            business to swiftly onboard customers, reducing manual effort and
            mitigating identity fraud and illicit activity risks.
          </p>
          <div class="_0xK5Yr">
            <button class="i6oszE _8dDVll DvWUkr hX3WJv zkITkJ ayslAO">
              <span class="pOE1_y">Learn more</span
              ><svg class="icon icon-arrow-right-long mj71Cs">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-arrow-right-long"
                ></use>
              </svg>
            </button>
          </div>
        </div>
        <div
          class="WyuUXz is-hovered aos-init"
          data-aos="fade-up"
          data-aos-delay="200"
          id="procedures"
          :class="{'aos-animate': isVisibleProcedures}"
        >
          <div class="tsamQD">
            <img
              alt=""
              loading="lazy"
              width="120"
              height="120"
              decoding="async"
              data-nimg="1"
              style="color: transparent"
              src="../../assets/image/services/icon-3.webp"
            />
          </div>
          <h3 class="E_P4Gn">AML/KYC procedures</h3>
          <p class="aBoVgz">
            Launch your crypto venture with ease, simplicity, and confidence
            through our streamlined AML and KYC consulting, ensuring smooth
            compliance and effective risk management right from the beginning.
          </p>
          <div class="_0xK5Yr">
            <button class="i6oszE _8dDVll DvWUkr hX3WJv zkITkJ ayslAO">
              <span class="pOE1_y">Learn more</span
              ><svg class="icon icon-arrow-right-long mj71Cs">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-arrow-right-long"
                ></use>
              </svg>
            </button>
          </div>
        </div>
        <div
          class="WyuUXz is-hovered aos-init"
          data-aos="fade-up"
          data-aos-delay="300"
          id="account"
          :class="{'aos-animate': isVisibleAccount}"
        >
          <div class="tsamQD">
            <img
              alt=""
              loading="lazy"
              width="120"
              height="120"
              decoding="async"
              data-nimg="1"
              style="color: transparent"
              src="../../assets/image/services/icon-4.webp"
            />
          </div>
          <h3 class="E_P4Gn">Corporate accounts at CEX/EMI</h3>
          <p class="aBoVgz">
            Streamline corporate account opening on CEX EMI with our expert
            assistance, ensuring your focus remains on business growth in the
            crypto industry.
          </p>
          <div class="_0xK5Yr">
            <button class="i6oszE _8dDVll DvWUkr hX3WJv zkITkJ ayslAO">
              <span class="pOE1_y">Learn more</span
              ><svg class="icon icon-arrow-right-long mj71Cs">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-arrow-right-long"
                ></use>
              </svg>
            </button>
          </div>
        </div>
        <div
          class="WyuUXz is-hovered aos-init"
          data-aos="fade-up"
          data-aos-delay="400"
          id="blockChain"
          :class="{'aos-animate': isVisibleBlockChain}"
        >
          <div class="tsamQD">
            <img
              alt=""
              loading="lazy"
              width="120"
              height="120"
              decoding="async"
              data-nimg="1"
              style="color: transparent"
              src="../../assets/image/services/icon-5.webp"
            />
          </div>
          <h3 class="E_P4Gn">Blockchain investigations</h3>
          <p class="aBoVgz">
            Recover stolen cryptocurrencies with AMLBot's expert blockchain
            investigations, swiftly identifying culprits and tracing funds for
            effective recovery.
          </p>
          <div class="_0xK5Yr">
            <button class="i6oszE _8dDVll DvWUkr hX3WJv zkITkJ ayslAO">
              <span class="pOE1_y">Learn more</span
              ><svg class="icon icon-arrow-right-long mj71Cs">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-arrow-right-long"
                ></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:['bottom'],
  data: function(){
    return {
      isVisibleTitle: false,
      isVisibleDescription: false,
      isVisibleScreening: false,
      isVisibleBusiness: false,
      isVisibleProcedures: false,
      isVisibleAccount: false,
      isVisibleBlockChain: false,
    }
  },
  watch: {
    bottom() {
      if(this.bottom > this.locationTitle) {
        this.isVisibleTitle = true;
      } else {
        this.isVisibleTitle = false;
      }
      
      if(this.bottom > this.locationDescription) {
        this.isVisibleDescription = true;
      } else {
        this.isVisibleDescription = false
      }
      
      if(this.bottom > this.locationScreening) {
        this.isVisibleScreening = true;
      } else {
        this.isVisibleScreening = false
      }
      
      if(this.bottom > this.locationBusiness) {
        this.isVisibleBusiness = true;
      } else {
        this.isVisibleBusiness = false
      }
      
      if(this.bottom > this.locationProcedures) {
        this.isVisibleProcedures = true;
      } else {
        this.isVisibleProcedures = false
      }
      
      if(this.bottom > this.locationAccount) {
        this.isVisibleAccount = true;
      } else {
        this.isVisibleAccount = false
      }
      
      if(this.bottom > this.locationBlockChain) {
        this.isVisibleBlockChain = true;
      } else {
        this.isVisibleBlockChain = false
      }

    }
  },
  computed: {
    locationTitle: function(){
      return document.getElementById('title').getBoundingClientRect().top + window.scrollY;
    },
    locationDescription: function(){
      return document.getElementById('description').getBoundingClientRect().top + window.scrollY;
    },
    locationScreening: function(){
      return document.getElementById('screening').getBoundingClientRect().top + window.scrollY;
    },
    locationBusiness: function(){
      return document.getElementById('business').getBoundingClientRect().top + window.scrollY;
    },
    locationProcedures: function(){
      return document.getElementById('procedures').getBoundingClientRect().top + window.scrollY;
    },
    locationAccount: function(){
      return document.getElementById('account').getBoundingClientRect().top + window.scrollY;
    },
    locationBlockChain: function(){
      return document.getElementById('blockChain').getBoundingClientRect().top + window.scrollY;
    },
  },
};
</script>

<style>
.is-hovered {
    cursor: pointer;
}
</style>
