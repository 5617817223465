<template>
  <section class="_FWdkg" id="pricing">
    <div class="wrapper">
      <div class="EuKPwk is-hovered aos-init " data-aos="fade-up" id="Card" :class="{'aos-animate': isVisibleCard}">
        <h2 class="cjQTrl">How much for your assurance?</h2>
        <div class="VgxtIY">First check is free</div>
        <h3 class="fcT9Xw">From</h3>
        <div class="iVEqa5">
          <div class="WcSR9l"><span>$0.2</span></div>
          <div class="_4M4S2R">
            /
            per additional check
          </div>
        </div>
        <div>
          <button class="i6oszE _8dDVll DvWUkr hX3WJv ZsJpqW">
            <span class="pOE1_y">Contact us</span
            ><svg class="icon icon-arrow-right-long mj71Cs">
              <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="#icon-arrow-right-long"
              ></use>
            </svg>
          </button>
        </div>
      </div>
      <div class="Pd5DNa aos-init" data-aos="fade-up" id="Suspicious" :class="{'aos-animate': isVisibleSuspicious}">
        Per our data, <b>one in four wallets is suspicious.</b><br />
        Spending a few dollars on a check may save you a large sum.
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:['bottom'],
  data: function(){
    return {
      isVisibleCard: false,
      isVisibleSuspicious: false
    }
  },
  watch: {
    bottom() {
      if(this.bottom > this.locationCard) {
        this.isVisibleCard = true;
      } else {
        this.isVisibleCard = false;
      }
      
      if(this.bottom > this.locationSuspicious) {
        this.isVisibleSuspicious = true;
      } else {
        this.isVisibleSuspicious = false
      }

    }
  },
  computed: {
    locationCard: function(){
      return document.getElementById('Card').getBoundingClientRect().top + window.scrollY;
    },
    locationSuspicious: function(){
      return document.getElementById('Suspicious').getBoundingClientRect().top + window.scrollY;
    },
  },
};
</script>

<style></style>
