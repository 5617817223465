<template>
  <section class="rPccI9">
    <div class="wrapper">
      <div class="_HJUe6">
        <div class="_gbILZ Z9kYYb ouA_zO">
          <div class="u10kYh aos-init " data-aos="fade-up" id="money" :class="{'aos-animate': isVisibleMoney}">
            <div class="_6TP_rh">
              <h3 class="FqgwEj osr_7e">+$100 000 000</h3>
            </div>
            <p class="_9TO9Vw">Amount of the risky funds detected</p>
          </div>
        </div>
        <div class="_gbILZ Z9kYYb ouA_zO">
          <div class="u10kYh aos-init" data-aos="fade-up" data-aos-delay="50" id="compliance" :class="{'aos-animate': isVisibleCompliance}">
            <div class="_6TP_rh">
              <div class="XCdSZ_">
                <div>
                  <img
                    alt="Binance"
                    loading="lazy"
                    width="140"
                    height="29"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent"
                    src="../../assets/media/logo/binance-white.aed1452d.svg"
                  />
                </div>
                <div>
                  <img
                    alt="OKX"
                    loading="lazy"
                    width="72"
                    height="29"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent"
                    src="../../assets/media/logo/okx-white.32ece2b9.svg"
                  />
                </div>
                <div>
                  <img
                    alt="Huobi"
                    loading="lazy"
                    width="88"
                    height="29"
                    decoding="async"
                    data-nimg="1"
                    style="color: transparent"
                    src="../../assets/media/logo/huobi-white.ef112ac0.svg"
                  />
                </div>
              </div>
            </div>
            <p class="_9TO9Vw">
              Compliance departments that accept our AML procedures
            </p>
          </div>
        </div>
        <div class="_gbILZ Z9kYYb ouA_zO">
          <div class="u10kYh aos-init" data-aos="fade-up" data-aos-delay="100" id="check" :class="{'aos-animate': isVisibleCheck}">
            <div class="_6TP_rh"><h4 class="FqgwEj">60,000+</h4></div>
            <p class="_9TO9Vw">Service providers checked</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:['bottom'],
  data: function(){
    return {
      isVisibleMoney: false,
      isVisibleCompliance: false,
      isVisibleCheck: false,
    }
  },
  watch: {
    bottom() {
      if(this.bottom > this.locationMoney) {
        this.isVisibleMoney = true;
      } else {
        this.isVisibleMoney = false;
      }
      
      if(this.bottom > this.locationCompliance) {
        this.isVisibleCompliance = true;
      } else {
        this.isVisibleCompliance = false
      }
      
      if(this.bottom > this.locationCheck) {
        this.isVisibleCheck = true;
      } else {
        this.isVisibleCheck = false
      }

    }
  },
  computed: {
    locationMoney: function(){
      return document.getElementById('money').getBoundingClientRect().top + window.scrollY;
    },
    locationCompliance: function(){
      return document.getElementById('compliance').getBoundingClientRect().top + window.scrollY;
    },
    locationCheck: function(){
      return document.getElementById('check').getBoundingClientRect().top + window.scrollY;
    },
  },

};
</script>

<style></style>
