<template>
  <HeadConfig></HeadConfig>
  <whole-body></whole-body>
  <!-- <MainHeader></MainHeader>
  <MainBody></MainBody>
  <main-footer></main-footer> -->
  <!-- <test-main></test-main> -->
</template>

<script>
import { mapState } from "vuex";
import HeadConfig from './components/HeaderConfig.vue'
import WholeBody from './components/WholeBody.vue'
export default {
  name: 'App',
  components: {
    // TestMain
    // MainHeader,
    // MainBody,
    // MainFooter,
    HeadConfig,
    WholeBody
  },
}
</script>

<style>
</style>
