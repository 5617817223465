<template>
    <div class="_5lwoaH" style="opacity: 1; height: auto; transform: none">
      <div class="OoyxQH">
        <nav class="v_giwW">
          <div class="EA5Veh _43cB57" :class="{'AxRzrA': isOpened}">
            <button
              class="Ym5531"
              style="opacity: 1; transform: none"
              @click="toggleProducts()"
            >
              Products
            </button>
            <div
              class="SsdHEB"
              :class="{'open': isOpened, 'close': !isOpened}"
            >
              <div>
                <div class="UL6BM7">
                  <div class="wNFb4j">
                    <div>
                      <div class="fgrZ76">
                        <a
                          target="_blank"
                          class="msQoC3"
                          href="https://t.me/cryptoaml_bot?start=6690eada293fc849133602"
                        >
                          Chat Bot
                        </a>
                      </div>
                      <div class="fgrZ76">
                        <a class="msQoC3" href="/api-integration">KYT</a>
                      </div>
                      <div class="fgrZ76">
                        <a class="msQoC3" href="/training">AML Training</a>
                      </div>
                      <div class="fgrZ76">
                        <a class="msQoC3" href="/crypto-compliance-consulting"
                          >Consulting</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="wNFb4j">
                    <div>
                      <div class="fgrZ76">
                        <a
                          target="_blank"
                          class="msQoC3"
                          href="https://web.amlbot.com/signin?utm_term=amlbot&amp;utm_campaign=Search_Brand_08.03.24&amp;utm_source=googleads&amp;utm_medium=ppc&amp;hsa_acc=4547405139&amp;hsa_cam=21086036855&amp;hsa_grp=159272070866&amp;hsa_ad=693001814616&amp;hsa_src=g&amp;hsa_tgt=kwd-1026849843563&amp;hsa_kw=amlbot&amp;hsa_mt=e&amp;hsa_net=adwords&amp;hsa_ver=3&amp;gad_source=1&amp;gclid=CjwKCAjw7s20BhBFEiwABVIMrcJjXKIj5IdAj0W5aCtRtFCX9eEcsHaPZKSRC_34bGdPPwNVgKzGoxoCQZkQAvD_BwE"
                          >AMLBot App</a
                        >
                      </div>
                      <div class="fgrZ76">
                        <a class="msQoC3" href="https://kyc.amlbot.com">KYC</a>
                      </div>
                      <div class="fgrZ76">
                        <div class="msQoC3 _1bviie">AMLBot Pro</div>
                      </div>
                      <div class="fgrZ76">
                        <a class="msQoC3" href="/reclaim-crypto">Investigation</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="EA5Veh" style="opacity: 1; transform: none">
            <a class="Ym5531" href="/#how-it-works" @click="toggleDrawal">How does it work?</a>
          </div>
          <div class="EA5Veh" style="opacity: 1; transform: none">
            <a class="Ym5531" href="/#pricing" @click="toggleDrawal">Pricing</a>
          </div>
          <div class="EA5Veh" style="opacity: 1; transform: none">
            <a class="Ym5531" href="/what-do-we-analyze" @click="toggleDrawal">Risks</a>
          </div>
          <div class="EA5Veh" style="opacity: 1; transform: none">
            <a class="Ym5531" href="/#faq" @click="toggleDrawal">FAQ</a>
          </div>
          <div class="EA5Veh" style="opacity: 1; transform: none">
            <a class="Ym5531" href="https://blog.amlbot.com" @click="toggleDrawal">Blog</a>
          </div>
          <div class="EA5Veh" style="opacity: 1; transform: none">
            <a class="Ym5531" href="/about-us" @click="toggleDrawal">About Us</a>
          </div>
        </nav>
        <nav class="Vj3dC0" style="opacity: 1; transform: none">
          <a
            rel="noopener noreferrer nofollow"
            class="i6oszE yMZqNV JxhfJ_ interact-button"
            ><span class="pOE1_y">Check Wallet</span></a
          >
        </nav>
      </div>
    </div>
  </template>
  <script>
  export default {
      props: [
          'toggleDrawal'
      ],
    data: function () {
      return {
        isOpened: false,
      };
    },
  };
  </script>
  <style>
      .open {
          height: 283px !important; transition: all 0.25s ease 0s
      }
      .close {
          height: 0px; transition: all 0.25s ease 0s
      }
      .Vj3dC0 {
      flex-direction: column;
      gap: .8rem;
      margin-top: 4rem;
  }
  </style>