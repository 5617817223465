<template>
  <body
    class="bg-light-gray"
    data-aos-easing="ease"
    data-aos-duration="400"
    data-aos-delay="0"
  >
    <div id="hs-web-interactives-top-push-anchor" class="go3670563033"></div>
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-NB2RTM9"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe
    ></noscript>
    <div id="__next">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style="position: absolute; width: 0; height: 0; visibility: hidden"
      >
        <symbol id="icon-checkmark-circle" viewBox="0 0 256 256">
          <path
            d="M128,10C62.8,10,10,62.8,10,128c0,65.2,52.8,118,118,118c65.2,0,118-52.8,118-118C246,62.8,193.2,10,128,10z M200.1,94.1l-80.9,79.2c-0.4,0.7-0.9,1.4-1.6,2l-3.8,3.8c-2.1,2.1-4.6,3-5.5,2.1l-38.5-40.3c-0.9-0.9,0-3.4,2.1-5.5l3.8-3.8c2.1-2.1,4.6-3,5.5-2.1l28.9,30.2l78.7-77c2.1-2.1,5.5-2.1,7.6,0l3.8,3.8C202.2,88.6,202.2,92,200.1,94.1z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-right-long" viewBox="0 0 20 10">
          <path
            d="M19.7709,4.2907422 C19.7706,4.2905122 19.7704,4.2902322 19.7702,4.2900022 L15.688,0.227502195 C15.3821,-0.0768378048 14.8875,-0.0757078048 14.5831,0.230152195 C14.2787,0.535972195 14.2799,1.0306222 14.5857,1.3350022 L17.3265,4.0625022 L0.78125,4.0625022 C0.349766,4.0625022 0,4.4122522 0,4.8437522 C0,5.2752522 0.349766,5.6249522 0.78125,5.6249522 L17.3264,5.6249522 L14.5857,8.3524522 C14.2799,8.6568522 14.2788,9.1515522 14.5831,9.4573522 C14.8875,9.7632522 15.3822,9.7642522 15.688,9.4599522 L19.7702,5.3974522 C19.7704,5.3972522 19.7706,5.3969522 19.7709,5.3967522 C20.0769,5.0913522 20.0759,4.5951522 19.7709,4.2907422 Z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-left-long" viewBox="0 0 20 10">
          <path
            d="M0.153482779,4.37823078 L0.229825153,4.2900022 L4.31202515,0.227502195 C4.61792515,-0.0768378048 5.11252515,-0.0757078048 5.41692515,0.230152195 C5.72132515,0.535972195 5.72012515,1.0306222 5.41432515,1.3350022 L2.67352515,4.0625022 L19.2187752,4.0625022 C19.6502592,4.0625022 20.0000252,4.4122522 20.0000252,4.8437522 C20.0000252,5.2752522 19.6502592,5.6249522 19.2187752,5.6249522 L2.67362515,5.6249522 L5.41432515,8.3524522 C5.72012515,8.6568522 5.72122515,9.1515522 5.41692515,9.4573522 C5.11252515,9.7632522 4.61782515,9.7642522 4.31202515,9.4599522 L0.229125153,5.3967522 C-0.0490566647,5.11911583 -0.0735194747,4.68379352 0.153482779,4.37823078 Z"
          ></path>
        </symbol>
        <symbol id="icon-facebook-fill-circle" viewBox="0 0 28 28">
          <path
            d="M14,0 C6.28043,0 0,6.28043 0,14 C0,21.719 6.28043,28 14,28 C21.719,28 28,21.719 28,14 C28,6.28043 21.7201,0 14,0 Z"
            fill="#1D1D1D"
          ></path>
          <path
            d="M17.4817,14.4929 L15.204,14.4929 L15.204,22.6111 L11.8289,22.6111 C11.8289,22.6111 11.8289,18.1753 11.8289,14.4929 L10.2245,14.4929 L10.2245,11.6236 L11.8289,11.6236 L11.8289,9.76774 C11.8289,8.43857 12.4605,6.36164 15.235,6.36164 L17.736,6.37122 L17.736,9.15645 C17.736,9.15645 16.2162,9.15645 15.9207,9.15645 C15.6252,9.15645 15.2051,9.3042 15.2051,9.93805 L15.2051,11.6242 L17.7766,11.6242 L17.4817,14.4929 Z"
            fill="#FFFFFF"
          ></path>
        </symbol>
        <symbol id="icon-telegram-fill-circle" viewBox="0 0 28 28">
          <path
            d="M14,28 C21.7338,28 28,21.7338 28,14 C28,6.26617 21.7338,0 14,0 C6.26617,0 0,6.26617 0,14 C0,21.7338 6.26617,28 14,28 Z"
          ></path>
          <path
            d="M6.40617,13.6967 L19.9045,8.49217 C20.531,8.26583 21.0782,8.645 20.8752,9.59233 L20.8763,9.59117 L18.578,20.419 C18.4077,21.1867 17.9515,21.3733 17.3133,21.0117 L13.8133,18.4322 L12.1252,20.0585 C11.9385,20.2452 11.781,20.4027 11.4193,20.4027 L11.6678,16.8408 L18.1545,10.9807 C18.4368,10.7322 18.0915,10.5922 17.7193,10.8395 L9.70317,15.8865 L6.2475,14.8085 C5.49733,14.5705 5.481,14.0583 6.40617,13.6967 Z"
            fill="#fff"
          ></path>
        </symbol>
        <symbol id="icon-telegram" viewBox="0 0 22 19">
          <path
            d="M8.63240987,12.0829924 L8.26849987,17.2015924 C8.78915987,17.2015924 9.01465987,16.9779924 9.28507987,16.7093924 L11.7261999,14.3764924 L16.7842999,18.0806924 C17.7119999,18.5976924 18.3655999,18.3254924 18.6157999,17.2272924 L21.9359999,1.66962235 L21.9368999,1.66870235 C22.2311999,0.297371354 21.4409999,-0.238878146 20.5371999,0.0975383542 L1.02132987,7.56929235 C-0.310587131,8.08629235 -0.290420131,8.82879235 0.794912869,9.16520235 L5.78432987,10.7170924 L17.3736999,3.46537235 C17.9191999,3.10420235 18.4150999,3.30404235 18.0071999,3.66520235 L8.63240987,12.0829924 Z"
          ></path>
        </symbol>
        <symbol id="icon-linkedin-fill-circle" viewBox="0 0 28 28">
          <path
            d="M14,0 C6.2692,0 0,6.2692 0,14 C0,21.7308 6.2692,28 14,28 C21.7308,28 28,21.7308 28,14 C28,6.2692 21.7308,0 14,0 Z"
          ></path>
          <polygon
            fill="#FFFFFF"
            points="9.93176 21.1641 6.52213 21.1641 6.52213 10.9061 9.93176 10.9061"
          ></polygon>
          <path
            d="M8.22705,9.50537 L8.20483,9.50537 C7.06067,9.50537 6.32068,8.71774 6.32068,7.73337 C6.32068,6.72678 7.08331,5.96094 8.24969,5.96094 C9.41608,5.96094 10.1339,6.72678 10.1561,7.73337 C10.1561,8.71774 9.41608,9.50537 8.22705,9.50537 Z"
            fill="#FFFFFF"
          ></path>
          <path
            d="M22.2262,21.1641 L18.817,21.1641 L18.817,15.6763 C18.817,14.2971 18.3233,13.3566 17.0896,13.3566 C16.1478,13.3566 15.5868,13.991 15.3403,14.6035 C15.2501,14.8227 15.2281,15.129 15.2281,15.4355 L15.2281,21.1641 L11.8187,21.1641 C11.8187,21.1641 11.8633,11.8685 11.8187,10.9061 L15.2281,10.9061 L15.2281,12.3585 C15.6812,11.6595 16.4919,10.6653 18.3009,10.6653 C20.5441,10.6653 22.2262,12.1314 22.2262,15.2822 L22.2262,21.1641 Z"
            fill="#FFFFFF"
          ></path>
        </symbol>
        <symbol id="icon-linkedin-fill-rect" viewBox="0 0 21 20">
          <path
            d="M18.5195,0 L1.47656,0 C0.66016,0 0,0.644531 0,1.44141 L0,18.5547 C0,19.3516 0.66016,20 1.47656,20 L18.5195,20 C19.3359,20 20,19.3516 20,18.5586 L20,1.44141 C20,0.644531 19.3359,0 18.5195,0 Z M5.93359,17.043 L2.96484,17.043 L2.96484,7.49609 L5.93359,7.49609 L5.93359,17.043 Z M4.44922,6.19531 C3.49609,6.19531 2.72656,5.42578 2.72656,4.47656 C2.72656,3.52734 3.49609,2.75781 4.44922,2.75781 C5.39844,2.75781 6.16797,3.52734 6.16797,4.47656 C6.16797,5.42188 5.39844,6.19531 4.44922,6.19531 Z M17.043,17.043 L14.0781,17.043 L14.0781,12.4023 C14.0781,11.2969 14.0586,9.87109 12.5352,9.87109 C10.9922,9.87109 10.7578,11.0781 10.7578,12.3242 L10.7578,17.043 L7.79688,17.043 L7.79688,7.49609 L10.6406,7.49609 L10.6406,8.80078 L10.6797,8.80078 C11.0742,8.05078 12.043,7.25781 13.4844,7.25781 C16.4883,7.25781 17.043,9.23438 17.043,11.8047 L17.043,17.043 Z"
          ></path>
        </symbol>
        <symbol id="icon-instagram-fill-circle" viewBox="0 0 28 28">
          <circle fill="#1D1D1D" cx="14" cy="14" r="14"></circle>
          <path
            d="M17.9997,6 L9.9999,6 C7.80015,6 6,7.80015 6,9.9999 L6,18.0001 C6,20.1993 7.80015,22 9.9999,22 L17.9997,22 C20.1995,22 21.9996,20.1993 21.9996,18.0001 L21.9996,9.9999 C21.9996,7.80015 20.1995,6 17.9997,6 Z M20.6662,18.0001 C20.6662,19.4701 19.4705,20.6666 17.9997,20.6666 L9.9999,20.6666 C8.52974,20.6666 7.33337,19.4701 7.33337,18.0001 L7.33337,9.9999 C7.33337,8.52955 8.52974,7.33337 9.9999,7.33337 L17.9997,7.33337 C19.4705,7.33337 20.6662,8.52955 20.6662,9.9999 L20.6662,18.0001 Z"
            fill="#FFFFFF"
          ></path>
          <path
            d="M18.332,10.666 C18.8843,10.666 19.332,10.2183 19.332,9.66599 C19.332,9.11372 18.8843,8.66602 18.332,8.66602 C17.7797,8.66602 17.332,9.11372 17.332,9.66599 C17.332,10.2183 17.7797,10.666 18.332,10.666 Z"
            fill="#FFFFFF"
          ></path>
          <path
            d="M13.9999,10 C11.7903,10 10,11.7905 10,13.9999 C10,16.2084 11.7903,18.0002 13.9999,18.0002 C16.2088,18.0002 17.9998,16.2084 17.9998,13.9999 C17.9998,11.7905 16.2088,10 13.9999,10 Z M13.9999,16.6668 C12.5273,16.6668 11.3334,15.4729 11.3334,13.9999 C11.3334,12.5269 12.5273,11.3334 13.9999,11.3334 C15.4725,11.3334 16.6664,12.5269 16.6664,13.9999 C16.6664,15.4729 15.4725,16.6668 13.9999,16.6668 Z"
            fill="#FFFFFF"
          ></path>
        </symbol>
        <symbol id="icon-reddit-fill-circle" viewBox="0 0 28 28">
          <circle cx="14" cy="14" r="14"></circle>
          <path
            d="M21.1991,14.1078 C21.1631,13.2438 20.4431,12.5598 19.5611,12.5778 C19.1651,12.5958 18.8051,12.7578 18.5351,13.0098 C17.3111,12.1638 15.8531,11.7138 14.3591,11.6778 L15.0611,8.31183 L17.3831,8.77983 C17.4551,9.37383 17.9771,9.80583 18.5711,9.73383 C19.1651,9.66183 19.5971,9.13983 19.5251,8.54583 C19.4531,7.95183 18.9311,7.51983 18.3371,7.59183 C17.9951,7.62783 17.6891,7.82583 17.5271,8.11383 L14.8811,7.59183 C14.7011,7.55583 14.5211,7.66383 14.4851,7.84383 L13.6931,11.6058 C12.1811,11.6238 10.7231,12.0918 9.46314,12.9378 C8.83314,12.3438 7.82514,12.3618 7.23114,13.0098 C6.63714,13.6398 6.65514,14.6478 7.30314,15.2418 C7.42914,15.3498 7.57314,15.4578 7.73514,15.5298 C7.71714,15.6918 7.71714,15.8538 7.73514,15.9978 C7.73514,18.4278 10.5611,20.4078 14.0531,20.4078 C17.5451,20.4078 20.3711,18.4458 20.3711,15.9978 C20.3891,15.8358 20.3891,15.6738 20.3711,15.5298 C20.8751,15.2778 21.2171,14.7198 21.1991,14.1078 L21.1991,14.1078 Z M10.3451,15.2058 C10.3451,14.6118 10.8311,14.1258 11.4251,14.1258 C12.0191,14.1258 12.5051,14.6118 12.5051,15.2058 C12.5051,15.7998 12.0191,16.2858 11.4251,16.2858 C10.8311,16.2858 10.3451,15.7998 10.3451,15.2058 Z M16.6451,18.1938 C15.8711,18.7698 14.9351,19.0758 13.9811,19.0218 C13.0271,19.0578 12.0731,18.7698 11.3171,18.1938 C11.2091,18.0678 11.2271,17.8878 11.3531,17.7798 C11.4611,17.6898 11.6051,17.6898 11.7311,17.7798 C12.3791,18.2478 13.1711,18.4998 13.9811,18.4638 C14.7911,18.4998 15.5831,18.2838 16.2491,17.7978 C16.3751,17.6898 16.5551,17.6898 16.6811,17.7978 C16.7891,17.9238 16.7891,18.1038 16.6811,18.2298 L16.6811,18.1938 L16.6451,18.1938 Z M16.4651,16.3398 C15.8711,16.3398 15.3851,15.8538 15.3851,15.2598 C15.3851,14.6658 15.8711,14.1798 16.4651,14.1798 C17.0591,14.1798 17.5451,14.6658 17.5451,15.2598 C17.5631,15.8538 17.1131,16.3578 16.5011,16.3758 C16.4831,16.3758 16.4651,16.3758 16.4471,16.3758 L16.4651,16.3398 Z"
            fill="#FFFFFF"
          ></path>
        </symbol>
        <symbol id="icon-tiktok-fill-circle" viewBox="0 0 28 28">
          <circle cx="14" cy="14" r="14"></circle>
          <path
            d="M21.0732,10.7617 C20.2171,10.7617 19.4273,10.4781 18.793,9.99971 C18.0655,9.45127 17.5429,8.64678 17.3583,7.71953 C17.3126,7.49043 17.288,7.254 17.2857,7.01172 L14.8403,7.01172 L14.8403,13.6938 L14.8373,17.3538 C14.8373,18.3323 14.2001,19.162 13.3168,19.4538 C13.0605,19.5385 12.7836,19.5786 12.4953,19.5628 C12.1274,19.5426 11.7825,19.4315 11.4828,19.2522 C10.845,18.8708 10.4126,18.1788 10.4009,17.3872 C10.3824,16.15 11.3826,15.1413 12.619,15.1413 C12.863,15.1413 13.0974,15.1812 13.3168,15.2535 L13.3168,13.4271 L13.3168,12.7706 C13.0854,12.7363 12.8498,12.7185 12.6116,12.7185 C11.2584,12.7185 9.99279,13.281 9.0881,14.2943 C8.40432,15.0602 7.99416,16.0372 7.93088,17.0617 C7.84797,18.4076 8.34045,19.687 9.29553,20.631 C9.43586,20.7695 9.58322,20.8981 9.73732,21.0168 C10.5562,21.647 11.5572,21.9886 12.6116,21.9886 C12.8498,21.9886 13.0854,21.971 13.3168,21.9367 C14.3018,21.7908 15.2106,21.3399 15.9278,20.631 C16.809,19.76 17.2959,18.6036 17.3012,17.3729 L17.2886,11.9072 C17.709,12.2315 18.1687,12.4999 18.662,12.7082 C19.4293,13.0319 20.2429,13.196 21.0802364,13.1957 L21.0802364,11.42 L21.0802364,10.7611 C21.0808,10.7617 21.0738,10.7617 21.0732,10.7617 L21.0732,10.7617 Z"
            fill="#FFFFFF"
          ></path>
        </symbol>
        <symbol id="icon-twitter-fill-circle" viewBox="0 0 28 28">
          <g width="28" height="28">
            <path
              d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
            ></path>
            <path
              d="M23 9.53923C22.4116 9.79988 21.7795 9.97599 21.115 10.0556C21.7929 9.64983 22.313 9.00596 22.5582 8.23951C21.924 8.61569 21.2214 8.88902 20.4738 9.03555C19.8748 8.39872 19.0222 8 18.078 8C16.2656 8 14.7956 9.46949 14.7956 11.2828C14.7956 11.5392 14.8252 11.7886 14.8802 12.0295C12.1511 11.8921 9.73337 10.5853 8.11407 8.6002C7.8322 9.08627 7.67013 9.64983 7.67013 10.25C7.67013 11.3884 8.25007 12.3937 9.13019 12.9819C8.59183 12.9643 8.08588 12.8171 7.64335 12.5712V12.6135C7.64335 14.2034 8.77433 15.5299 10.2767 15.8314C10.0004 15.9061 9.71083 15.9455 9.41205 15.9455C9.20065 15.9455 8.99419 15.9258 8.79406 15.8878C9.21193 17.191 10.4239 18.1406 11.8607 18.166C10.7368 19.0465 9.32115 19.5714 7.78358 19.5714C7.51863 19.5714 7.2572 19.5559 7 19.5256C8.45301 20.4576 10.178 21 12.0313 21C18.0702 21 21.3715 15.9998 21.3715 11.6632C21.3715 11.5223 21.368 11.38 21.3617 11.2391C22.0029 10.7755 22.5596 10.1986 22.9986 9.54135L23 9.53923Z"
              fill="white"
            ></path>
          </g>
        </symbol>
        <symbol id="icon-youtube-fill-circle" viewBox="0 0 28 28">
          <circle id="Oval" cx="14" cy="14" r="14"></circle>
          <g id="Group" transform="translate(5.000000, 7.707030)">
            <path
              d="M17.6291,1.97204 C17.4216,1.20094 16.8137,0.59312 16.0427,0.38548 C14.6341,0 8.9998,0 8.9998,0 C8.9998,0 3.36565,0 1.95707,0.37079 C1.20094,0.57829 0.57815,1.20108 0.37065,1.97204 C0,3.38047 0,6.30147 0,6.30147 C0,6.30147 0,9.23717 0.37065,10.63087 C0.57829,11.40187 1.18611,12.00977 1.95721,12.21747 C3.38048,12.60287 9,12.60287 9,12.60287 C9,12.60287 14.6341,12.60287 16.0427,12.23217 C16.8138,12.02467 17.4216,11.41667 17.6293,10.64567 C17.9999766,9.23717 17.9999766,6.31627 17.9999766,6.31627 C17.9999766,6.31627 18.0148,3.38047 17.6291,1.97204 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
            <polygon
              id="Path"
              points="7.207 8.99957 11.8923 6.30107 7.207 3.60257"
            ></polygon>
          </g>
        </symbol>
        <symbol id="icon-medium-fill-circle" viewBox="0 0 28 28">
          <circle cx="14" cy="14" r="14"></circle>
          <path
            d="M11.0766 9C13.8804 9 16.1531 11.2886 16.1531 14.1115C16.1531 16.9344 13.8802 19.2228 11.0766 19.2228C8.27305 19.2228 6 16.9344 6 14.1115C6 11.2886 8.27287 9 11.0766 9ZM19.1836 9.29942C20.5855 9.29942 21.722 11.4536 21.722 14.1115H21.7221C21.7221 16.7686 20.5857 18.9235 19.1838 18.9235C17.7819 18.9235 16.6455 16.7686 16.6455 14.1115C16.6455 11.4543 17.7818 9.29942 19.1836 9.29942ZM23.1073 9.80063C23.6002 9.80063 24 11.7306 24 14.1115C24 16.4916 23.6004 18.4223 23.1073 18.4223C22.6142 18.4223 22.2147 16.4921 22.2147 14.1115C22.2147 11.7308 22.6143 9.80063 23.1073 9.80063Z"
            fill="white"
          ></path>
        </symbol>
        <symbol id="icon-twitter" viewBox="0 0 21 18">
          <path
            d="M6.2918,16.2505782 C13.8371,16.2505782 17.9652,9.99787647 17.9652,4.57716647 C17.9652,4.40137647 17.9613,4.22169647 17.9535,4.04590647 C18.7566,3.46516647 19.4496,2.74582647 20,1.92169647 C19.2521,2.25444647 18.458,2.47176647 17.6449,2.56622647 C18.5011,2.05303647 19.1421,1.24683647 19.4492,0.297086465 C18.6438,0.774406465 17.763,1.11111647 16.8445,1.29278647 C16.2257,0.635246465 15.4075,0.199876465 14.5164,0.0539864653 C13.6253,-0.0919015347 12.711,0.0598184653 11.9148,0.485686465 C11.1186,0.911566465 10.4848,1.58786647 10.1115,2.41003647 C9.7382,3.23220647 9.6462,4.15445647 9.8496,5.03419647 C8.21874,4.95235647 6.62328,4.52869647 5.16665,3.79069647 C3.71002,3.05268647 2.42474,2.01680647 1.39414,0.750206465 C0.87033,1.65331647 0.71005,2.72198647 0.94586,3.73901647 C1.18167,4.75605647 1.79589,5.64514647 2.66367,6.22559647 C2.01219,6.20491647 1.37498,6.02950647 0.80469,5.71387647 L0.80469,5.76465647 C0.8041,6.71240647 1.13175,7.63109647 1.73192,8.36458647 C2.3321,9.09807647 3.16777,9.60107647 4.09687,9.78807647 C3.49338,9.95317647 2.85999,9.97727647 2.2457,9.85837647 C2.50788,10.6734765 3.01798,11.3863765 3.70481,11.8975765 C4.39164,12.4087765 5.22093,12.6927765 6.07695,12.7099765 C4.62369,13.8515765 2.82848,14.4706765 0.98047,14.4677765 C0.65274,14.4672765 0.325333,14.4471765 0,14.4076765 C1.87738,15.6120765 4.06128,16.2517765 6.2918,16.2505782 Z"
          ></path>
        </symbol>
        <symbol id="icon-close" viewBox="0 0 27 24">
          <path
            d="M15.3055 12L25.7924 2.41015C26.3961 1.85816 26.3961 0.965972 25.7924 0.413988C25.1888 -0.137996 24.2132 -0.137996 23.6095 0.413988L13.1226 10.0038L2.63561 0.413988C2.03199 -0.137996 1.05634 -0.137996 0.452716 0.413988C-0.150905 0.965972 -0.150905 1.85816 0.452716 2.41015L10.9397 12L0.452716 21.5898C-0.150905 22.1418 -0.150905 23.034 0.452716 23.586C0.753757 23.8613 1.14896 23.9996 1.54416 23.9996C1.93937 23.9996 2.33457 23.8612 2.63561 23.586L13.1226 13.9961L23.6095 23.586C23.9105 23.8613 24.3057 23.9996 24.7009 23.9996C25.0961 23.9996 25.4914 23.8612 25.7924 23.586C26.396 23.034 26.396 22.1418 25.7924 21.5898L15.3055 12Z"
          ></path>
        </symbol>
        <symbol id="icon-login" viewBox="0 0 22 23">
          <path
            d="M13.702 1.646C12.4484 1.64598 11.4379 1.64596 10.6432 1.75281C9.81815 1.86374 9.12339 2.10106 8.57164 2.65282C8.09045 3.134 7.84731 3.72533 7.71968 4.42015C7.59567 5.09533 7.57194 5.92161 7.56642 6.91301C7.56431 7.29269 7.87039 7.6022 8.25009 7.60432C8.62978 7.60644 8.93929 7.30034 8.9414 6.92066C8.94698 5.91829 8.97301 5.2078 9.07206 4.66857C9.16749 4.14899 9.32077 3.84825 9.54389 3.62509C9.79762 3.37139 10.1538 3.20598 10.8264 3.11555C11.5188 3.02245 12.4365 3.021 13.7523 3.021H14.669C15.9848 3.021 16.9024 3.02245 17.5949 3.11555C18.2675 3.20598 18.6237 3.37139 18.8774 3.62509C19.1311 3.87879 19.2965 4.23498 19.387 4.90761C19.48 5.60001 19.4815 6.5177 19.4815 7.8335V15.1668C19.4815 16.4826 19.48 17.4003 19.387 18.0927C19.2965 18.7654 19.1311 19.1215 18.8774 19.3752C18.6237 19.629 18.2675 19.7943 17.5949 19.8848C16.9024 19.9779 15.9848 19.9793 14.669 19.9793H13.7523C12.4365 19.9793 11.5188 19.9779 10.8264 19.8848C10.1538 19.7943 9.79762 19.629 9.54389 19.3752C9.32077 19.152 9.16749 18.8514 9.07206 18.3318C8.97301 17.7925 8.94698 17.082 8.9414 16.0796C8.93929 15.7 8.62978 15.3939 8.25009 15.396C7.87039 15.3981 7.56431 15.7077 7.56642 16.0873C7.57194 17.0787 7.59567 17.905 7.71968 18.5801C7.84731 19.275 8.09045 19.8663 8.57164 20.3476C9.12339 20.8993 9.81815 21.1366 10.6432 21.2475C11.4379 21.3543 12.4484 21.3543 13.702 21.3543H14.7193C15.9729 21.3543 16.9834 21.3543 17.7781 21.2475C18.6032 21.1366 19.2979 20.8993 19.8497 20.3476C20.4014 19.7957 20.6388 19.1011 20.7497 18.276C20.8566 17.4812 20.8565 16.4708 20.8565 15.2172V7.7832C20.8565 6.52957 20.8566 5.51911 20.7497 4.72439C20.6388 3.89929 20.4014 3.20458 19.8497 2.65282C19.2979 2.10106 18.6032 1.86374 17.7781 1.75281C16.9834 1.64596 15.9729 1.64598 14.7193 1.646H13.702Z"
          ></path>
          <path
            d="M1.83594 10.8115C1.45624 10.8115 1.14844 11.1193 1.14844 11.499C1.14844 11.8787 1.45624 12.1865 1.83594 12.1865H12.8107L11.0135 13.7271C10.7252 13.9741 10.6919 14.4081 10.9389 14.6964C11.186 14.9847 11.6201 15.0181 11.9084 14.7711L15.1167 12.0211C15.2691 11.8904 15.3568 11.6998 15.3568 11.499C15.3568 11.2984 15.2691 11.1077 15.1167 10.9771L11.9084 8.22704C11.6201 7.97995 11.186 8.01333 10.9389 8.30161C10.6919 8.58991 10.7252 9.02392 11.0135 9.27103L12.8107 10.8115H1.83594Z"
          ></path>
        </symbol>
        <symbol id="icon-helpcrunch" viewBox="0 0 36 30">
          <path
            fill-rule="evenodd"
            d="M28.202 13.052c.255-.793.39-1.622.39-2.478C28.592 4.652 22.135 0 14.296 0 6.456 0 0 4.652 0 10.574c0 3.148 1.846 6.031 4.935 8.005-.005.402-.005 4.041-.005 4.041 0 1.372 1.305 1.998 2.37 1.123 0 0 2.557-2.101 3.538-2.905a19.489 19.489 0 0 0 5.568.195c.771 3.35 4.653 5.798 9.228 5.798.234 0 .467-.007.698-.02.495.405 2.846 2.342 2.846 2.342 1.066.875 2.371.249 2.371-1.123 0 0 .01-2.29.01-2.77C33.696 23.956 35 21.986 35 19.808c0-3.273-2.902-5.932-6.798-6.757zm-2.005-.253c.276-.712.423-1.459.423-2.225 0-4.672-5.462-8.607-12.324-8.607-6.863 0-12.324 3.935-12.324 8.607 0 2.549 1.628 4.957 4.442 6.602l.487.285v4.08l3.042-2.517.365-.3.464.096a17.556 17.556 0 0 0 5.546.244c.494-3.592 4.525-6.277 9.316-6.277.189 0 .376.004.563.012zm-.563 12.064c-4.14 0-7.395-2.344-7.395-5.054 0-2.71 3.255-5.055 7.395-5.055s7.394 2.345 7.394 5.055c0 1.555-1.073 3.041-2.924 4.013l-.527.276v.594l.012 2.25-2.31-1.898-.311-.256-.402.035c-.307.027-.618.04-.932.04zm-3.45-4.207a.985.985 0 1 0-.987-.984c0 .543.442.984.986.984zm3.45 0a.985.985 0 1 0-.986-.984c0 .543.441.984.986.984zm-16.76-7.87a1.97 1.97 0 0 0 1.971-1.966 1.97 1.97 0 0 0-1.972-1.968 1.97 1.97 0 0 0-1.972 1.968 1.97 1.97 0 0 0 1.972 1.967zm5.915 0a1.97 1.97 0 0 0 1.972-1.966 1.97 1.97 0 0 0-1.972-1.968 1.97 1.97 0 0 0-1.972 1.968 1.97 1.97 0 0 0 1.972 1.967zm14.296 7.87a.985.985 0 1 0-.986-.984c0 .543.441.984.986.984zm-8.38-7.87a1.97 1.97 0 0 0 1.971-1.966 1.97 1.97 0 0 0-1.972-1.968 1.97 1.97 0 0 0-1.972 1.968 1.97 1.97 0 0 0 1.972 1.967z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-right" viewBox="0 0 22 20">
          <path
            d="M10.4696699,-0.530330086 C10.7625631,-0.823223305 11.2374369,-0.823223305 11.5303301,-0.530330086 L20.5303301,8.46966991 C20.5529506,8.49229046 20.5741329,8.51634926 20.5937281,8.54169736 L20.598,8.548 L20.619,8.58 L20.647603,8.62146064 L20.652,8.631 L20.6610428,8.64528991 L20.674,8.675 L20.6910613,8.70806615 L20.699,8.73 L20.7046137,8.74253391 L20.71,8.761 L20.7232093,8.80062029 L20.729,8.828 L20.7336609,8.84387965 L20.736,8.863 L20.7431534,8.89822944 L20.745,8.925 L20.7481845,8.94768643 L20.748,8.971 L20.75,9 L20.748,9.029 L20.7481845,9.05231357 L20.745,9.073 L20.7431534,9.10177056 L20.736,9.136 L20.7336609,9.15612035 L20.729,9.171 L20.7232093,9.19937971 L20.71,9.238 L20.7046137,9.25746609 L20.699,9.269 L20.6910613,9.29193385 L20.674,9.324 L20.6610428,9.35471009 L20.652,9.368 L20.647603,9.37853936 L20.619,9.419 L20.6029482,9.44621165 L20.5303301,9.53033009 L11.5303301,18.5303301 C11.2374369,18.8232233 10.7625631,18.8232233 10.4696699,18.5303301 C10.1767767,18.2374369 10.1767767,17.7625631 10.4696699,17.4696699 L18.188,9.75 L0,9.75 C-0.379695766,9.75 -0.693490961,9.46784612 -0.743153384,9.10177056 L-0.75,9 C-0.75,8.58578644 -0.414213562,8.25 0,8.25 L18.189,8.25 L10.4696699,0.530330086 C10.2034034,0.264063523 10.1791973,-0.152600159 10.3970518,-0.446211653 L10.4696699,-0.530330086 Z"
          ></path>
        </symbol>
        <symbol id="icon-whatsapp" viewBox="0 0 25 24">
          <path
            d="M0,24 L1.687003,17.837 C0.646003,16.033 0.099,13.988 0.1,11.891 C0.103,5.335 5.438003,0 11.992993,0 C15.173993,0.001 18.159993,1.24 20.405993,3.488 C22.650993,5.736 23.886993,8.724 23.8859936,11.902 C23.882993,18.459 18.547993,23.794 11.992993,23.794 C10.002993,23.793 8.042003,23.294 6.305003,22.346 L0,24 Z M6.597003,20.193 C8.273003,21.188 9.872993,21.784 11.988993,21.785 C17.436993,21.785 21.874993,17.351 21.8779937,11.9 C21.879993,6.438 17.462993,2.01 11.996993,2.008 C6.545003,2.008 2.110003,6.442 2.10800185,11.892 C2.107003,14.117 2.759003,15.783 3.854003,17.526 L2.855003,21.174 L6.597003,20.193 Z M17.983993,14.729 C17.909993,14.605 17.711993,14.531 17.413993,14.382 C17.116993,14.233 15.655993,13.514 15.382993,13.415 C15.110993,13.316 14.912993,13.266 14.713993,13.564 C14.515993,13.861 13.945993,14.531 13.772993,14.729 C13.599993,14.927 13.425993,14.952 13.128993,14.803 C12.831993,14.654 11.873993,14.341 10.738993,13.328 C9.855993,12.54 9.259003,11.567 9.086003,11.269 C8.913003,10.972 9.068003,10.811 9.216003,10.663 C9.350003,10.53 9.512993,10.316 9.661993,10.142 C9.812993,9.97 9.861993,9.846 9.961993,9.647 C10.060993,9.449 10.011993,9.275 9.936993,9.126 C9.861993,8.978 9.268003,7.515 9.021003,6.92 C8.779003,6.341 8.534003,6.419 8.352003,6.41 L7.782003,6.4 C7.584003,6.4 7.262003,6.474 6.990003,6.772 C6.718003,7.07 5.950003,7.788 5.950003,9.251 C5.950003,10.714 7.015003,12.127 7.163003,12.325 C7.312003,12.523 9.258003,15.525 12.238993,16.812 C12.947993,17.118 13.501993,17.301 13.932993,17.438 C14.644993,17.664 15.292993,17.632 15.804993,17.556 C16.375993,17.471 17.562993,16.837 17.810993,16.143 C18.058993,15.448 18.058993,14.853 17.983993,14.729 Z"
          ></path>
        </symbol>
        <symbol id="icon-download" viewBox="0 0 19 22">
          <path
            d="M0.00251110477,18.9548172 L18.0024998,19.0000172 L17.9974776,21.0000108 L-0.00251110477,20.9548172 L0.00251110477,18.9548172 Z M8.60568413,17.3567676 L-0.0343188665,8.66896762 L1.38379027,7.25866038 L8.314,14.228 L8.3147387,0 L10.3147387,0 L10.314,14.228 L17.245632,7.25866256 L18.6637454,8.66896544 L10.0237954,17.3567676 L9.314,16.651 L8.60568413,17.3567676 Z"
          ></path>
        </symbol>
        <symbol id="icon-aml" viewBox="0 0 13 16">
          <path
            d="M6.1818 0L0 8L6.18184 16L12.3636 8L6.1818 0ZM4.63637 8H1.54543L6.1818 1.59998L4.63637 8H7.72727L6.1818 14.4L4.63637 8Z"
          ></path>
        </symbol>
        <symbol id="icon-timer" viewBox="0 0 16 16">
          <path
            d="M7.99967 13.333C10.933 13.333 13.333 10.933 13.333 7.99967C13.333 5.06634 10.933 2.66634 7.99967 2.66634C5.06634 2.66634 2.66634 5.06634 2.66634 7.99967C2.66634 10.933 5.06634 13.333 7.99967 13.333ZM7.99967 1.33301C11.6663 1.33301 14.6663 4.33301 14.6663 7.99967C14.6663 11.6663 11.6663 14.6663 7.99967 14.6663C4.33301 14.6663 1.33301 11.6663 1.33301 7.99967C1.33301 4.33301 4.33301 1.33301 7.99967 1.33301ZM11.333 9.26634L10.8663 10.133L7.33301 8.19967V4.66634H8.33301V7.59967L11.333 9.26634Z"
          ></path>
        </symbol>
        <symbol id="icon-arrow-down-short" viewBox="0 0 20 20">
          <path
            d="M3.16107 6.49408C3.48651 6.16864 4.01414 6.16864 4.33958 6.49408L9.58366 11.7382L14.8277 6.49408C15.1532 6.16864 15.6808 6.16864 16.0062 6.49408C16.3317 6.81951 16.3317 7.34715 16.0062 7.67259L10.1729 13.5059C9.84748 13.8314 9.31984 13.8314 8.9944 13.5059L3.16107 7.67259C2.83563 7.34715 2.83563 6.81951 3.16107 6.49408Z"
          ></path>
        </symbol>
      </svg>
      <MainHeader :drawal="drawal" :toggleDrawal="toggleDrawal"></MainHeader>
      <Agreement v-if="endPoint === '/terms-of-service'"></Agreement>
      <PrivacyPolicy v-else-if="endPoint === '/privacy-policy'"></PrivacyPolicy>
      <template v-else>
        <DrawalMenu v-if="drawal" :toggleDrawal="toggleDrawal"></DrawalMenu>
        <MainBody></MainBody>
      </template>
      <MainFooter></MainFooter>
    </div>

    <noscript
      ><img
        alt=""
        loading="lazy"
        width="1"
        height="1"
        decoding="async"
        data-nimg="1"
        style="color: transparent; display: none"
        srcSet="/_next/image?url=https%3A%2F%2Fpx.ads.linkedin.com%2Fcollect%2F%3Fpid%3D5572601%26fmt%3Dgif&amp;w=16&amp;q=75 1x"
        src="/_next/image?url=https%3A%2F%2Fpx.ads.linkedin.com%2Fcollect%2F%3Fpid%3D5572601%26fmt%3Dgif&amp;w=16&amp;q=75"
    /></noscript>
    <div class="ReactModalPortal"></div>
    <div class="ReactModalPortal"></div>
    <div class="ReactModalPortal"></div>
    <div class="ReactModalPortal"></div>
    <div class="ReactModalPortal"></div>

    <next-route-announcer
      ><p
        aria-live="assertive"
        id="__next-route-announcer__"
        role="alert"
        style="
          border: 0px;
          clip: rect(0px, 0px, 0px, 0px);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0px;
          position: absolute;
          top: 0px;
          width: 1px;
          white-space: nowrap;
          overflow-wrap: normal;
        "
      ></p
    ></next-route-announcer>

    <noscript
      ><img
        height="1"
        width="1"
        style="display: none"
        src="https://www.facebook.com/tr?id=877590766007498&amp;ev=PageView&amp;noscript=1"
    /></noscript>

    <iframe
      id="_hjSafeContext_68115898"
      title="_hjSafeContext"
      tabindex="-1"
      aria-hidden="true"
      src="about:blank"
      style="
        display: none !important;
        width: 1px !important;
        height: 1px !important;
        opacity: 0 !important;
        pointer-events: none !important;
      "
    ></iframe
    ><iframe
      name="__uspapiLocator"
      tabindex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      style="
        display: none;
        position: absolute;
        width: 1px;
        height: 1px;
        top: -9999px;
      "
    ></iframe
    ><iframe
      tabindex="-1"
      role="presentation"
      aria-hidden="true"
      title="Blank"
      src="https://consentcdn.cookiebot.com/sdk/bc-v4.min.html"
      style="position: absolute; width: 1px; height: 1px; top: -9999px"
    ></iframe
    ><iframe
      allow="join-ad-interest-group"
      data-tagging-id="AW-460079918/A78fCNH154wCEK6GsdsB"
      data-load-time="1720808826252"
      height="0"
      width="0"
      src="https://td.doubleclick.net/td/rul/460079918?random=1720808826249&amp;cv=11&amp;fst=1720808826249&amp;fmt=3&amp;bg=ffffff&amp;guid=ON&amp;async=1&amp;gtm=45be4790v895870961z8837878660za201zb837878660&amp;gcs=G111&amp;gcd=13v3v3v3v5&amp;dma=0&amp;tag_exp=95250752&amp;u_w=2560&amp;u_h=1080&amp;url=https%3A%2F%2Famlbot.com%2F&amp;ref=https%3A%2F%2Fwww.google.com%2F&amp;label=A78fCNH154wCEK6GsdsB&amp;hn=www.googleadservices.com&amp;frm=0&amp;tiba=AMLBot%20-%20The%20full-fledged%20crypto%20compliance%20solution&amp;value=0&amp;bttype=purchase&amp;npa=0&amp;us_privacy=1---&amp;gclgs=1&amp;gclst=16249&amp;gclaw=CjwKCAjwqMO0BhA8EiwAFTLgICEucbm65lpIqDRPTN9lv7Ebdw_IKh6xUVLUk8QekU4gnjkzCJYFSxoCpFEQAvD_BwE&amp;pscdl=noapi&amp;auid=1390169137.1720773335&amp;uaa=arm&amp;uab=64&amp;uafvl=Google%2520Chrome%3B125.0.6422.142%7CChromium%3B125.0.6422.142%7CNot.A%252FBrand%3B24.0.0.0&amp;uamb=0&amp;uam=&amp;uap=macOS&amp;uapv=13.3.1&amp;uaw=0&amp;fledge=1&amp;capi=1&amp;data=ads_data_redaction%3Dtrue&amp;ct_cookie_present=0"
      style="display: none; visibility: hidden"
    ></iframe
    ><iframe
      allow="join-ad-interest-group"
      data-tagging-id="AW-16525223156"
      data-load-time="1720808826266"
      height="0"
      width="0"
      src="https://td.doubleclick.net/td/rul/16525223156?random=1720808826264&amp;cv=11&amp;fst=1720808826264&amp;fmt=3&amp;bg=ffffff&amp;guid=ON&amp;async=1&amp;gtm=45be4790v9182910966z8837878660za201zb837878660&amp;gcd=13v3v3v3v5&amp;dma=0&amp;tag_exp=95250752&amp;u_w=2560&amp;u_h=1080&amp;url=https%3A%2F%2Famlbot.com%2F&amp;ref=https%3A%2F%2Fwww.google.com%2F&amp;hn=www.googleadservices.com&amp;frm=0&amp;tiba=AMLBot%20-%20The%20full-fledged%20crypto%20compliance%20solution&amp;npa=0&amp;us_privacy=1---&amp;pscdl=noapi&amp;auid=1390169137.1720773335&amp;uaa=arm&amp;uab=64&amp;uafvl=Google%2520Chrome%3B125.0.6422.142%7CChromium%3B125.0.6422.142%7CNot.A%252FBrand%3B24.0.0.0&amp;uamb=0&amp;uam=&amp;uap=macOS&amp;uapv=13.3.1&amp;uaw=0&amp;fledge=1&amp;data=ads_data_redaction%3Dtrue"
      style="display: none; visibility: hidden"
    ></iframe>

    <div class="go2933276541 go2369186930" id="hs-web-interactives-top-anchor">
      <div id="hs-interactives-modal-overlay" class="go1632949049"></div>
    </div>
    <div
      class="go2933276541 go1348078617"
      id="hs-web-interactives-bottom-anchor"
    ></div>
    <div id="hs-web-interactives-floating-container">
      <div
        id="hs-web-interactives-floating-top-left-anchor"
        class="go2417249464 go613305155"
      ></div>
      <div
        id="hs-web-interactives-floating-top-right-anchor"
        class="go2417249464 go471583506"
      ></div>
      <div
        id="hs-web-interactives-floating-bottom-left-anchor"
        class="go2417249464 go3921366393"
      ></div>
      <div
        id="hs-web-interactives-floating-bottom-right-anchor"
        class="go2417249464 go3967842156"
        style="margin-bottom: 96px"
      ></div>
    </div>

    <iframe
      owner="archetype"
      title="archetype"
      style="display: none; visibility: hidden"
    ></iframe
    ><img
      id="CookiebotSessionPixel"
      src="https://imgsct.cookiebot.com/1.gif?dgi=66ad5a5a-1bfe-4bf5-8016-ecbf593ac015"
      alt="Cookiebot session tracker icon loaded"
      data-cookieconsent="ignore"
      style="display: none"
    />
    <div
      role="region"
      aria-label="채팅 위젯"
      id="hubspot-messages-iframe-container"
      class="widget-align-right"
      style="min-height: 96px; min-width: 100px; width: 92px; height: 92px"
    >
      <div class="hs-shadow-container"></div>
      <iframe
        src="https://app.hubspot.com/conversations-visitor/20255707/threads/utk/861de5b170c94d8da82e1de7f802cf16?uuid=3d78b85b568d4311950e12217fee1310&amp;mobile=false&amp;mobileSafari=false&amp;hideWelcomeMessage=false&amp;hstc=254419799.3041e11a29a1c8de3c1a0e620a71e9ba.1720773337621.1720791202972.1720808814550.3&amp;domain=amlbot.com&amp;inApp53=false&amp;messagesUtk=861de5b170c94d8da82e1de7f802cf16&amp;url=https%3A%2F%2Famlbot.com%2F&amp;inline=false&amp;isFullscreen=false&amp;globalCookieOptOut=&amp;isFirstVisitorSession=false&amp;isAttachmentDisabled=false&amp;isInitialInputFocusDisabled=false&amp;enableWidgetCookieBanner=false&amp;isInCMS=false&amp;hideScrollToButton=true&amp;hubspotUtk=3041e11a29a1c8de3c1a0e620a71e9ba"
        id="hubspot-conversations-iframe"
        title="Chat Widget"
        allowfullscreen=""
        data-test-id="chat-widget-iframe"
      ></iframe>
    </div>
  </body>
</template>

<script>
import MainHeader from "./MainHeader.vue";
import MainBody from "./MainBody.vue";
import MainFooter from "./MainFooter.vue";
import Agreement from "./Agreement.vue";
import PrivacyPolicy from "./PrivacyPolicy.vue";
import DrawalMenu from './DrawalMenu.vue';

export default {
  components: {
    MainHeader,
    MainBody,
    MainFooter,
    Agreement,
    PrivacyPolicy,
    DrawalMenu
  },
  data: function () {
    return {
      endPoint: null,
      drawal: false
    };
  },
  methods: {
    toggleDrawal: function() {
      this.drawal = !this.drawal
    },
  },
  mounted: function () {
    this.endPoint = window.location.pathname;
  },
};
</script>

<style>
.tmXwhz {
  margin-bottom: 16rem;
  padding-top: 12.3rem;
}
.bg-light-gray {
  background-color: #efefef;
}
body {
  /* overflow-x: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #efefef;
  --overlay-primary: #fff;
  --overlay-secondary: #000;
  font-size: 2.4rem;
  margin: 0px;
}
</style>
