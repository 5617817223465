<template>
  <section class="OWqml5">
    <div class="wrapper">
      <h2 class="d_wu83 aos-init" data-aos="fade-up" id="Core" :class="{'aos-animate': isVisibleCore}">Meet the core team</h2>
      <div class="_HJUe6 Pe_j2I n3bkm5">
        <div class="_gbILZ zGEBGs _64_V75 aos-init " data-aos="fade-up" id="Slava" :class="{'aos-animate': isVisibleSlava}">
          <div class="xDemSk">
            <div class="DrV4ut">
              <img
                alt="Slava Demchuk"
                loading="lazy"
                width="469"
                height="366"
                decoding="async"
                data-nimg="1"
                style="color: transparent"
                src="../../assets/image/team/slava-demchuk.webp"
              />
            </div>
            <div class="ngmHXe">
              <div class="xqtD0N">Co-Founder</div>
              <h3 class="_7s5GNb">Slava Demchuk</h3>
              <nav class="Pw6GPp">
                <a
                  target="_blank"
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/in/demchukvm/"
                  ><svg class="icon icon-linkedin-fill-rect">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="#icon-linkedin-fill-rect"
                    ></use></svg></a
                ><a
                  target="_blank"
                  aria-label="Twitter"
                  href="https://twitter.com/demchukvm"
                  ><svg class="icon icon-twitter">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="#icon-twitter"
                    ></use></svg
                ></a>
              </nav>
            </div>
          </div>
        </div>
        <div
          class="_gbILZ zGEBGs _64_V75 aos-init"
          data-aos="fade-up"
          data-aos-delay="100"
          id="Sid"
          :class="{'aos-animate': isVisibleSid}"
        >
          <div class="xDemSk">
            <div class="DrV4ut">
              <img
                alt="Sid Panda"
                loading="lazy"
                width="469"
                height="366"
                decoding="async"
                data-nimg="1"
                style="color: transparent"
                src="../../assets/image/team/sid-panda.webp"
              />
            </div>
            <div class="ngmHXe">
              <div class="xqtD0N">Blockchain Analyst</div>
              <h3 class="_7s5GNb">Sid Panda</h3>
              <nav class="Pw6GPp">
                <a
                  target="_blank"
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/in/sidhartha-panda-24862115/"
                  ><svg class="icon icon-linkedin-fill-rect">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="#icon-linkedin-fill-rect"
                    ></use></svg
                ></a>
              </nav>
            </div>
          </div>
        </div>
        <div
          class="_gbILZ zGEBGs _64_V75 aos-init"
          data-aos="fade-up"
          data-aos-delay="200"
          id="Graeme"
          :class="{'aos-animate': isVisibleGraeme}"
        >
          <div class="xDemSk">
            <div class="DrV4ut">
              <img
                alt="Graeme Hampton"
                loading="lazy"
                width="469"
                height="366"
                decoding="async"
                data-nimg="1"
                style="color: transparent"
                src="../../assets/image/team/graeme-hampton.webp"
              />
            </div>
            <div class="ngmHXe">
              <div class="xqtD0N">Certified AML Specialist</div>
              <h3 class="_7s5GNb">Graeme Hampton</h3>
              <nav class="Pw6GPp">
                <a
                  target="_blank"
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/in/graeme-hampton-97107b22/"
                  ><svg class="icon icon-linkedin-fill-rect">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="#icon-linkedin-fill-rect"
                    ></use></svg
                ></a>
              </nav>
            </div>
          </div>
        </div>
        <div
          class="_gbILZ zGEBGs _64_V75 aos-init"
          data-aos="fade-up"
          data-aos-delay="300"
          id="Anmol"
          :class="{'aos-animate': isVisibleAnmol}"
        >
          <div class="xDemSk">
            <div class="DrV4ut">
              <img
                alt="Anmol Jain"
                loading="lazy"
                width="469"
                height="366"
                decoding="async"
                data-nimg="1"
                style="color: transparent"
                src="../../assets/image/team/anmol-jain.webp"
              />
            </div>
            <div class="ngmHXe">
              <div class="xqtD0N">Head of Investigations</div>
              <h3 class="_7s5GNb">Anmol Jain</h3>
              <nav class="Pw6GPp">
                <a
                  target="_blank"
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/in/anmoljain/"
                  ><svg class="icon icon-linkedin-fill-rect">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="#icon-linkedin-fill-rect"
                    ></use></svg
                ></a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:['bottom'],
  data: function(){
    return {
      isVisibleCore: false,
      isVisibleSlava: false,
      isVisibleSid: false,
      isVisibleGraeme: false,
      isVisibleAnmol: false,
    }
  },
  watch: {
    bottom() {
      if(this.bottom > this.locationCore) {
        this.isVisibleCore = true;
      } else {
        this.isVisibleCore = false;
      }

      if(this.bottom > this.locationSlava) {
        this.isVisibleSlava = true;
      } else {
        this.isVisibleSlava = false;
      }
      
      if(this.bottom > this.locationSid) {
        this.isVisibleSid = true;
      } else {
        this.isVisibleSid = false
      }
      
      if(this.bottom > this.locationGraeme) {
        this.isVisibleGraeme = true;
      } else {
        this.isVisibleGraeme = false
      }
      
      if(this.bottom > this.locationAnmol) {
        this.isVisibleAnmol = true;
      } else {
        this.isVisibleAnmol = false
      }

    }
  },
  computed: {
    locationCore: function(){
      return document.getElementById('Core').getBoundingClientRect().top + window.scrollY;
    },
    locationSlava: function(){
      return document.getElementById('Slava').getBoundingClientRect().top + window.scrollY;
    },
    locationSid: function(){
      return document.getElementById('Sid').getBoundingClientRect().top + window.scrollY;
    },
    locationGraeme: function(){
      return document.getElementById('Graeme').getBoundingClientRect().top + window.scrollY;
    },
    locationAnmol: function(){
      return document.getElementById('Anmol').getBoundingClientRect().top + window.scrollY;
    },
  },
};
</script>

<style></style>
