<template>
  <section class="bg3siR">
    <div class="wrapper">
      <div class="PGwkwd">
        <div class="Wj80_I">
          <h2 class="YHxvIl aos-init aos-animate" data-aos="fade-up">
            Our initiatives
          </h2>
          <div
            class="dEg32z aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              class="i6oszE _8dDVll DvWUkr hX3WJv zkITkJ"
              href="https://blog.amlbot.com"
              ><span class="pOE1_y">More in our blog</span
              ><svg class="icon icon-arrow-right-long mj71Cs">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-arrow-right-long"
                ></use></svg
            ></a>
          </div>
        </div>
        <div class="GIWDD5">
          <div
            class="swiper swiper-initialized swiper-horizontal swiper-autoheight ZAcvzG swiper-backface-hidden"
          >
            <div
              class="swiper-wrapper"
              style="
                height: 348px;
                transition-duration: 0ms;
                transform: translate3d(0px, 0px, 0px);
                transition-delay: 0ms;
              "
            >
              <div
                class="swiper-slide swiper-slide-active"
                style="width: 366.333px; margin-right: 20px"
              >
                <a
                  target="_blank"
                  class="fFM9Pz aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="0"
                  href="https://blog.amlbot.com/how-to-open-a-financial-institution-account-at-binance/"
                  ><div class="Ylol5t">
                    <img
                      alt="How to Open a Financial Institution Account at Binance"
                      loading="lazy"
                      width="469"
                      height="263"
                      decoding="async"
                      data-nimg="1"
                      style="color: transparent"
                      src="../../assets/image/1.webp"
                    />
                  </div>
                  <div class="TK3QTC"><span>2 min read</span></div>
                  <h3 class="U7Vn6H">
                    How to Open a Financial Institution Account at Binance
                  </h3>
                  <p class="X0js5h">
                    How to Open a Financial Institution Account at Binance If
                    You Are a Crypto...
                  </p></a
                >
              </div>
              <div
                class="swiper-slide swiper-slide-next"
                style="width: 366.333px; margin-right: 20px"
              >
                <a
                  target="_blank"
                  class="fFM9Pz aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  href="https://blog.amlbot.com/amlbot-attends-eu-crypto-regulation-round-table/"
                  ><div class="Ylol5t">
                    <img
                      alt="AMLBot Team Attends EU Crypto Regulation Round Table"
                      loading="lazy"
                      width="704"
                      height="395"
                      decoding="async"
                      data-nimg="1"
                      style="color: transparent"
                      src="../../assets/image/2.webp"
                    />
                  </div>
                  <div class="TK3QTC"><span>1 min read</span></div>
                  <h3 class="U7Vn6H">
                    AMLBot Team Attends EU Crypto Regulation Round Table
                  </h3>
                  <p class="X0js5h">
                    With the application of the Markets in Crypto Assets (MiCA)
                    on the horizon...
                  </p></a
                >
              </div>
              <div
                class="swiper-slide"
                style="width: 366.333px; margin-right: 20px"
              >
                <a
                  target="_blank"
                  class="fFM9Pz aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  href="https://blog.amlbot.com/how-aml-regulations-are-changing-in-2023/"
                  ><div class="Ylol5t">
                    <img
                      alt="How AML Regulations Are Changing in 2023"
                      loading="lazy"
                      width="469"
                      height="263"
                      decoding="async"
                      data-nimg="1"
                      style="color: transparent"
                      src="../../assets/image/3.webp"
                    />
                  </div>
                  <div class="TK3QTC"><span>7 min read</span></div>
                  <h3 class="U7Vn6H">
                    How AML Regulations Are Changing in 2023
                  </h3>
                  <p class="X0js5h">
                    Organized crime`s motivation is the money perpetrators stand
                    to make out...
                  </p></a
                >
              </div>
              <div
                class="swiper-slide"
                style="width: 366.333px; margin-right: 20px"
              >
                <a
                  target="_blank"
                  class="fFM9Pz aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  href="https://blog.amlbot.com/amlbot-attends-the-web3-euro-summit-2023/"
                  ><div class="Ylol5t">
                    <img
                      alt="AMLBot Team Attends The Web3 Euro Summit 2023"
                      loading="lazy"
                      width="469"
                      height="263"
                      decoding="async"
                      data-nimg="1"
                      style="color: transparent"
                      src="../../assets/image/4.webp"
                    />
                  </div>
                  <div class="TK3QTC"><span>2 min read</span></div>
                  <h3 class="U7Vn6H">
                    AMLBot Team Attends The Web3 Euro Summit 2023
                  </h3>
                  <p class="X0js5h">
                    At AMLBot, we constantly strive to connect with projects and
                    ...
                  </p></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="xoqlB_">
        <a
          class="TUFj6g"
          href="https://cointelegraph.com/news/kyc-to-stake-your-eth-it-s-probably-coming-to-the-us"
          ><img
            alt=""
            loading="lazy"
            width="164"
            height="60"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/cointelegraph.e7e32c87.svg" /></a
        ><a
          class="TUFj6g"
          href="https://www.benzinga.com/markets/cryptocurrency/23/03/31509547/regulation-rumble-unpacking-the-coinbase-sec-showdown-and-what-it-means-for-crypto"
          ><img
            alt=""
            loading="lazy"
            width="119"
            height="60"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/benzinga.9798d80a.svg" /></a
        ><a
          class="TUFj6g"
          href="https://coincodex.com/article/26583/analyzing-the-trends-of-usdt-and-usdc-traders-haven-or-risky-waters/"
          ><img
            alt=""
            loading="lazy"
            width="300"
            height="57"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/coincodex.029611ca.svg" /></a
        ><a
          class="TUFj6g"
          href="https://es.beincrypto.com/que-esperar-regulacion-criptomonedas-2023/"
          ><img
            alt=""
            loading="lazy"
            width="155"
            height="60"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/beincrypto.5605fabe.svg" /></a
        ><a
          class="TUFj6g"
          href="https://bitcoinist.com/dont-keep-your-public-key-too-public-and-other-tips-to-safeguard-your-crypto-wallet/"
          ><img
            alt=""
            loading="lazy"
            width="120"
            height="60"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/bitcoinist.1767d741.svg" /></a
        ><a
          class="TUFj6g"
          href="https://www.newsbtc.com/news/company/the-hidden-danger-of-stablecoins/"
          ><img
            alt=""
            loading="lazy"
            width="118"
            height="60"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/newsbtc.9b839ed1.svg" /></a
        ><a
          class="TUFj6g"
          href="https://dailyhodl.com/2022/12/19/top-five-hubs-for-crypto-startups/"
          ><img
            alt=""
            loading="lazy"
            width="163"
            height="60"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/thedailyhodl.92d43345.svg" /></a
        ><a
          class="TUFj6g"
          href="https://m.es.investing.com/news/cryptocurrency-news/evolucion-de-las-scams-con-criptomonedas-explorando-las-estafas-cripto-20192021-2330386?ampMode=1"
          ><img
            alt=""
            loading="lazy"
            width="130"
            height="60"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            src="../../assets/media/logo/investing.95103cb6.svg"
        /></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.Ylol5t {
    overflow: hidden;
    margin-bottom: 1.6rem;
    border-radius: 1.6rem;
    backface-visibility: hidden;
}
[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}
[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}
.swiper-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform,height;
}
.swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.swiper-autoheight, .swiper-autoheight .swiper-slide {
    height: auto;
}
[data-aos=fade-up] {
    transform: translate3d(0,100px,0);
}
</style>
