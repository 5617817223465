<template>
  <section class="I6ypnN">
    <div class="wrapper">
      <h2 class="WiFbIK aos-init " data-aos="fade-up" id="Title" :class="{'aos-animate': isVisibleTitle}">Why AMLBot?</h2>
      <div class="_HJUe6 Pe_j2I">
        <div class="_gbILZ UyNOnA">
          <div class="_7cxHPD _2IK3sC aos-init" data-aos="fade-up" id="First" :class="{'aos-animate': isVisibleFirst}">
            <div class="N5zZFF">
              <img
                alt=""
                loading="lazy"
                width="1072"
                height="1072"
                decoding="async"
                data-nimg="1"
                style="color: transparent"
                src="../../assets/image/why/1.webp"
              />
            </div>
            <div>
              <h3 class="vJJOgz">Personalized Approach</h3>
              <div class="AHae58">
                <ul>
                  <li>
                    AMLBot offers a wide range of compliance solutions
                    customized for each client.
                  </li>
                  <li>
                    We're confident in meeting your demands
                    <b>after helping 300+ crypto enterprises</b> of all sizes in
                    25 jurisdictions.
                  </li>
                </ul>
              </div>
              <div class="ylDXkw">
                <button class="i6oszE GgTu08 _365UGS">
                  <span class="pOE1_y">Let’s discuss</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="_gbILZ _5S4ixo">
          <div class="_7cxHPD sJPkUi aos-init" data-aos="fade-up" id="Second" :class="{'aos-animate': isVisibleSecond}">
            <div class="N5zZFF">
              <img
                alt=""
                loading="lazy"
                width="684"
                height="684"
                decoding="async"
                data-nimg="1"
                style="color: transparent"
                src="../../assets/image/why/2.webp"
              />
            </div>
            <h3 class="vJJOgz">Integrated Compliance Platform</h3>
            <div class="AHae58">
              <ul>
                <li>
                  We offer KYT/Wallet Screening, KYC, AML, and more for crypto
                  businesses.
                </li>
                <li>
                  AMLBot' risk scoring is based on multiple data sources,
                  ensuring that we have the most reliable data in the industry.
                </li>
                <li>
                  Our user-friendly services and solutions streamline your
                  company processes, removing compliance provider complexity.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="_gbILZ mGH0jS">
          <div
            class="_7cxHPD k15PyP is-hovered aos-init"
            data-aos="fade-up"
            data-aos-delay="50"
            id="Third"
            :class="{'aos-animate': isVisibleThird}"
          >
            <div class="N5zZFF">
              <img
                alt=""
                loading="lazy"
                width="684"
                height="684"
                decoding="async"
                data-nimg="1"
                style="color: transparent"
                src="../../assets/image/why/3.webp"
              />
            </div>
            <h3 class="vJJOgz">Customer Support</h3>
            <div class="AHae58">
              <p>
                AMLBot understands the significance of fast, friendly customer
                support, thus we're always here for our clients. 24/7 support.
              </p>
              <p>
                <small>An answer during the night may take a bit longer</small>
              </p>
            </div>
            <div class="ylDXkw">
              <button class="i6oszE _8dDVll DvWUkr hX3WJv zkITkJ">
                <span class="pOE1_y">Contact</span
                ><svg class="icon icon-arrow-right-long mj71Cs">
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#icon-arrow-right-long"
                  ></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:['bottom'],
  data: function(){
    return {
      isVisibleTitle: false,
      isVisibleFirst: false,
      isVisibleSecond: false,
      isVisibleThird: false,
    }
  },
  watch: {
    bottom() {
      if(this.bottom > this.locationTitle) {
        this.isVisibleTitle = true;
      } else {
        this.isVisibleTitle = false;
      }

      if(this.bottom > this.locationFirst) {
        this.isVisibleFirst = true;
      } else {
        this.isVisibleFirst = false;
      }
      
      if(this.bottom > this.locationSecond) {
        this.isVisibleSecond = true;
      } else {
        this.isVisibleSecond = false
      }
      
      if(this.bottom > this.locationThird) {
        this.isVisibleThird = true;
      } else {
        this.isVisibleThird = false
      }

    }
  },
  computed: {
    locationTitle: function(){
      return document.getElementById('Title').getBoundingClientRect().top + window.scrollY;
    },
    locationFirst: function(){
      return document.getElementById('First').getBoundingClientRect().top + window.scrollY;
    },
    locationSecond: function(){
      return document.getElementById('Second').getBoundingClientRect().top + window.scrollY;
    },
    locationThird: function(){
      return document.getElementById('Third').getBoundingClientRect().top + window.scrollY;
    },
  },
};
</script>

<style></style>
