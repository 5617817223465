<template>
  <section class="BqDNUl" id="faq">
    <div class="wrapper">
      <div class="sNtS4T">
        <h2 class="Y1se9F aos-init aos-animate" data-aos="fade-up">FAQs</h2>
      </div>
      <div class="cM4Cmj">
        <div class="tSfhz3 aos-init aos-animate" data-aos="fade-up">
          <h3 class="IoZS4u">Is your question not on the list?</h3>
          <p class="ji9kgN">
            Contact us via messenger. We are in touch <b>24/7</b>, so any issue
            can be resolved quickly and in a live chat format.
          </p>
          <button class="GmuvSO">
            <div class="LDENXf">
              <svg class="icon icon-helpcrunch">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-helpcrunch"
                ></use>
              </svg>
            </div>
            <div class="kXvM5_">
              <h4>We're in messenger</h4>
              <p>We will answer within 30 seconds</p>
            </div>
          </button>
          <p class="L9jxab">
            <small
              >It may take a little longer to respond during the night.</small
            >
          </p>
        </div>
        <div class="hTVpsn aos-init aos-animate" data-aos="fade-up" data-aos-delay="50">
          <div class="">
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(1)}">
              <div class="_0wcovB" @click="toggleObj(1)">What does the AMLBot check show?</div>
              <div v-if="checkObj(1)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    The basic AMLBot check result includes risk score
                    percentage, sources of risk, and belonging to the
                    blacklist.<br /><br />
                    In addition, the check result may include various optional
                    information about the address, such as belonging to the
                    cluster, actual balance, and transferred funds amount.<br /><br />
                    The check result may be incomplete in relation to the
                    described data if the necessary information is missing.<br /><br />
                    Please note that for blockchains that are in the limited
                    mode, clustering and percentage value of the check risk
                    score are not available. A risk score can be provided for a
                    counterparty only if it relates to an entity.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(2)}">
              <div class="_0wcovB" @click="toggleObj(2)">
                What does the percentage risk score mean?
              </div>
              <div v-if="checkObj(2)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    The overall risk score (percentage) is the probability that
                    the address is associated with illegal activity.<br /><br />
                    AMLBot finds connections of a checked address to other
                    addresses on the blockchain and with entities of different
                    categories, each with a different conditional risk score,
                    and calculates the overall risk score based on those
                    connections.<br /><br />
                    The overall risk score calculation takes into account the
                    risk severity of connections found. For example, in the case
                    of connections to entity categories Mining (0% risk) and
                    Darknet (100% risk), the risk impact of Darknet, as a more
                    risky category, will be higher, and Mining will have less
                    weight in the risk assessment.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(3)}">
              <div class="_0wcovB" @click="toggleObj(3)">What do the sources of risk mean?</div>
              <div v-if="checkObj(3)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    AMLBot checks the specified wallet address for connections
                    to known blockchain services - entities. AMLBot
                    conventionally groups these services into groups with
                    different risk levels of illegal activity - entity
                    categories.<br /><br />
                    The AML check shows the connections of the checked address
                    to these entity categories as the sources of risk, with
                    which the address interacted, and the percentage of funds
                    transferred from/to these services.<br /><br />
                    Based on all the sources of risk, an overall risk score is
                    calculated, which helps the user to make further decisions
                    about the assets.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(4)}">
              <div class="_0wcovB" @click="toggleObj(4)">
                What is the difference between an address and a transaction
                checks?
              </div>
              <div v-if="checkObj(4)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    An address check is an analysis of all its connections to
                    other addresses and entities on blockchains, considering
                    incoming and outgoing funds movement.<br /><br />
                    A transaction check process differs from the address check,
                    and the result depends on your side in the transaction. The
                    overall risk score always relates to the counterparty.<br /><br />
                    To check a transaction you need to specify the TxID and the
                    destination address of the transaction and select your side
                    in the transaction:<br />
                    - Recipient (you got a deposit to your wallet) - the
                    addresses from which the funds were received are checked.
                    The sources of risk describe the services from which the TX
                    senders accumulated the transferred funds with a percentage
                    breakdown.<br />
                    - Sender (you made a withdrawal from your wallet) - the
                    wallet that received the funds is checked. The sources of
                    risk describe all connections of the recipient address with
                    a percentage breakdown.<br /><br />
                    Thus, when checking a transaction, the risks for the
                    recipient are checked if you receive funds, and the risks
                    for the sender if you send funds.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(5)}">
              <div class="_0wcovB" @click="toggleObj(5)">How do I understand risk assessment?</div>
              <div v-if="checkObj(5)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    Each client determines for himself what percentage of risk
                    is acceptable for him. Conventionally, the risk score can be
                    divided as follows: <br />
                    - 0-25% is a clean wallet/transaction; <br />
                    - 25-75% is the average level of risk; <br />
                    - 75%+ such a wallet/transaction is considered risky. <br />
                    It is also worth paying attention to the red sources of risk
                    in the detailed analysis, described in
                    <a href="https://amlbot.com/what-do-we-analyze">page </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(6)}">
              <div class="_0wcovB" @click="toggleObj(6)">
                What cryptocurrencies does AMLBot analyze?
              </div>
              <div v-if="checkObj(6)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    AMLBot supports all major blockchains and tokens on them. We
                    are constantly adding support for additional
                    cryptocurrencies. You can always check the up-to-date list
                    of supported cryptocurrencies in
                    <a href="http://web.amlbot.com" target="_blank">
                      web dashboard
                    </a>
                    or in
                    <a href="https://docs.amlbot.com/" target="_blank">
                      API Documentation
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(7)}">
              <div class="_0wcovB" @click="toggleObj(7)">
                How does AMLBot help to protect you against blocking?
              </div>
              <div v-if="checkObj(7)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    By checking counterparties' wallets before a transaction,
                    you can reject their assets if the risk score is high. Also,
                    before transferring funds to other services, you can check
                    your wallet address and save the result (make a
                    screenshot).<br /><br />
                    If the check shows that your assets had no connection with
                    illegal activity and the service blocked you, you can
                    provide the saved result to confirm the purity of your
                    assets.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(8)}">
              <div class="_0wcovB" @click="toggleObj(8)">
                The risk is higher than 50%, but I am certain that the address
                is reliable. What to do?
              </div>
              <div v-if="checkObj(8)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    The check results are based on the international databases,
                    which are constantly updated. So an address that had 0% risk
                    yesterday may have received or given the asset to a risky
                    counterparty today. In this case, the risk score will
                    change. If you want to be sure of the result and determine
                    what the cause of the high risk is, we can do a detailed
                    check for you. To do it, email us at
                    <a href="mailto:info@amlbot.com"> info@amlbot.com</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(9)}">
              <div class="_0wcovB" @click="toggleObj(9)">How often are checks recommended to do?</div>
              <div v-if="checkObj(9)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    An answer to this question depends on your unique risk
                    model. The general recommendation would be to perform an AML
                    check every time you interact with an unknown wallet or a
                    smart contract.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(10)}">
              <div class="_0wcovB" @click="toggleObj(10)">How quickly is the balance replenished?</div>
              <div v-if="checkObj(10)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    After transaction confirmation, the balance is replenished:
                    <br />
                    • up to 10 minutes if the payment was made within 24 hours
                    after the invoice was issued, <br />
                    • up to 25 minutes if the payment was made after 24 hours
                    after the invoice issuance. Overall, BTC, ETH, USDT, and
                    fiat are processed faster than other cryptocurrencies.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(11)}">
              <div class="_0wcovB" @click="toggleObj(11)">
                What happens if I don't use all my checks each month?
              </div>
              <div v-if="checkObj(11)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    If you purchased checks without expiration date - they
                    remain in your account, and you can use them at any time.<br /><br />
                    If you purchased checks with a time limit - they will be
                    deducted from your account after expiration date.
                  </div>
                </div>
              </div>
            </div>
            <div class="cepbRO" :class="{'Iz3FCj': checkObj(12)}">
              <div class="_0wcovB" @click="toggleObj(12)">What if I will need more checks?</div>
              <div v-if="checkObj(12)" class="SsdHEB" style="height: auto; transition: all 0.25s">
                <div>
                  <div class="oUo8lV">
                    You can buy additional checks as needed. The number of
                    checks is always displayed within your user information.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      openedObj: []
    }
  },
  methods: {
    toggleObj: function(number) {
      const index = this.openedObj.indexOf(number)
      if(index === -1) this.openedObj.push(number)
      else this.openedObj.splice(index, 1)
    },
    checkObj: function(number) {
      const index = this.openedObj.indexOf(number)
      if(index === -1) return false
      else return true
    }
  }
};
</script>

<style>
.cepbRO.Iz3FCj ._0wcovB:after {
    opacity: 0;
}
.opened {
  height:295px;
}
</style>
