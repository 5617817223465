<template>
  <head>
    <title>AMLBot - The full-fledged crypto compliance solution</title>
    <noscript data-n-css=""></noscript>
  </head>
</template>

<script>
export default {
};
</script>

<style>
html[dir="ltr"],
[data-sonner-toaster][dir="ltr"] {
  --toast-icon-margin-start: -3px;
  --toast-icon-margin-end: 4px;
  --toast-svg-margin-start: -1px;
  --toast-svg-margin-end: 0px;
  --toast-button-margin-start: auto;
  --toast-button-margin-end: 0;
  --toast-close-button-start: 0;
  --toast-close-button-end: unset;
  --toast-close-button-transform: translate(-35%, -35%);
}
html[dir="rtl"],
[data-sonner-toaster][dir="rtl"] {
  --toast-icon-margin-start: 4px;
  --toast-icon-margin-end: -3px;
  --toast-svg-margin-start: 0px;
  --toast-svg-margin-end: -1px;
  --toast-button-margin-start: 0;
  --toast-button-margin-end: auto;
  --toast-close-button-start: unset;
  --toast-close-button-end: 0;
  --toast-close-button-transform: translate(35%, -35%);
}
[data-sonner-toaster] {
  position: fixed;
  width: var(--width);
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --gray1: hsl(0, 0%, 99%);
  --gray2: hsl(0, 0%, 97.3%);
  --gray3: hsl(0, 0%, 95.1%);
  --gray4: hsl(0, 0%, 93%);
  --gray5: hsl(0, 0%, 90.9%);
  --gray6: hsl(0, 0%, 88.7%);
  --gray7: hsl(0, 0%, 85.8%);
  --gray8: hsl(0, 0%, 78%);
  --gray9: hsl(0, 0%, 56.1%);
  --gray10: hsl(0, 0%, 52.3%);
  --gray11: hsl(0, 0%, 43.5%);
  --gray12: hsl(0, 0%, 9%);
  --border-radius: 8px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  z-index: 999999999;
}
[data-sonner-toaster][data-x-position="right"] {
  right: max(var(--offset), env(safe-area-inset-right));
}
[data-sonner-toaster][data-x-position="left"] {
  left: max(var(--offset), env(safe-area-inset-left));
}
[data-sonner-toaster][data-x-position="center"] {
  left: 50%;
  transform: translate(-50%);
}
[data-sonner-toaster][data-y-position="top"] {
  top: max(var(--offset), env(safe-area-inset-top));
}
[data-sonner-toaster][data-y-position="bottom"] {
  bottom: max(var(--offset), env(safe-area-inset-bottom));
}
[data-sonner-toast] {
  --y: translateY(100%);
  --lift-amount: calc(var(--lift) * var(--gap));
  z-index: var(--z-index);
  position: absolute;
  opacity: 0;
  transform: var(--y);
  touch-action: none;
  will-change: transform, opacity, height;
  transition: transform 0.4s, opacity 0.4s, height 0.4s, box-shadow 0.2s;
  box-sizing: border-box;
  outline: none;
  overflow-wrap: anywhere;
}
[data-sonner-toast][data-styled="true"] {
  padding: 16px;
  background: var(--normal-bg);
  border: 1px solid var(--normal-border);
  color: var(--normal-text);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 12px #0000001a;
  width: var(--width);
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
}
[data-sonner-toast]:focus-visible {
  box-shadow: 0 4px 12px #0000001a, 0 0 0 2px #0003;
}
[data-sonner-toast][data-y-position="top"] {
  top: 0;
  --y: translateY(-100%);
  --lift: 1;
  --lift-amount: calc(1 * var(--gap));
}
[data-sonner-toast][data-y-position="bottom"] {
  bottom: 0;
  --y: translateY(100%);
  --lift: -1;
  --lift-amount: calc(var(--lift) * var(--gap));
}
[data-sonner-toast] [data-description] {
  font-weight: 400;
  line-height: 1.4;
  color: inherit;
}
[data-sonner-toast] [data-title] {
  font-weight: 500;
  line-height: 1.5;
  color: inherit;
}
[data-sonner-toast] [data-icon] {
  display: flex;
  height: 16px;
  width: 16px;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  margin-left: var(--toast-icon-margin-start);
  margin-right: var(--toast-icon-margin-end);
}
[data-sonner-toast][data-promise="true"] [data-icon] > svg {
  opacity: 0;
  transform: scale(0.8);
  transform-origin: center;
  animation: sonner-fade-in 0.3s ease forwards;
}
[data-sonner-toast] [data-icon] > * {
  flex-shrink: 0;
}
[data-sonner-toast] [data-icon] svg {
  margin-left: var(--toast-svg-margin-start);
  margin-right: var(--toast-svg-margin-end);
}
[data-sonner-toast] [data-content] {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
[data-sonner-toast] [data-button] {
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  height: 24px;
  font-size: 12px;
  color: var(--normal-bg);
  background: var(--normal-text);
  margin-left: var(--toast-button-margin-start);
  margin-right: var(--toast-button-margin-end);
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transition: opacity 0.4s, box-shadow 0.2s;
}
[data-sonner-toast] [data-button]:focus-visible {
  box-shadow: 0 0 0 2px #0006;
}
[data-sonner-toast] [data-button]:first-of-type {
  margin-left: var(--toast-button-margin-start);
  margin-right: var(--toast-button-margin-end);
}
[data-sonner-toast] [data-cancel] {
  color: var(--normal-text);
  background: rgba(0, 0, 0, 0.08);
}
[data-sonner-toast][data-theme="dark"] [data-cancel] {
  background: rgba(255, 255, 255, 0.3);
}
[data-sonner-toast] [data-close-button] {
  position: absolute;
  left: var(--toast-close-button-start);
  right: var(--toast-close-button-end);
  top: 0;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: var(--gray1);
  color: var(--gray12);
  border: 1px solid var(--gray4);
  transform: var(--toast-close-button-transform);
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.1s, background 0.2s, border-color 0.2s;
}
[data-sonner-toast] [data-close-button]:focus-visible {
  box-shadow: 0 4px 12px #0000001a, 0 0 0 2px #0003;
}
[data-sonner-toast] [data-disabled="true"] {
  cursor: not-allowed;
}
[data-sonner-toast]:hover [data-close-button] {
  opacity: 1;
}
[data-sonner-toast]:focus [data-close-button] {
  opacity: 1;
}
[data-sonner-toast]:focus-within [data-close-button] {
  opacity: 1;
}
[data-sonner-toast]:hover [data-close-button]:hover {
  background: var(--gray2);
  border-color: var(--gray5);
}
[data-sonner-toast][data-swiping="true"]:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}
[data-sonner-toast][data-y-position="top"][data-swiping="true"]:before {
  bottom: 50%;
  transform: scaleY(3) translateY(50%);
}
[data-sonner-toast][data-y-position="bottom"][data-swiping="true"]:before {
  top: 50%;
  transform: scaleY(3) translateY(-50%);
}
[data-sonner-toast][data-swiping="false"][data-removed="true"]:before {
  content: "";
  position: absolute;
  inset: 0;
  transform: scaleY(2);
}
[data-sonner-toast]:after {
  content: "";
  position: absolute;
  left: 0;
  height: calc(var(--gap) + 1px);
  bottom: 100%;
  width: 100%;
}
[data-sonner-toast][data-mounted="true"] {
  --y: translateY(0);
  opacity: 1;
}
[data-sonner-toast][data-expanded="false"][data-front="false"] {
  --scale: var(--toasts-before) * 0.05 + 1;
  --y: translateY(calc(var(--lift-amount) * var(--toasts-before)))
    scale(calc(-1 * var(--scale)));
  height: var(--front-toast-height);
}
[data-sonner-toast] > * {
  transition: opacity 0.4s;
}
[data-sonner-toast][data-expanded="false"][data-front="false"][data-styled="true"]
  > * {
  opacity: 0;
}
[data-sonner-toast][data-visible="false"] {
  opacity: 0;
  pointer-events: none;
}
[data-sonner-toast][data-mounted="true"][data-expanded="true"] {
  --y: translateY(calc(var(--lift) * var(--offset)));
  height: var(--initial-height);
}
[data-sonner-toast][data-removed="true"][data-front="true"][data-swipe-out="false"] {
  --y: translateY(calc(var(--lift) * -100%));
  opacity: 0;
}
[data-sonner-toast][data-removed="true"][data-front="false"][data-swipe-out="false"][data-expanded="true"] {
  --y: translateY(calc(var(--lift) * var(--offset) + var(--lift) * -100%));
  opacity: 0;
}
[data-sonner-toast][data-removed="true"][data-front="false"][data-swipe-out="false"][data-expanded="false"] {
  --y: translateY(40%);
  opacity: 0;
  transition: transform 0.5s, opacity 0.2s;
}
[data-sonner-toast][data-removed="true"][data-front="false"]:before {
  height: calc(var(--initial-height) + 20%);
}
[data-sonner-toast][data-swiping="true"] {
  transform: var(--y) translateY(var(--swipe-amount, 0px));
  transition: none;
}
[data-sonner-toast][data-swipe-out="true"][data-y-position="bottom"],
[data-sonner-toast][data-swipe-out="true"][data-y-position="top"] {
  animation: swipe-out 0.2s ease-out forwards;
}
@keyframes swipe-out {
  0% {
    transform: translateY(
      calc(var(--lift) * var(--offset) + var(--swipe-amount))
    );
    opacity: 1;
  }
  to {
    transform: translateY(
      calc(
        var(--lift) * var(--offset) + var(--swipe-amount) + var(--lift) * -100%
      )
    );
    opacity: 0;
  }
}
@media (max-width: 600px) {
  [data-sonner-toaster] {
    position: fixed;
    --mobile-offset: 16px;
    right: var(--mobile-offset);
    left: var(--mobile-offset);
    width: 100%;
  }
  [data-sonner-toaster] [data-sonner-toast] {
    left: 0;
    right: 0;
    width: calc(100% - 32px);
  }
  [data-sonner-toaster][data-x-position="left"] {
    left: var(--mobile-offset);
  }
  [data-sonner-toaster][data-y-position="bottom"] {
    bottom: 20px;
  }
  [data-sonner-toaster][data-y-position="top"] {
    top: 20px;
  }
  [data-sonner-toaster][data-x-position="center"] {
    left: var(--mobile-offset);
    right: var(--mobile-offset);
    transform: none;
  }
}
[data-sonner-toaster][data-theme="light"] {
  --normal-bg: #fff;
  --normal-border: var(--gray4);
  --normal-text: var(--gray12);
  --success-bg: hsl(143, 85%, 96%);
  --success-border: hsl(145, 92%, 91%);
  --success-text: hsl(140, 100%, 27%);
  --info-bg: hsl(208, 100%, 97%);
  --info-border: hsl(221, 91%, 91%);
  --info-text: hsl(210, 92%, 45%);
  --warning-bg: hsl(49, 100%, 97%);
  --warning-border: hsl(49, 91%, 91%);
  --warning-text: hsl(31, 92%, 45%);
  --error-bg: hsl(359, 100%, 97%);
  --error-border: hsl(359, 100%, 94%);
  --error-text: hsl(360, 100%, 45%);
}
[data-sonner-toaster][data-theme="light"]
  [data-sonner-toast][data-invert="true"] {
  --normal-bg: #000;
  --normal-border: hsl(0, 0%, 20%);
  --normal-text: var(--gray1);
}
[data-sonner-toaster][data-theme="dark"]
  [data-sonner-toast][data-invert="true"] {
  --normal-bg: #fff;
  --normal-border: var(--gray3);
  --normal-text: var(--gray12);
}
[data-sonner-toaster][data-theme="dark"] {
  --normal-bg: #000;
  --normal-border: hsl(0, 0%, 20%);
  --normal-text: var(--gray1);
  --success-bg: hsl(150, 100%, 6%);
  --success-border: hsl(147, 100%, 12%);
  --success-text: hsl(150, 86%, 65%);
  --info-bg: hsl(215, 100%, 6%);
  --info-border: hsl(223, 100%, 12%);
  --info-text: hsl(216, 87%, 65%);
  --warning-bg: hsl(64, 100%, 6%);
  --warning-border: hsl(60, 100%, 12%);
  --warning-text: hsl(46, 87%, 65%);
  --error-bg: hsl(358, 76%, 10%);
  --error-border: hsl(357, 89%, 16%);
  --error-text: hsl(358, 100%, 81%);
}
[data-rich-colors="true"] [data-sonner-toast][data-type="success"],
[data-rich-colors="true"]
  [data-sonner-toast][data-type="success"]
  [data-close-button] {
  background: var(--success-bg);
  border-color: var(--success-border);
  color: var(--success-text);
}
[data-rich-colors="true"] [data-sonner-toast][data-type="info"],
[data-rich-colors="true"]
  [data-sonner-toast][data-type="info"]
  [data-close-button] {
  background: var(--info-bg);
  border-color: var(--info-border);
  color: var(--info-text);
}
[data-rich-colors="true"] [data-sonner-toast][data-type="warning"],
[data-rich-colors="true"]
  [data-sonner-toast][data-type="warning"]
  [data-close-button] {
  background: var(--warning-bg);
  border-color: var(--warning-border);
  color: var(--warning-text);
}
[data-rich-colors="true"] [data-sonner-toast][data-type="error"],
[data-rich-colors="true"]
  [data-sonner-toast][data-type="error"]
  [data-close-button] {
  background: var(--error-bg);
  border-color: var(--error-border);
  color: var(--error-text);
}
.sonner-loading-wrapper {
  --size: 16px;
  height: var(--size);
  width: var(--size);
  position: absolute;
  inset: 0;
  z-index: 10;
}
.sonner-loading-wrapper[data-visible="false"] {
  transform-origin: center;
  animation: sonner-fade-out 0.2s ease forwards;
}
.sonner-spinner {
  position: relative;
  top: 50%;
  left: 50%;
  height: var(--size);
  width: var(--size);
}
.sonner-loading-bar {
  animation: sonner-spin 1.2s linear infinite;
  background: var(--gray11);
  border-radius: 6px;
  height: 8%;
  left: -10%;
  position: absolute;
  top: -3.9%;
  width: 24%;
}
.sonner-loading-bar:nth-child(1) {
  animation-delay: -1.2s;
  transform: rotate(0.0001deg) translate(146%);
}
.sonner-loading-bar:nth-child(2) {
  animation-delay: -1.1s;
  transform: rotate(30deg) translate(146%);
}
.sonner-loading-bar:nth-child(3) {
  animation-delay: -1s;
  transform: rotate(60deg) translate(146%);
}
.sonner-loading-bar:nth-child(4) {
  animation-delay: -0.9s;
  transform: rotate(90deg) translate(146%);
}
.sonner-loading-bar:nth-child(5) {
  animation-delay: -0.8s;
  transform: rotate(120deg) translate(146%);
}
.sonner-loading-bar:nth-child(6) {
  animation-delay: -0.7s;
  transform: rotate(150deg) translate(146%);
}
.sonner-loading-bar:nth-child(7) {
  animation-delay: -0.6s;
  transform: rotate(180deg) translate(146%);
}
.sonner-loading-bar:nth-child(8) {
  animation-delay: -0.5s;
  transform: rotate(210deg) translate(146%);
}
.sonner-loading-bar:nth-child(9) {
  animation-delay: -0.4s;
  transform: rotate(240deg) translate(146%);
}
.sonner-loading-bar:nth-child(10) {
  animation-delay: -0.3s;
  transform: rotate(270deg) translate(146%);
}
.sonner-loading-bar:nth-child(11) {
  animation-delay: -0.2s;
  transform: rotate(300deg) translate(146%);
}
.sonner-loading-bar:nth-child(12) {
  animation-delay: -0.1s;
  transform: rotate(330deg) translate(146%);
}
@keyframes sonner-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes sonner-fade-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
@keyframes sonner-spin {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0.15;
  }
}
@media (prefers-reduced-motion) {
  [data-sonner-toast],
  [data-sonner-toast] > *,
  .sonner-loading-bar {
    transition: none !important;
    animation: none !important;
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  transition: opacity 0.2s, transform 0.2s;
}
.loader[data-visible="false"] {
  opacity: 0;
  transform: scale(0.8) translate(-50%, -50%);
}

html {
  font-family: "__Inter_003395", "__Inter_Fallback_003395", Helvetica, Arial,
    sans-serif;
}

.cookieconsent-optin,
.cookieconsent-optin-preferences,
.cookieconsent-optin-statistics,
.cookieconsent-optin-marketing {
  display: block;
  display: initial;
}
.cookieconsent-optout-preferences,
.cookieconsent-optout-statistics,
.cookieconsent-optout-marketing,
.cookieconsent-optout {
  display: none;
}

.go2933276541 {
  position: fixed;
  display: block;
  width: 100%;
  height: 0px;
  margin: 0px;
  padding: 0px;
  overflow: visible;
  transform-style: preserve-3d;
  background: transparent;
  backface-visibility: hidden;
  pointer-events: none;
  left: 0px;
  z-index: 9998;
}
.go2369186930 {
  top: 0px;
  z-index: 9999;
  height: 100%;
  width: 100%;
}
.go1348078617 {
  bottom: 0px;
}
.go2417249464 {
  position: fixed;
  z-index: 9989;
}
.go3921366393 {
  left: 0;
  bottom: 0;
}
.go3967842156 {
  right: 0;
  bottom: 0;
}
.go613305155 {
  left: 0;
  top: 0;
}
.go471583506 {
  right: 0;
  top: 0;
}
.go3670563033 {
  position: relative;
  overflow: hidden;
  display: none;
}
.go1041095097 {
  display: block;
}
.go1632949049 {
  position: absolute;
  pointer-events: none;
  width: 101vw;
  height: 101vh;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  z-index: -1;
}
.go2512015367 {
  z-index: 99998;
  opacity: 0.8;
  visibility: visible;
  pointer-events: all;
  cursor: pointer;
}
.go1432718904 {
  overflow: hidden;
}
.go812842568 {
  display: block !important;
  position: static !important;
  box-sizing: border-box !important;
  background: transparent !important;
  border: none;
  min-height: 0px !important;
  max-height: none !important;
  margin: 0px;
  padding: 0px !important;
  height: 100% !important;
  width: 1px !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.go2729809544 {
  z-index: 99999;
  visibility: hidden;
  position: absolute;
  inset: 50vh auto auto 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  opacity: 0;
  pointer-events: none;
  max-height: 95%;
  max-width: 95%;
}
.go1011812720 {
  pointer-events: auto !important;
  visibility: visible;
  opacity: 1;
  transition: opacity 1s ease-in;
}
.go456419034 {
  transition: opacity 0.3s ease-in;
}
.go3128134379 {
  pointer-events: auto !important;
  visibility: visible !important;
  max-height: 95vh !important;
  transition: max-height 1s ease-in;
}
.go494047706 {
  z-index: 9999;
  width: 100%;
  max-height: 95%;
  position: fixed;
  visibility: hidden;
}
.go2481764524 {
  z-index: 9999;
  width: 100%;
  max-height: 95%;
  position: fixed;
  visibility: hidden;
  bottom: 0px;
}
.go2685733372 {
  visibility: hidden;
}
.go2985984737 {
  visibility: visible !important;
}
.go3281949485 {
  pointer-events: auto !important;
  visibility: visible !important;
  max-height: 95vh !important;
  transform: none !important;
}
.go3539422596 {
  z-index: 9999;
  width: 100%;
  max-height: 95%;
  position: fixed;
  visibility: hidden;
  transition: transform 0.5s cubic-bezier(0.31, -0.2, 0.04, 1.47);
}
.go988075951 {
  z-index: 9999;
  position: fixed;
  left: 10px;
  top: 10px;
  max-height: 95vh !important;
  max-width: 95%;
  visibility: hidden;
}
.go2699082514 {
  z-index: 9999;
  position: fixed;
  right: 10px;
  top: 10px;
  max-height: 95vh !important;
  max-width: 95%;
  visibility: hidden;
}
.go1595992025 {
  z-index: 9999;
  position: fixed;
  left: 10px;
  bottom: 10px;
  max-height: 95vh !important;
  max-width: 95%;
  visibility: hidden;
}
.go1222083472 {
  z-index: 9999;
  position: fixed;
  right: 10px;
  bottom: 10px;
  max-height: 95vh !important;
  max-width: 95%;
  visibility: hidden;
}
.go722322694 {
  transition: none !important;
}
.go26732895 {
  cursor: pointer;
}
.go2083580917 {
  display: flex;
  justify-content: center;
  align-items: center;
}

html.hs-messages-widget-open.hs-messages-mobile,
html.hs-messages-widget-open.hs-messages-mobile body {
  overflow: hidden !important;
  position: relative !important;
}
html.hs-messages-widget-open.hs-messages-mobile body {
  height: 100% !important;
  margin: 0 !important;
}
#hubspot-messages-iframe-container {
  display: initial !important;
  z-index: 2147483647;
  position: fixed !important;
  bottom: 0 !important;
}
#hubspot-messages-iframe-container.widget-align-left {
  left: 0 !important;
}
#hubspot-messages-iframe-container.widget-align-right {
  right: 0 !important;
}
#hubspot-messages-iframe-container.internal {
  z-index: 1016;
}
#hubspot-messages-iframe-container.internal iframe {
  min-width: 108px;
}
#hubspot-messages-iframe-container .hs-shadow-container {
  display: initial !important;
  z-index: -1;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  content: "";
}
#hubspot-messages-iframe-container .hs-shadow-container.internal {
  display: none !important;
}
#hubspot-messages-iframe-container .hs-shadow-container.active {
  width: 400px;
  height: 400px;
}
#hubspot-messages-iframe-container iframe {
  display: initial !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  background: transparent !important;
}
.hs-drag-handle {
  position: absolute;
  bottom: 16px;
  cursor: grab;
  height: 60px;
  display: none;
  align-items: center;
}
.hs-drag-handle--right {
  right: 76px;
}
.hs-drag-handle--left {
  left: 76px;
}
.hs-drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  z-index: 999;
  display: none;
}

#hubspot-messages-iframe-container {
  z-index: 9990 !important;
}
</style>
