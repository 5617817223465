<template>
  <div style="opacity: 1; transform: none">
    <main class="_8Ppbo_" id="prime">
      <div class="wrapper TSY1GI">
        <div class="Qm5pMA">
          <h1 class="K6SnkQ">
            One-stop compliance solution for crypto business
          </h1>
          <p class="_8TvVN">
            The AMLBot platform automates AML / KYC procedures and reduces
            compliance expenses
          </p>
          <div class="PMqxQr">
            <button class="i6oszE GgTu08 _365UGS interact-button">
              <span class="pOE1_y">Check Wallet</span>
            </button>
            <a
              rel="noopener noreferrer nofollow"
              class="i6oszE _8dDVll DvWUkr hX3WJv ZsJpqW interact-button"
              ><span class="pOE1_y">For individuals</span
              ><svg class="icon icon-arrow-right-long mj71Cs">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-arrow-right-long"
                ></use></svg
            ></a>
          </div>
        </div>
        <div class="KxZynA">
          <img
            alt="One-stop compliance solution for crypto business"
            fetchpriority="high"
            loading="eager"
            width="1680"
            height="1040"
            decoding="async"
            data-nimg="1"
            style="color: transparent"
            srcset="../assets/image/cover.webp, ../assets/image/cover.webp"
            src="../assets/image/cover.webp"
          />
        </div>
      </div>
    </main>
    <div class="rJZASt">
      <div class="wrapper">
        <div class="CL6iOz">
          <div class="uuV7Aw">
            <a
              target="_blank"
              class="GxLv1Y undefined undefined mclpCO"
              href="https://www.trustpilot.com/review/amlbot.com"
              ><img
                alt="Trustpilot"
                loading="lazy"
                width="126"
                height="31"
                decoding="async"
                data-nimg="1"
                class="c3oiSx"
                style="color: transparent"
                src="../assets/media/logo/logo-trustpilot.7960630a.svg"
              />
              <div class="anPQ6J">
                <div class="c_kRaM">
                  <svg
                    viewBox="0 0 47 47"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      fill="#219654"
                      points="0 46.330002 46.375587 46.330002 46.375587 0 0 0"
                    ></polygon>
                    <path
                      d="M39.532606,19.711433 L13.229706,38.800651 L17.067125,27.002824 L7.019821,19.711433 L19.438795,19.711433 L23.276213,7.912809 L27.113631,19.711433 L39.532606,19.711433 L39.532606,19.711433 Z M23.27717,31.510075 L30.460765,30.000499 L33.322879,38.800651 L23.27717,31.510075 Z"
                      fill="#FFFFFF"
                    ></path></svg
                  ><svg
                    viewBox="0 0 47 47"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      fill="#219654"
                      points="0 46.330002 46.375587 46.330002 46.375587 0 0 0"
                    ></polygon>
                    <path
                      d="M39.532606,19.711433 L13.229706,38.800651 L17.067125,27.002824 L7.019821,19.711433 L19.438795,19.711433 L23.276213,7.912809 L27.113631,19.711433 L39.532606,19.711433 L39.532606,19.711433 Z M23.27717,31.510075 L30.460765,30.000499 L33.322879,38.800651 L23.27717,31.510075 Z"
                      fill="#FFFFFF"
                    ></path></svg
                  ><svg
                    viewBox="0 0 47 47"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      fill="#219654"
                      points="0 46.330002 46.375587 46.330002 46.375587 0 0 0"
                    ></polygon>
                    <path
                      d="M39.532606,19.711433 L13.229706,38.800651 L17.067125,27.002824 L7.019821,19.711433 L19.438795,19.711433 L23.276213,7.912809 L27.113631,19.711433 L39.532606,19.711433 L39.532606,19.711433 Z M23.27717,31.510075 L30.460765,30.000499 L33.322879,38.800651 L23.27717,31.510075 Z"
                      fill="#FFFFFF"
                    ></path></svg
                  ><svg
                    viewBox="0 0 47 47"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      fill="#219654"
                      points="0 46.330002 46.375587 46.330002 46.375587 0 0 0"
                    ></polygon>
                    <path
                      d="M39.532606,19.711433 L13.229706,38.800651 L17.067125,27.002824 L7.019821,19.711433 L19.438795,19.711433 L23.276213,7.912809 L27.113631,19.711433 L39.532606,19.711433 L39.532606,19.711433 Z M23.27717,31.510075 L30.460765,30.000499 L33.322879,38.800651 L23.27717,31.510075 Z"
                      fill="#FFFFFF"
                    ></path></svg
                  ><svg
                    viewBox="0 0 47 47"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      fill="#D9D9D9"
                      points="0 46.330002 46.375587 46.330002 46.375587 0 0 0"
                    ></polygon>
                    <polygon
                      fill="#219654"
                      points="0 46.330002 23.187793 46.330002 23.187793 0 0 0"
                    ></polygon>
                    <path
                      d="M39.532606,19.711433 L13.229706,38.800651 L17.067125,27.002824 L7.019821,19.711433 L19.438795,19.711433 L23.276213,7.912809 L27.113631,19.711433 L39.532606,19.711433 L39.532606,19.711433 Z M23.27717,31.510075 L30.460765,30.000499 L33.322879,38.800651 L23.27717,31.510075 Z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                </div>
                <div class="fOC61H"><b>4.5</b> out of 5</div>
              </div></a
            >
          </div>
          <div class="Wgijsj">
            <h3 class="EMUxMP">We are trusted members of</h3>
            <ul class="NDhKxT">
              <li>
                <img
                  alt="INATBA"
                  loading="lazy"
                  width="64"
                  height="64"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../assets/media/logo/INATBA.webp"
                /><span>INATBA</span>
              </li>
              <li>
                <img
                  alt="CDA"
                  loading="lazy"
                  width="64"
                  height="64"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../assets/media/logo/CDA.webp"
                /><span>CDA</span>
              </li>
              <li>
                <img
                  alt="ATII"
                  loading="lazy"
                  width="64"
                  height="64"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../assets/media/logo/ATII.webp"
                /><span>ATII</span>
              </li>
              <li>
                <img
                  alt="LSW3"
                  loading="lazy"
                  width="64"
                  height="64"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../assets/media/logo/LSW3.webp"
                /><span>LSW3</span>
              </li>
              <li>
                <img
                  alt="EU Blockchain Association"
                  loading="lazy"
                  width="64"
                  height="64"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../assets/media/logo/EUBA.webp"
                /><span>ЕВА</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Partners :bottom="bottom"></Partners>
    <BlueLine :bottom="bottom"></BlueLine>
    <Initiatives :bottom="bottom"></Initiatives>
    <Services :bottom="bottom"></Services>
    <Assurance :bottom="bottom"></Assurance>
    <Why :bottom="bottom"></Why>
    <CoreTeam :bottom="bottom"></CoreTeam>
    <FAQ :bottom="bottom"></FAQ>
  </div>
</template>

<script>
import Assurance from "./MainSections/Assurance.vue";
import BlueLine from "./MainSections/BlueLine.vue";
import CoreTeam from "./MainSections/CoreTeam.vue";
import FAQ from "./MainSections/FAQ.vue";
import Initiatives from "./MainSections/Initiatives.vue";
import Partners from "./MainSections/Partners.vue";
import Services from "./MainSections/Services.vue";
import Why from "./MainSections/Why.vue";

export default {
  components: {
    Assurance,
    BlueLine,
    CoreTeam,
    FAQ,
    Initiatives,
    Partners,
    Services,
    Why,
  },
  data: function() {
    return {
      screenLength: document.documentElement.clientHeight,
      bottom: this.screenLength
    }
  },
  methods: {
    // Send the transaction using either the Web3Provider or InfuraProvider
    scroll() {
      this.bottom = window.scrollY + this.screenLength
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scroll);
  },
};
</script>

<style>
img {
  border-style: none;
}
</style>
