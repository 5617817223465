<template>
  <section class="HuZNLe">
    <div class="wrapper" id="partnerTitle">
      <h2 class="_2YIvoq aos-init" data-aos="fade-up" :class="{'aos-animate': isVisibleTitle}">
        Our 300+ clients and partners
      </h2>
    </div>
    <div data-aos="fade-up" class="aos-init" id="partnerCarousel" :class="{'aos-animate': isVisibleCarousel}">
      <div class="egxr2U">
        <div
          class="swiper swiper-initialized swiper-horizontal swiper-free-mode"
        >
          <div
            class="swiper-wrapper"
            style="
              transition-duration: 30000ms;
            "
            :class="{'moving': isVisibleCarousel}"
          >
            <div
              class="swiper-slide"
              data-swiper-slide-index="9"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="954"
                  height="315"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/comistar.webp"
                />
                <!-- <v-lazy-img
                  alt=""
                  loading="eager"
                  width="954"
                  height="315"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  srcset="
                    ../assets/media/logo/comistar.webp&amp;w=1080&amp;q=75 1x,
                    ../assets/media/logo/comistar.webp&amp;w=1920&amp;q=75 2x
                  "
                  src="../assets/media/logo/comistar.webp&amp;w=1920&amp;q=75"
                /> -->
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="10"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="954"
                  height="315"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/credits.webp"
                />
                <!-- <v-lazy-img
                  alt=""
                  loading="eager"
                  width="954"
                  height="315"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  srcset="
                    ../../assets/media/logo/credits.webp&amp;w=1080&amp;q=75 1x,
                    ../../assets/media/logo/credits.webp&amp;w=1920&amp;q=75 2x
                  "
                  src="../../assets/media/logo/credits.webp&amp;w=1920&amp;q=75"
                /> -->
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="11"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="416"
                  height="168"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/goodcrypto.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="12"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="636"
                  height="210"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/letsexchange.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="13"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="672"
                  height="141"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/misttrack.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="14"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="998"
                  height="274"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/alfabit.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="0"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="396"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/boxexchenger.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="1"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/crystal.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="2"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/kunaio.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="3"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/e-scrooge.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-prev"
              data-swiper-slide-index="4"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/cryptopnl.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-active"
              data-swiper-slide-index="5"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/gateio.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide swiper-slide-next"
              data-swiper-slide-index="6"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/safe3.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="7"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/purefi.webp"
                />
              </div>
            </div>
            <div
              class="swiper-slide"
              data-swiper-slide-index="8"
              style="margin-right: 20px"
            >
              <div class="h3bjE4">
                <img
                  alt=""
                  loading="eager"
                  width="424"
                  height="140"
                  decoding="async"
                  data-nimg="1"
                  style="color: transparent"
                  src="../../assets/media/logo/eclipcoin.webp"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:['bottom'],
  data: function(){
    return {
      isVisibleTitle: false,
      isVisibleCarousel: false
    }
  },
  watch: {
    bottom() {
      if(this.bottom > this.locationTitle) {
        this.isVisibleTitle = true;
      } else {
        this.isVisibleTitle = false;
      }
      
      if(this.bottom > this.locationCarousel) {
        this.isVisibleCarousel = true;
      } else {
        this.isVisibleCarousel = false
      }

    }
  },
  computed: {
    locationTitle: function(){
      return document.getElementById('partnerTitle').getBoundingClientRect().top + window.scrollY;
    },
    locationCarousel: function(){
      return document.getElementById('partnerCarousel').getBoundingClientRect().top + window.scrollY;
    },
  },
  methods:{
  },
};
</script>

<style>
[data-aos=fade-up] {
    transform: translate3d(0,100px,0);
}
[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity,transform;
}
[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
    transition-duration: .4s;
}
[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
    transition-timing-function: ease;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translateZ(0);
}
.swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}

.swiper-horizontal {
    touch-action: pan-y;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    box-sizing: content-box;
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}
.moving {
  transform: translate3d(-1936.94px, 0px, 0px);
}
</style>
