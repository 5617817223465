<template>
  <div class="tmXwhz">
    <div class="wrapper">
      <div class="iVg6s0">
        <h1>User Agreement</h1>
        <p>
          This User Agreement (the “<strong>Agreement</strong>”), addressed to
          an unlimited number of persons and constituting an official public
          offer of the Licensor (as defined below) to conclude an agreement for
          the provision of services with any Licensee (as defined below), is
          made between:
        </p>
        <p>
          <strong>SAFELEMENT LIMITED,</strong> a company registered in Hong Kong
          with the company registry number 3148041, whose registered office is
          located at Flat H, 3/F, Tower 5, The Beaumount, 8 Shek Kok Road,
          Tseung Kwan O, N.T., Hong Kong in case you are the resident of any
          country <strong>excluding European Union</strong> (the
          “<strong>Licensor</strong>”)&nbsp;
        </p>
        <p>Or</p>
        <p>
          <strong>Safe3, UAB</strong> having its registered office at Taikos pr.
          111-74, LT-94230 Klaipėda in case you are
          <strong>resident of European Union</strong> (the
          “<strong>Licensor</strong>”)
        </p>
        <p>And&nbsp;</p>
        <p>
          Any person who accepted the terms of this Agreement
          (“<strong>Licensee</strong>”).&nbsp;
        </p>
        <p>
          The Licensor and Licensee are hereinafter also referred to
          individually as "<strong>Party</strong>" and together as
          "<strong>Parties</strong>", as the case may be.
        </p>
        <p><strong>WHEREAS</strong>:</p>
        <ol>
          <li>
            The Licensee wishes to use the services as described in this
            Agreement; and
          </li>
        </ol>
        <ol>
          <li>
            The Licensor agrees to provide the Licensee with the services as
            described in this Agreement.
          </li>
        </ol>
        <p>
          When ordering / paying / providing the potential Licensee’s data for
          the services of the Licensor, the Licensees accept the terms and
          conditions of this Agreement as follows:
        </p>
        <h2>1. GENERAL PROVISIONS</h2>
        <ol>
          <li>
            This Agreement is made by providing the Licensee's full and
            unconditional consent to conclude the Agreement in full, without
            signing a written copy of the Agreement by the Parties.
          </li>
          <li>
            The Agreement has full legal force and effect and is equivalent to
            the Agreement signed by the Parties in writing.
          </li>
          <li>
            The Licensee confirms the fact of familiarization and agreement with
            all the terms and conditions of this Agreement in full by its
            acceptance.
          </li>
          <li>
            Any of the following actions is considered acceptance of this public
            offer agreement: the fact of registration of the Licensee on the
            website of the Licensor and registration of the order of the
            Licensor’s services on the website of the Licensor at
            <a href="https://amlbot.com/ru">https://amlbot.com/</a> or in
            Lisensor’s networks;&nbsp;payment for the Licensor’s services on the
            terms and in the manner determined by this Agreement or through the
            corresponding page (if available) of the Licensor’s website&nbsp;<a
              href="https://amlbot.com/ru"
              >https://amlbot.com/</a
            >; written (including in electronic form by means of e-mail)
            notification of the Licensee about the acceptance of the terms of
            this Agreement to the e-mail address indicated on the
            website&nbsp;<a href="https://amlbot.com/ru">https://amlbot.com/</a
            >.
          </li>
          <li>
            By concluding this Agreement, the Licensee automatically agrees with
            the full and unconditional acceptance of the provisions of this
            Agreement, prices for services and all annexes that are integral
            parts of the Agreement.
          </li>
          <li>
            The Licensor reserves the right to introduce amendments to this
            Agreement at any time at its sole discretion without prior
            notification to the Licensee, and the Licensee shall from time to
            time review this Agreement, the current version of which shall be at
            all times available on the website
            <a href="https://amlbot.com/ru">https://amlbot.com/</a>. Where the
            Licensee does not accept the updated terms and conditions of this
            Agreement, the Licensee shall immediately stop using the services of
            the Licensor provided for under this Agreement.
          </li>
          <li>
            If the Licensee does not agree with the terms of the Agreement, he
            is not entitled to enter into this Agreement, and also does not have
            the right to use the Services under this Agreement.
          </li>
        </ol>
        <h2>2. DEFINITIONS</h2>
        <p>
          When used in this Agreement, the following terms shall have the
          respective meanings indicated, such meanings to be applicable to both
          the singular and plural forms of the terms defined:
        </p>
        <p>
          <strong>“Access Credentials” </strong>means any username,
          identification number, password, license or security key, security
          token, PIN or other security code, method, technology or device used,
          alone or in combination, to verify an individual’s identity and
          authorization to access and use Hosted Services.
        </p>
        <p>
          <strong>“Action” </strong>has the meaning set forth in Section 13.1.
        </p>
        <p>
          <strong>“Agreement” </strong>has the meaning set forth in the
          preamble.
        </p>
        <p>
          <strong>“Authorized User” </strong>means each of the individuals
          authorized by or on behalf of Licensee to use the Services pursuant to
          Section 4.1.
        </p>
        <p>
          <strong>“Confidential Information” </strong>means, as set forth in
          Section 10.1 and including, without limitation, the Services, the
          Licensor Materials and terms and conditions of this Agreement.
        </p>
        <p>
          <strong>“Disclosing Party” </strong>has the meaning set forth in
          Section 10.1.
        </p>
        <p>
          <strong>“Documentation” </strong>means the documentation for the
          Software and Services – any manuals, instructions or other documents
          or materials that Licensor provides or makes available to Licensee in
          any form or medium and which describe the functionality, components,
          features or requirements of the Services or Licensor Materials,
          including any aspect of the installation, configuration, integration,
          operation, use, support or maintenance thereof.
        </p>
        <p>
          <strong>“Effective Date” </strong>is the date of acceptance by the
          Licensee of the terms of this Agreement.&nbsp;
        </p>
        <p>
          <strong>“Error” </strong>means a material and continuing failure of
          the Software and Services to function in conformity with the
          Specifications.
        </p>
        <p><strong>“Fees” </strong>has the meaning set forth in Section 9.1.</p>
        <p>
          <strong>“Force Majeure Event” </strong>has the meaning set forth in
          Section 15.1.
        </p>
        <p>
          <strong>“Harmful Code” </strong>means any software, hardware or other
          technology, device or means, including any virus, worm, malware or
          other malicious computer code, the purpose or effect of which is to
          (a) permit unauthorized access to, or to destroy, disrupt, disable,
          distort or otherwise harm or impede in any manner any (i) computer,
          software, firmware, hardware, system or network or (ii) any
          application or function of any of the foregoing or the security,
          integrity, confidentiality or use of any data Processed thereby, or
          (b) prevent Licensee or any Authorized User from accessing or using
          the Services or Licensor Systems as intended by this Agreement.
          “Harmful Code” does not include any Licensor Disabling Device.
        </p>
        <p>
          <strong>“Hosted Services” </strong>has the meaning set forth in
          Section 3.1. <strong>“Indemnitee” </strong>has the meaning set forth
          in Section 13.3. <strong>“Indemnitor” </strong>has the meaning set
          forth in Section 13.3. <strong>“Initial Term” </strong>has the meaning
          set forth in Section 11.1.
        </p>
        <p>
          <strong>“Intellectual Property Rights” </strong>means any and all
          registered and unregistered rights granted, applied for or otherwise
          now or hereafter in existence under or related to any patent,
          copyright, trademark, trade secret, database protection or other
          intellectual property rights laws or practice, and all similar or
          equivalent rights or forms of protection, in any part of the world.
        </p>
        <p>
          <strong>“Law” </strong>means any applicable statute, law, ordinance,
          regulation, rule, code, order, constitution, treaty, common law,
          judgment, decree or other requirement of any federal, state, local or
          foreign government or political subdivision thereof, regulatory agency
          or arbitrator, mediator, court or tribunal of competent jurisdiction.
        </p>
        <p>
          <strong>“Licensee Data” </strong>means, other than Resultant Data,
          information, data and other content, in any form or medium, that is
          collected, downloaded or otherwise received, directly or indirectly
          from Licensee or an Authorized User by or through the Services.
        </p>
        <p>
          <strong>“Licensee Failure” </strong>has the meaning set forth in
          Section 5.2.
        </p>
        <p>
          <strong>“Licensee Systems” </strong>means Licensee’s information
          technology infrastructure, including computers, software, hardware,
          databases, electronic systems (including database management systems)
          and networks, whether operated directly by Licensee or through the use
          of third party services.
        </p>
        <p>
          <strong>“Losses” </strong>means any and all losses, damages,
          liabilities, deficiencies, claims, actions, judgments, settlements,
          interest, awards, penalties, fines, costs or expenses of whatever
          kind, including reasonable attorneys’ fees and the costs of enforcing
          any right hereunder and the cost of pursuing any insurance providers.
        </p>
        <p>
          <strong>“Licensor`s Disabling Device” </strong>means any software,
          hardware or other technology, device or means (including any back
          door, time bomb, time out, drop dead device, software routine or other
          disabling device) used by Licensor or its designee to disable any
          Person’s (including, without limitation, Licensee’s or any Authorized
          User’s) access to or use of the Services automatically with the
          passage of time or under the positive control of Licensor or its
          designee.
        </p>
        <p>
          <strong>“Licensor`s Materials” </strong>means the Software,
          Documentation, Specifications and Licensor Systems and any and all
          other information, data, documents, materials, works and other
          content, devices, methods, processes, hardware, software and other
          technologies and inventions, including any deliverables, technical or
          functional descriptions, requirements, plans or reports, that are
          provided or used by Licensor or any Subcontractor in connection with
          the Services or otherwise comprise or relate to the Services or
          Licensor Systems. For the avoidance of doubt, Licensor Materials
          include Resultant Data and any information, data or other content
          derived from Licensor’s monitoring of Licensee’s access to or use of
          the Services, but do not include Licensee Data.
        </p>
        <p>
          <strong>“Licensor Personnel” </strong>means all individuals involved
          in the performance of Services as employees, agents or independent
          contractors of Licensor or any Subcontractor.
        </p>
        <p>
          <strong>“Licensor`s Systems” </strong>means the information technology
          infrastructure used by or on behalf of Licensor in performing the
          Services, including all computers, software, hardware, databases,
          electronic systems (including database management systems) and
          networks, whether operated directly by Licensor or through the use of
          third party services.
        </p>
        <p>
          <strong>“Person” </strong>means an individual, corporation,
          partnership, joint venture, limited liability entity, governmental
          authority, unincorporated organization, trust, association or other
          entity.
        </p>
        <p>
          <strong>“Privacy Policy” </strong>means Licensor`s and its affiliates’
          data privacy policies, as posted on
          <a href="https://amlbot.com/privacy-policy/"
            >https://amlbot.com/privacy-policy/</a
          >, as may be amended by Licensor or its affiliates from time to time.
        </p>
        <p>
          <strong>“Process” </strong>means to take any action or perform any
          operation or set of operations that the Services are capable of taking
          or performing on any data, information or other content, including to
          collect, receive, input, upload, download, record, reproduce, store,
          organize, compile, combine, log, catalog, cross-reference, manage,
          maintain, copy, adapt, alter, translate or make other derivative works
          or improvements, process, retrieve, output, consult, use, perform,
          display, disseminate, transmit, submit, post, transfer, disclose or
          otherwise provide or make available, or block, erase or destroy.
          “Processing” and “Processed” have correlative meanings.
        </p>
        <p>
          <strong>“Receiving Party” </strong>has the meaning set forth in
          Section 10.1.
        </p>
        <p>
          <strong>“Renewal Term” </strong>has the meaning set forth in Section
          11.2.
        </p>
        <p>
          “<strong>Representatives</strong>” means, with respect to a Person,
          that Person’s affiliates and their employees, officers, directors,
          consultants, agents, independent contractors, service providers,
          sub-licensees, subcontractors and legal, tax and financial advisors.
        </p>
        <p>
          <strong>“Resultant Data” </strong>means information, data and other
          content that is derived by or through the Services from Processing or
          aggregating Licensee Data and is sufficiently different from such
          Licensee Data that such Licensee Data cannot be reverse engineered or
          otherwise identified from the inspection, analysis or further
          Processing of such information, data or content.
        </p>
        <p>
          <strong>“Scheduled Downtime” </strong>has the meaning set forth in
          Section 6.2.
        </p>
        <p>
          <strong>“Service Software</strong>” means the Licensor`s software
          application and any third party or other software, and all new
          versions, updates, revisions, improvements and modifications of the
          foregoing, that Licensor provides remote access to and use of as part
          of the Services.
        </p>
        <p>
          <strong>“Services” </strong>means any services provided by Licensor or
          its contractors to Licensee in connection with this Agreement,
          including software as a service (SaaS), installation, configuration,
          integration, customization training, and/or technical support, as
          specified on the relevant page of the website
          <a href="https://amlbot.com/ru">https://amlbot.com/</a>, including
          Hosted Services.
        </p>
        <p>
          <strong>“Software” </strong>means the computer programs specified on
          the relevant page of the website
          <a href="https://amlbot.com/ru">https://amlbot.com/</a> in
          machine-readable, object code form, and any computer programs
          delivered to Licensee in machine-readable, object code form and any
          updates thereto, or provided by Licensor in connection with any
          Services hereunder, and the Service Software.
        </p>
        <p>
          <strong>“Specifications</strong>” means Licensor’s current published
          product release definitions.
        </p>
        <p>
          <strong>“Subcontractor” </strong>has the meaning set forth in Section
          3.5.
        </p>
        <p>
          <strong>“Term” </strong>has the meaning set forth in Section 11.2.
        </p>
        <p>
          <strong>“User Agreement” </strong>means Licensor’s and its affiliates’
          terms of use, as posted on
          <a href="https://amlbot.com/terms-of-service"
            >https://amlbot.com/terms-of-service</a
          >, as may be amended by Licensor or its affiliates from time to time.
        </p>
        <p>
          <strong>“Third Party Materials” </strong>means materials and
          information, in any form or medium, including any software, documents,
          data, content, specifications, products, equipment or components of or
          relating to the Services that are not proprietary to Licensor.
        </p>
        <h2>3. SERVICES</h2>
        <ol>
          <li>
            Services. Subject to and conditioned on Licensee’s and its
            Authorized Users’ compliance with the terms and conditions of this
            Agreement, during the Term Licensor shall use commercially
            reasonable efforts to provide to Licensee and its Authorized Users
            the Services in accordance with the terms and conditions hereof,
            including to host, manage, operate and maintain the Service Software
            for remote electronic access and use by Licensee and its Authorized
            Users (“<strong>Hosted Services</strong>”) on an ongoing basis,
            except for:
          </li>
        </ol>
        <ol>
          <li>Scheduled Downtime in accordance with Section 6.2;</li>
          <li>Service downtime or degradation due to a Force Majeure Event;</li>
          <li>
            Any other circumstances beyond Licensor’s reasonable control,
            including Licensee’s or any Authorized User’s use of Third Party
            Materials, misuse of Hosted Services, or use of the Services other
            than in compliance with the express terms of this Agreement; and
          </li>
          <li>
            Any suspension or termination of Licensee’s or any Authorized Users’
            access to or use of Hosted Services as a result of a Licensee
            Failure or as otherwise permitted by this Agreement.
          </li>
        </ol>
        <ol>
          <li>
            Service and System Control. Except as otherwise expressly provided
            in this Agreement, as between the Parties:
          </li>
        </ol>
        <ol>
          <li>
            Licensor has and will retain sole control over the operation,
            provision, maintenance and management of the Services and Licensor`s
            Materials, including the: (i) Licensor Systems; (ii) selection,
            deployment, modification and replacement of the Service Software;
            and (iii) performance of maintenance, upgrades, corrections and
            repairs; and
          </li>
          <li>
            Licensee has and will retain sole control over the operation,
            maintenance and management of, and all access to and use of, the
            Licensee Systems, and sole responsibility for all access to and use
            of the Services and Licensor`s Materials by any Person by or through
            the Licensee Systems or any other means controlled by Licensee or
            any Authorized User, including any information, instructions or
            materials provided by any of them to Licensor or Subcontractors.
          </li>
        </ol>
        <ol>
          <li>
            Service Management. Licensee agrees throughout the Term to maintain
            within its organization a service manager to serve as Licensor’s
            primary point of contact for day-to-day communications, consultation
            and decision-making regarding the Services. Licensee shall ensure
            its service manager has the requisite organizational authority,
            skill, experience and other qualifications to perform in such
            capacity. If Licensee’s service manager ceases to be employed by it
            or it otherwise wishes to replace its service manager, Licensee
            shall promptly name a new service manager by written notice to
            Licensor.
          </li>
          <li>
            Changes. Licensor reserves the right, in its sole discretion, to
            make any changes to the Services and Licensor`s Materials that it
            deems necessary or useful to:&nbsp;
          </li>
        </ol>
        <p>
          (a) maintain or enhance (i) the quality or delivery of Licensor’s
          services to its customers, (ii) the competitive strength of or market
          for Licensor’s services or (iii) the Services’ cost efficiency or
          performance; or&nbsp;
        </p>
        <p>(b) to comply with the Law.</p>
        <ol>
          <li>
            Subcontractors. Licensor may from time to time in its sole
            discretion engage third parties to perform Services (each, a
            “<strong>Subcontractor</strong>”).
          </li>
          <li>
            Suspension or Termination of Services. Licensor may, directly or
            indirectly, and by use of a Licensor Disabling Device or any lawful
            means, suspend, terminate or otherwise deny Licensee’s, any
            Authorized User’s or any other Person’s access to or use of all or
            any part of the Services or Licensor`s Materials, without incurring
            any resulting obligation or liability, if:
          </li>
        </ol>
        <p>
          (a) Licensor receives a judicial or other governmental or regulatory
          demand or order, subpoena or law enforcement request that expressly or
          by reasonable implication requires Licensor to do so; or&nbsp;
        </p>
        <p>
          (b) Licensor believes, in its sole discretion, that (i) Licensee or
          any Authorized User has failed to comply with any term of this
          Agreement, or accessed or used the Services beyond the scope of the
          rights granted or for a purpose not authorized under this Agreement,
          (ii) Licensee or any Authorized User is, has been, or is likely to be
          involved in any fraudulent, misleading or unlawful activities, or
          (iii) this Agreement expires or is terminated. This Section 3.6 does
          not limit any of Licensor’s other rights or remedies, whether at law,
          in equity or under this Agreement.
        </p>
        <h2>4. AUTHORIZATIONS AND LICENSEE RESTRICTIONS</h2>
        <ol>
          <li>
            Authorization. Subject to and conditioned on Licensee’s payment of
            the Fees and compliance and performance in accordance with all other
            terms and conditions of this Agreement, Licensor hereby authorizes
            Licensee to nonexclusive, non transferable access and use, subject
            to the terms and conditions herein and during the Term, the Services
            and such Licensor Materials as Licensor may supply or make available
            to Licensee solely for the use by and through Authorized Users in
            accordance with the conditions and limitations set forth in this
            Agreement. This authorization is non-exclusive and, other than as
            may be expressly set forth in Section 16.6, non-transferable.
          </li>
          <li>
            Reservation of Rights. Except for the limited license in Section
            4.1, nothing in this Agreement grants any right, title or interest
            in or to (including any license under) any Intellectual Property
            Rights in or relating to, the Services, Licensor`s Materials or
            Third Party Materials, whether expressly, by implication, estoppel
            or otherwise. All right, title and interest in and to (including all
            license under) any Intellectual Property Rights in or relating to,
            the Services, Licensor`s Materials and Third Party Materials are and
            will remain with Licensor and the respective rights holders in the
            Third Party Materials.
          </li>
          <li>
            Authorization Limitations and Restrictions. Licensee shall not, and
            shall not permit any other Person to, access or use the Services or
            Licensor`s Materials except as expressly permitted by this Agreement
            and, in the case of Third Party Materials, the applicable third
            party license agreement. For purposes of clarity and without
            limiting the generality of the foregoing, Licensee shall not, except
            as this Agreement expressly permits:
          </li>
        </ol>
        <ol>
          <li>
            modify or create derivative works or improvements of the Services or
            Licensor`s Materials;
          </li>
          <li>
            copy the Software and Documentation, unless for archival or backup
            purposes only; in such case, all titles, trademarks, and copyright,
            proprietary and restricted rights notices shall be reproduced in all
            such copies, and all copies shall be subject to the terms of this
            Agreement;
          </li>
          <li>
            rent, lease, lend, sell, sublicense, assign, distribute, publish,
            transfer or otherwise make available any Services or Licensor`s
            Materials to any Person, including on or in connection with the
            internet or any time-sharing, service bureau, SaaS, cloud or other
            technology or service;
          </li>
          <li>
            reverse engineer, disassemble, decompile, decode, adapt or otherwise
            attempt to derive or gain access to the source code of the Services
            or Licensor`s Materials, in whole or in part;
          </li>
          <li>
            bypass or breach any security device or protection used by the
            Services or Licensor`s Materials or access or use the Services or
            Licensor`s Materials other than by an Authorized User through the
            use of such Authorized User’s own then valid Access Credentials;
          </li>
          <li>
            input, upload, transmit or otherwise provide to or through the
            Services or Licensor`s Systems, any information or materials that
            are unlawful or injurious, or contain, transmit or activate any
            Harmful Code;
          </li>
          <li>
            damage, destroy, disrupt, disable, impair, interfere with or
            otherwise impede or harm in any manner the Services, Licensor`s
            Systems or Licensor’s provision of services to any third party, in
            whole or in part;
          </li>
          <li>
            remove, delete, alter or obscure any trademarks, Documentation,
            Specification, warranties or disclaimers, or any copyright,
            trademark, patent or other intellectual property or proprietary
            rights notices from any Services or Licensor`s Materials, including
            any copy thereof;
          </li>
          <li>
            access or use the Services or Licensor`s Materials in any manner or
            for any purpose that infringes, misappropriates or otherwise
            violates any Intellectual Property Right or other right of any third
            party (including by any unauthorized access to, misappropriation,
            use, alteration, destruction or disclosure of the data of any other
            Licensor`s customer), or that violates any Law;
          </li>
          <li>
            take any action that might lead a third party (including an
            Authorized User) to conclude that the Services or Licensor`s
            Materials involve the provision of investment advice or
            recommendations;
          </li>
          <li>
            access or use the Services or Licensor`s Materials for purposes of
            competitive analysis of the Services or Licensor Materials, the
            development, provision or use of a competing software service or
            product or any other purpose that is to Licensor’s detriment or
            commercial disadvantage; or
          </li>
          <li>
            otherwise access or use the Services or Licensor Materials beyond
            the scope of the authorization granted under Section 4.1.
          </li>
        </ol>
        <h2>5. LICENSEE OBLIGATIONS</h2>
        <ol>
          <li>
            Licensee Systems and Cooperation. Licensee shall at all times during
            the Term: (a) set up, maintain and operate in good repair all
            Licensee Systems on or through which the Software or the Services
            are accessed or used; and (b) provide all cooperation and assistance
            as Licensor may reasonably request to enable Licensor to exercise
            its rights and perform its obligations under and in connection with
            this Agreement; (c) provide additional information requested by the
            Licensor.
          </li>
          <li>
            Effect of Licensee Failure or Delay. Licensor is not responsible or
            liable for any delay or failure of performance caused in whole or in
            part by Licensee’s delay in performing, or failure to perform, any
            of its obligations under this Agreement (each, a “<strong
              >Licensee Failure</strong
            >”).
          </li>
          <li>
            Corrective Action and Notice. If Licensee becomes aware of any
            actual or threatened activity prohibited by Section 4.3, Licensee
            shall cause its Authorized Users to, immediately: (a) take all
            reasonable and lawful measures within their respective control that
            are necessary to stop the activity or threatened activity and to
            mitigate its effects (including, where applicable, by discontinuing
            and preventing any unauthorized access to the Services and
            Licensor`s Materials and permanently erasing from their systems and
            destroying any data to which any of them have gained unauthorized
            access); and (b) notify Licensor of any such actual or threatened
            activity.
          </li>
          <li>
            Consent to Use Licensee Data. Licensee hereby irrevocably grants all
            such rights and permissions in or relating to Licensee Data: (a) to
            Licensor, its Subcontractors and the Licensor Personnel as are
            necessary or useful to perform the Services; and (b) to Licensor as
            are necessary or useful to enforce this Agreement and exercise its
            rights and perform its hereunder.
          </li>
        </ol>
        <h2>6. SERVICE LEVELS</h2>
        <ol>
          <li>
            Service Levels. Subject to the terms and conditions of this
            Agreement, Licensor will use commercially reasonable efforts to make
            Hosted Services available for access and use by Licensee and its
            Authorized Users over the Internet at least 99% of the time as
            measured over the course of each calendar month during the Term
            excluding unavailability due, in whole or in part, to any: (a) act
            or omission by Licensee or any Authorized User, access to or use of
            Hosted Services by Licensee or any Authorized User, or using
            Licensee’s or an Authorized User’s Access Credentials, that does not
            strictly comply with this Agreement; (b) Licensee Failure; (c)
            Licensee’s or its Authorized User’s Internet connectivity; (d) Force
            Majeure Event; (e) failure, interruption, outage or other problem
            with any software, hardware, system, network, facility or other
            matter not supplied by Licensor pursuant to this Agreement; (f)
            Scheduled Downtime; or (g) disabling, suspension or termination of
            the Services pursuant to Section 3.6. Service levels cannot be
            guaranteed and Licensor shall not be liable to Licensee or
            Authorized Users in the event Hosted Services are unavailable.
          </li>
          <li>
            Scheduled Downtime. Licensor will use commercially reasonable
            efforts to: (a) schedule downtime for routine maintenance of Hosted
            Services between the hours of 12:00 a.m. and 6:00 a.m., Eastern
            Standard Time; and (b) give Licensee at least 24 hours prior notice
            of all scheduled outages of Hosted Services (“<strong
              >Scheduled Downtime</strong
            >”).
          </li>
        </ol>
        <h2>7. DATA BACKUP</h2>
        <p>
          7.1. The Licensor will use commercially reasonable efforts to maintain
          regular data backups of Licensee Data; provided however, that LICENSOR
          HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION,
          DAMAGE, CORRUPTION OR RECOVERY OF LICENSEE DATA, EXCEPT IF OCCURED DUE
          TO THE LICENSOR’S DIRECT FAULT.
        </p>
        <h2>8. PRIVACY</h2>
        <ol>
          <li>
            Licensor`s Systems and Obligations. This Agreement incorporates by
            reference the Privacy Policy and the Agreement.
          </li>
          <li>
            Licensee Control and Responsibility. Licensee has and will retain
            sole responsibility for:
          </li>
        </ol>
        <p>
          (a) all Licensee Data (excluding data transmitted directly into the
          Licensor Systems by an Authorized User unaffiliated with Licensee),
          including its content and use, except as set forth in the Privacy
          Policy;&nbsp;
        </p>
        <p>
          (b) all information, instructions and materials provided by or on
          behalf of Licensee or any Authorized User in connection with the
          Services;&nbsp;
        </p>
        <p>(c) Licensee Systems;</p>
        <p>
          (d) the security and use of Licensee’s and its Authorized Users’
          Access Credentials; and (e) all access to and use of the Services and
          Licensor`s Materials directly or indirectly by or through the Licensee
          Systems or its or its Authorized Users’ Access Credentials, with or
          without Licensee’s knowledge or consent, including all results
          obtained from, and all conclusions, decisions and actions based on,
          such access or use.
        </p>
        <ol>
          <li>
            Access and Security. Licensee shall employ all physical,
            administrative and technical controls, screening and security
            procedures and other safeguards necessary to: (a) securely
            administer the distribution and use of all Access Credentials and
            protect against any unauthorized access to or use of Hosted
            Services; and (b) control the content and use of Licensee Data,
            including the uploading or other provision of Licensee Data for
            Processing by Hosted Services.
          </li>
        </ol>
        <h2>9. FEES AND PAYMENT TERMS</h2>
        <ol>
          <li>
            Fees. Licensee shall pay Licensor the fees set forth on the relevant
            page of the Licensor’s website
            <a href="https://amlbot.com/ru">https://amlbot.com/</a>
            (“<strong>Fees</strong>”) in accordance with this Section 9.
          </li>
          <li>
            Fee Increases. Licensor may increase Fees by providing written
            notice to Licensee at least 30 days prior to the effective date of
            the Fee increase, and the Fees will be deemed amended accordingly
            without further notice or consent; provided that Licensor will not
            increase Fees during the Initial Term. Licensee may terminate this
            Agreement effective as of the date of the Fee increase upon
            providing written notice to Licensor within 30 days of receipt of
            the notice of Fee increase.
          </li>
          <li>
            Taxes. All Fees and other amounts payable by Licensee under this
            Agreement are exclusive of taxes and similar assessments. Licensee
            is responsible for all sales, use and excise taxes, and any other
            similar taxes, duties and charges of any kind imposed by any
            federal, state or local governmental or regulatory authority on any
            amounts payable by Licensee hereunder, other than any taxes levied
            or imposed on Licensor’s income.
          </li>
          <li>
            Payment. All Fees shall be paid according to the order of the
            relevant page of the Licensor’s website
            <a href="https://amlbot.com/ru">https://amlbot.com/</a>, based on
            Licensor’s invoice to the Licensor's payment account specified below
            herein. Licensee consents to Licensor retaining and using Licensee’s
            payment information for future invoices and as provided in this
            Agreement. Licensee agrees and acknowledges that Licensor and its
            third party vendors may retain and use Licensee’s payment
            information to facilitate the payments provided for in this
            Agreement. The Parties agree to promptly provide the other Party
            with written notice of any update of or changes to its payment
            information. All payments shall be in US dollars in immediately
            available funds, if Parties have not agreed otherwise.
          </li>
          <li>
            Payment processed by third-party. There are cases where the Licensor
            deploys the third-party payment service provider to process the
            Licensee’s payment. A third-party payment service provider may block
            the payment and request additional information. The Licensee agrees
            to share with the Licensor such additional information or documents
            to comply with a third-party payment service provider’s request. In
            case of Licensee’s non-cooperation in this regard, the Licensor will
            not be able to complete the payment or return the payment to the
            Licensee if the Licensee will decide to proceed with a refund.
          </li>
          <li>
            Late Payment. If Licensee fails to make any payment when due then,
            in addition to all other remedies that may be available:
          </li>
        </ol>
        <ol>
          <li>
            Licensor may charge interest on the past due amount at the rate of
            1.5% per month, calculated daily and compounded monthly, or if
            lower, the highest rate permitted under Law; such interest may
            accrue after as well as before any judgment relating to collection
            of the amount due;
          </li>
          <li>
            Licensee shall reimburse Licensor for all costs incurred by Licensor
            in collecting any late payments or interest, including attorneys’
            fees, court costs and collection agency fees; and
          </li>
          <li>
            if such failure continues for 10 days following written notice
            thereof, Licensor may suspend performance of the Services until all
            past due amounts and interest thereon have been paid, without
            incurring any obligation or liability to Licensee or any other
            Person by reason of such suspension;
          </li>
        </ol>
        <p>
          provided that cumulative late payments are subject to the overall
          limits set forth in the relevant page of the Licensor’s website
          <a href="https://amlbot.com/ru">https://amlbot.com/</a>. A default
          under this Agreement by Licensee shall constitute a default by
          Licensee or its affiliates under all other agreements any of them have
          then in effect with Licensor or its affiliates.
        </p>
        <ol>
          <li>
            No Deductions or Setoffs. All amounts payable to Licensor under this
            Agreement shall be paid by Licensee to Licensor in full without any
            setoff, recoupment, counterclaim, deduction, debit or withholding
            for any reason (other than any deduction or withholding of tax as
            may be required by Law).
          </li>
        </ol>
        <h2>10. CONFIDENTIALITY</h2>
        <ol>
          <li>
            Confidential Information. In connection with this Agreement, each
            Party (“<strong>Disclosing Party</strong>”) may disclose or make
            available Confidential Information to the other party (“<strong
              >Receiving Party</strong
            >”). Subject to Section 10.2, “<strong
              >Confidential Information</strong
            >” means information in any form or medium (whether oral, written,
            electronic or other) that the Disclosing Party considers
            confidential or proprietary, including information consisting of or
            relating to the Disclosing Party’s or its affiliates’ technology,
            trade secrets, know-how, business operations, plans, strategies,
            customers, and pricing, and information with respect to which the
            Disclosing Party has contractual or other confidentiality
            obligations, in each case whether or not marked, designated or
            otherwise identified as “confidential”. Without limiting the
            foregoing, all Services and Licensor`s Materials, including the
            terms of this Agreement, are the Confidential Information of
            Licensor.
          </li>
          <li>
            Exclusions. Confidential Information does not include information
            that the Receiving Party can demonstrate by written or other
            documentary records: (a) was lawfully known to the Receiving Party
            without restriction on use or disclosure prior to such information
            being disclosed or made available to the Receiving Party in
            connection with this Agreement; (b) was or becomes generally known
            by the public other than by the Receiving Party’s or any of its
            Representatives’ noncompliance with this Agreement; (c) was or is
            received by the Receiving Party on a non-confidential basis from a
            third party that was not or is not, at the time of such receipt,
            under any obligation to maintain its confidentiality; or (d) the
            Receiving Party can demonstrate by written or other documentary
            records was or is independently developed by the Receiving Party
            without reference to or use of any Confidential Information.
          </li>
          <li>
            Protection of Confidential Information. As a condition to being
            provided with any disclosure of or access to Confidential
            Information, the Receiving Party shall:
          </li>
        </ol>
        <ol>
          <li>
            not access or use Confidential Information other than as necessary
            to exercise its rights or perform its obligations under and in
            accordance with this Agreement;
          </li>
          <li>
            except as may be permitted by and subject to its compliance with
            Section 10.4, not reveal, disclose or permit access to Confidential
            Information other than to its Representatives who:
          </li>
        </ol>
        <ol>
          <li>
            need to know such Confidential Information for purposes of the
            Receiving Party’s exercise of its rights or performance of its
            obligations under and in accordance with this Agreement;
          </li>
          <li>
            have been informed of the confidential nature of the Confidential
            Information; and
          </li>
          <li>
            are bound by confidentiality and restricted use obligations in
            substantially similar effect as the terms set forth in this Section
            10.3;
          </li>
        </ol>
        <ol>
          <li>
            safeguard and protect the Confidential Information from theft,
            piracy or unauthorized use, access or disclosure using at least the
            degree of care it uses to protect its similarly sensitive
            information and in no event less than a reasonable degree of care;
          </li>
          <li>
            ensure its Representatives’ compliance with, and be responsible and
            liable for any of its Representatives’ non-compliance with, the
            terms of this Section 10; and
          </li>
          <li>
            notify Disclosing Party upon discovery of any prohibited use or
            disclosure of the Confidential Information, or any other breach of
            these confidentiality obligations by Receiving Party, and shall
            fully cooperate with Disclosing Party to help Disclosing Party
            regain possession of the Confidential Information and prevent the
            further prohibited use or disclosure of the Confidential
            Information.
          </li>
        </ol>
        <ol>
          <li>
            Compelled Disclosures. If the Receiving Party or any of its
            Representatives is compelled by Law to disclose any Confidential
            Information then, to the extent permitted by Law, the Receiving
            Party shall: (a) promptly, and prior to such disclosure, notify the
            Disclosing Party in writing of such requirement so that the
            Disclosing Party can seek a protective order or other remedy or
            waive its rights under Section 10.3; and (b) provide reasonable
            assistance to the Disclosing Party in opposing such disclosure or
            seeking a protective order or other limitations on disclosure. If
            the Disclosing Party waives compliance or, after providing the
            notice and assistance required under this Section 10.4, the
            Receiving Party remains required by Law to disclose any Confidential
            Information, the Receiving Party shall disclose only that portion of
            the Confidential Information that the Receiving Party is legally
            required to disclose and, on the Disclosing Party’s request, shall
            use commercially reasonable efforts to obtain assurances from the
            applicable court or other presiding authority that such Confidential
            Information will be afforded confidential treatment. Notwithstanding
            the foregoing, the restrictions and requirements herein shall not
            apply to, and Receiving Party and its Representatives may disclose
            and retain copies of, Confidential Information in connection with
            Receiving Party’s or its Representatives’ compliance with legal,
            financial or regulatory filings, audits or examinations or as
            otherwise required by Law.
          </li>
        </ol>
        <h2>11. TERM AND TERMINATION</h2>
        <ol>
          <li>
            Initial Term. The initial term of this Agreement commences as of the
            Effective Date and, unless terminated earlier pursuant any of the
            Agreement’s express provisions, will continue in effect for one year
            (the “<strong>Initial Term</strong>”).
          </li>
          <li>
            Renewal. This Agreement will automatically renew for additional
            successive one-year terms unless earlier terminated pursuant to this
            Agreement’s express provisions or either Party gives the other Party
            written notice of non-renewal at least 90 days prior to the
            expiration of the then-current term (each a “<strong
              >Renewal Term</strong
            >” and, collectively with the Initial Term, the
            “<strong>Term</strong>”).
          </li>
          <li>Termination. In addition to Section 9.2 and Section 11.2:</li>
        </ol>
        <ol>
          <li>
            Licensor may terminate this Agreement, effective on written notice
            to Licensee, if Licensee: (i) fails to pay any amount when due
            hereunder, and such failure continues more than 30 days after
            Licensor’s delivery of written notice thereof; or (ii) breaches any
            of its obligations under Section 4.3 (Authorization Limitations and
            Restrictions) or Section 10 (Confidentiality).
          </li>
          <li>
            Either Party may terminate this Agreement, effective on written
            notice to the other Party, if the other Party materially breaches
            this Agreement, and such breach: (i) is incapable of cure within
            three business days of suffering Party’s notice to the breaching
            Party of the breach and suffering Party’s intent to terminate this
            Agreement; or (ii) being capable of cure, remains uncured 30 days
            after the non-breaching Party provides the breaching Party with
            written notice of such breach;
          </li>
          <li>
            Either Party may terminate this Agreement, effective immediately
            upon written notice to the other Party, if the other Party: (i)
            becomes insolvent or is generally unable to pay, or fails to pay,
            its debts as they become due; (ii) files or has filed against it, a
            petition for voluntary or involuntary bankruptcy or otherwise
            becomes subject, voluntarily or involuntarily, to any proceeding
            under any domestic or foreign bankruptcy or insolvency Law; (iii)
            makes or seeks to make a general assignment for the benefit of its
            creditors; or (iv) applies for or has appointed a receiver, trustee,
            custodian or similar agent appointed by order of any court of
            competent jurisdiction to take charge of or sell any material
            portion of its property or business; and
          </li>
          <li>
            Either Party may terminate this Agreement upon 90 days written
            notice to the other for any commercial or business reason.
          </li>
        </ol>
        <ol>
          <li>
            Effect of Expiration or Termination. Upon any expiration or
            termination of this Agreement, except as expressly otherwise
            provided in this Agreement (including Section 11.5 below):
          </li>
        </ol>
        <ol>
          <li>
            all rights, licenses, consents and authorizations granted by either
            Party to the other hereunder will immediately terminate;
          </li>
          <li>
            Licensor shall promptly cease all use of any Licensee Data or
            Licensee’s Confidential Information and erase all Licensee Data and
            Licensee’s Confidential Information from all systems Licensor
            controls; provided that, (i) for clarity, Licensors obligations
            under this Section 11.4(b) do not apply to any Resultant Data, (ii)
            Licensor and its affiliates may retain, use and disclose Licensee
            Data or Licensee Confidential Information as required by Law, and
          </li>
        </ol>
        <ol>
          <li>
            Licensor and its affiliates may retain Licensee Data and Licensee
            Confidential Information in its regular backup, archived or disaster
            recovery systems or files;
          </li>
        </ol>
        <ol>
          <li>
            Licensee shall promptly cease all use of any Services or Licensor`s
            Materials and (i) promptly return to Licensor, or at Licensor
            written request destroy, all documents and tangible materials
            containing, reflecting, incorporating or based on any Licensor
            Materials or Licensor’s Confidential Information; and (ii)
            permanently erase all Licensor`s Materials and Licensor’s
            Confidential Information from all systems Licensee directly or
            indirectly controls; provided that Licensee may retain Licensor
            Materials or Licensor’s Confidential Information in its regular
            backup, archived or disaster recovery systems or files, or as
            permitted by Section 10.4; an officer or director of Licensee shall,
            within 30 days from the effective date of the termination, certify
            in writing that all copies of the Software and Documentation have
            been returned, deleted and destroyed;
          </li>
          <li>
            Licensor may disable all Licensee and Authorized User access to
            Hosted Services and Licensor`s Materials;
          </li>
          <li>
            if Licensee terminates this Agreement pursuant to Section 11.3(b),
            Licensee will be relieved of any obligation to pay any Fees
            attributable to the period after the effective date of such
            termination and Licensor will refund to Licensee Fees paid in
            advance for Services that Licensor has not performed as of the
            effective date of termination; and
          </li>
          <li>
            if Licensor terminates this Agreement pursuant to Section 11.3(a) or
            Section 11.3(b), all Fees that would have become payable had the
            Agreement remained in effect until expiration of the Term will
            become immediately due and payable, and Licensee shall pay such
            Fees, together with all previously-accrued but not yet paid Fees, on
            receipt of Licensor’s invoice therefor.
          </li>
        </ol>
        <ol>
          <li>
            Surviving Terms. The provisions set forth in the following sections,
            and any other rights or obligations of the Parties in this Agreement
            that, by their nature, should survive termination or expiration of
            this Agreement, will survive any expiration or termination of this
            Agreement.
          </li>
        </ol>
        <h2>12. REPRESENTATIONS AND WARRANTIES</h2>
        <ol>
          <li>
            Mutual Representations and Warranties. Each Party represents and
            warrants to the other Party that:
          </li>
        </ol>
        <ol>
          <li>
            it is duly organized, validly existing and in good standing as a
            corporation or other entity under the laws of the jurisdiction of
            its incorporation or other organization;
          </li>
          <li>
            it has the full right, power and authority to enter into and perform
            its obligations and grant the rights, licenses, consents and
            authorizations it grants or is required to grant under this
            Agreement;
          </li>
          <li>
            the execution of this Agreement has been duly authorized by all
            necessary corporate or organizational action of such Party;
          </li>
          <li>
            its signatory to this Agreement is authorized to execute this
            Agreement on such Party’s behalf; and
          </li>
          <li>
            this Agreement constitutes the legal, valid and binding obligation
            of such a Party, enforceable against such Party in accordance with
            its terms.
          </li>
        </ol>
        <ol>
          <li>
            Additional Licensor`s Representations, Warranties and Covenants.
            Licensor represents, warrants and covenants to Licensee that
            Licensor will perform the Services using personnel of required
            skill, experience and qualifications and in a professional and
            workmanlike manner in accordance with generally recognized industry
            standards for similar services and will devote adequate resources to
            meet its obligations under this Agreement. Licensor also represents
            to Licensee that: (a) during the Term, the Software shall operate
            without any material Errors; and (b) upon notification to Licensor
            of any Errors, Licensor’s sole liability, and Licensee’s sole
            remedy, will be Licensor’s use of reasonable efforts during its
            normal business hours (<em>C</em>entral European Time
            <em>(CET)</em>) and at no cost to Licensee to correct such Errors
            that are verifiable and reproducible by Licensor, excluding any
            Errors caused by uses of the Software and Services not in accordance
            with the Specifications. Alternatively, in Licensor’s sole
            discretion, Licensor may refund the portion of the prepaid Fees
            applicable to the portion of the Software that is defective.
          </li>
          <li>
            Additional Licensee Representations, Warranties and Covenants.
            Licensee represents, warrants and covenants to Licensor that
            Licensee owns or otherwise has and will have the necessary rights
            and consents in and relating to the Licensee Data so that, as
            received by Licensor and Processed in accordance with this
            Agreement, they do not and will not infringe, misappropriate or
            otherwise violate any Intellectual Property Rights, or any privacy
            or other rights of any third party or violate any Law. Licensee
            acknowledges and agrees that the Services provided by Licensor under
            this Agreement are administrative and technological in nature and
            that Licensor is not providing investment advice, or otherwise
            acting in an investment advisory capacity, to Licensee or any
            Authorized User.
          </li>
          <li>
            DISCLAIMER OF WARRANTIES. EXCEPT FOR LICENSOR'S EXPRESS WARRANTIES
            SET FORTH IN SECTION 12.1, SECTION 12.2 AND SECTION 12.3, ALL
            SERVICES AND LICENSOR MATERIALS ARE PROVIDED “AS IS” AND LICENSOR
            HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY
            OR OTHER, AND LICENSOR SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES
            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING,
            USAGE OR TRADE PRACTICE. LICENSOR DOES NOT PROVIDE ANY INVESTMENT
            ADVISORY SERVICE, DUE DILIGENCE, BROKERAGE, FINANCIAL MANAGEMENT,
            TAX, ACCOUNTING OR ANY OTHER PROFESSIONAL SERVICE, AND ANY ADVICE OR
            OTHER INFORMATION OBTAINED THROUGH LICENSOR’S PRODUCTS AND SERVICES
            WILL BE USED BY LICENSEE AND ITS AUTHORIZED USERS SOLELY AT THEIR
            OWN RISK. WITHOUT LIMITING THE FOREGOING, LICENSOR MAKES NO WARRANTY
            OF ANY KIND THAT THE SERVICES OR LICENSOR MATERIALS, OR ANY PRODUCTS
            OR RESULTS OF THE USE THEREOF, WILL MEET LICENSEE’S OR ANY OTHER
            PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY
            INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR
            OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL
            CODE OR ERROR FREE. ALL THIRD PARTY MATERIALS ARE PROVIDED “AS IS”
            AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD PARTY
            MATERIALS IS STRICTLY BETWEEN LICENSEE AND THE THIRD PARTY OWNER OR
            DISTRIBUTOR OF THE THIRD PARTY MATERIALS.
          </li>
        </ol>
        <h2>13. INDEMNIFICATION</h2>
        <ol>
          <li>
            Licensor Indemnification. Subject to the limitations on liability in
            this Agreement, including as set forth in Section 14, Licensor shall
            indemnify, defend and hold harmless Licensee from and against any
            and all Losses incurred by Licensee arising out of or relating to
            any legal suit, claim, action or proceeding (each, an “Action”) by a
            third party (other than an affiliate of Licensee) to the extent that
            such Losses arise from any allegation in such Action that Licensee’s
            or an Authorized User’s use of the Services (excluding Licensee Data
            and Third Party Materials) in compliance with this Agreement
            infringes a Intellectual Property Right. The foregoing obligation
            does not apply to any Action or Losses arising out of or relating to
            any:
          </li>
        </ol>
        <ol>
          <li>
            access to or use of the Services or Licensor`s Materials in
            combination with any hardware, system, software, network or other
            materials or service not provided or authorized in writing by
            Licensor;
          </li>
          <li>
            modification of the Services or Licensor`s Materials other than: (i)
            by or on behalf of Licensor; or (ii) with Licensor’s written
            approval in accordance with Licensor’s written specification;
          </li>
          <li>
            failure to timely implement any modifications, upgrades,
            replacements or enhancements made available to Licensee by or on
            behalf of Licensor; or
          </li>
          <li>
            act, omission or other matter described in Section 13.2(a)-(g),
            whether or not the same results in any Action against or Losses by
            any Licensor Indemnitee.
          </li>
        </ol>
        <ol>
          <li>
            Licensee Indemnification. Licensee shall indemnify, defend and hold
            harmless Licensor and its Subcontractors and affiliates, and each of
            its and their respective officers, directors, employees, agents,
            Representatives, successors and assigns (each, a “Licensor
            Indemnitee”) from and against any and all Losses incurred by such
            Licensor Indemnitee in connection with any Action by a third party
            that arises out of or relates to any:
          </li>
        </ol>
        <ol>
          <li>
            Licensee Data, including any Processing of Licensee Data by or on
            behalf of Licensor in accordance with this Agreement;
          </li>
          <li>
            securities offering facilitated by Licensee or its Representatives,
            including any and all data and documentation related to such
            offering, the due diligence related to such offering, and/or the
            determination of suitability or qualification of a prospective
            investor for an offering;
          </li>
          <li>
            any other materials or information (including any documents, data,
            specifications, software, content or technology) provided by or on
            behalf of Licensee or any Authorized User, including Licensor’s
            compliance with any specifications or directions provided by or on
            behalf of Licensee or any Authorized User, to the extent prepared
            without any contribution by Licensor;
          </li>
          <li>
            brokerage services or investment advice; recommendations regarding
            any particular investment, security or course of action; offers to
            invest or to provide financial analysis or management services; or
            similar advice, offers or guidance to Authorized Users, which shall
            remain the sole responsibility of Licensee;
          </li>
          <li>
            allegation of facts that, if true, would constitute Licensee’s
            breach of any of its representations, warranties, covenants or
            obligations under this Agreement;
          </li>
          <li>
            negligence or more culpable act or omission (including recklessness
            or willful misconduct) by Licensee, any Authorized User, or any
            third party on behalf of Licensee or any Authorized User, in
            connection with this Agreement; or
          </li>
          <li>
            transaction for which the Services or Licensor`s Materials is being
            used by or on behalf of Licensee.
          </li>
        </ol>
        <ol>
          <li>
            Indemnification Procedure. Each Party shall promptly notify the
            other Party in writing of any Action for which such Party believes
            it is entitled to be indemnified pursuant to Section 12.1 or Section
            13.2, as the case may be. The Party seeking indemnification (the
            “<strong>Indemnitee</strong>”) shall cooperate with the other Party
            (the “<strong>Indemnitor</strong>”) at the Indemnitor’s sole cost
            and expense. The Indemnitor shall immediately take control of the
            defense and investigation of such Action and shall employ counsel
            reasonably acceptable to the Indemnitee to handle and defend the
            same, at the Indemnitor’s sole cost and expense. The Indemnitee’s
            failure to perform any obligations under this Section 13.3 will not
            relieve the Indemnitor of its obligations under this Section 13
            except to the extent that the Indemnitor can demonstrate that it has
            been materially prejudiced as a result of such failure. The
            Indemnitee may participate in and observe the proceedings at its own
            cost and expense with counsel of its own choosing.
          </li>
          <li>
            Mitigation. If any of the Services or Licensor`s Materials are, or
            in Licensor’s opinion are likely to be, claimed to infringe,
            misappropriate or otherwise violate any third party Intellectual
            Property Right, or if Licensee’s or any Authorized User’s use of the
            Services or Licensor`s Materials is enjoined or threatened to be
            enjoined, Licensor may, at its option:
          </li>
        </ol>
        <ol>
          <li>
            at Licensor’s sole cost and expense, obtain the right for Licensee
            to continue to use the Services and Licensor`s Materials materially
            as contemplated by this Agreement;
          </li>
          <li>
            at Licensor’s sole cost and expense, modify or replace the Services
            and Licensor Materials, in whole or in part, to seek to make the
            Services and Licensor Materials (as so modified or replaced)
            non-infringing, while providing substantially equivalent features
            and functionality, in which case such modifications or replacements
            will constitute Services and Licensor`s Materials, as applicable,
            under this Agreement; or
          </li>
          <li>
            by written notice to Licensee, terminate this Agreement and require
            Licensee to immediately cease any use of and destroy or return all
            copies of the Services and Licensor Materials in its possession or
            under its control.
          </li>
        </ol>
        <p>
          THIS SECTION 13 SETS FORTH LICENSEE’S SOLE REMEDIES AND LICENSOR’S
          SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED OR ALLEGED
          CLAIMS THAT THIS AGREEMENT OR ANY SUBJECT MATTER HEREOF (INCLUDING THE
          SERVICES AND LICENSOR’S MATERIALS) INFRINGES, MISAPPROPRIATES OR
          OTHERWISE VIOLATES ANY THIRD PARTY INTELLECTUAL PROPERTY RIGHT.
        </p>
        <h2>14. LIMITATIONS OF LIABILITY</h2>
        <ol>
          <li>
            EXCLUSION OF DAMAGES. LICENSOR AND ITS LICENSORS, SERVICE LICENSORS
            AND SUPPLIERS SHALL NOT BE LIABLE UNDER OR IN CONNECTION WITH THIS
            AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY,
            INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
            LIABILITY, BREACH OF WARRANTY, MISREPRESENTATIONS OR OTHERWISE, FOR
            ANY: (a) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT OR
            DIMINUTION IN VALUE; (b) IMPAIRMENT, INABILITY TO USE OR LOSS,
            INTERRUPTION OR DELAY OF THE SERVICES, (c) LOSS, DAMAGE, CORRUPTION
            OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY, OR (d)
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED OR
            PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF
            THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES
            WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY
            AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE. BOTH PARTIES
            UNDERSTAND AND AGREE THAT THE REMEDIES AND LIMITATIONS HEREIN
            ALLOCATE THE RISKS OF PRODUCT AND SERVICE NONCONFORMITY BETWEEN THE
            PARTIES AS AUTHORIZED BY LAW. THE FEES HEREIN REFLECT, AND ARE SET
            IN RELIANCE UPON, THIS ALLOCATION OF RISK AND THE EXCLUSION OF
            CONSEQUENTIAL DAMAGES SET FORTH IN THIS AGREEMENT.
          </li>
          <li>
            CAP ON MONETARY LIABILITY. IN ANY EVENT, THE COLLECTIVE AGGREGATE
            LIABILITY OF LICENSOR UNDER OR IN CONNECTION WITH THIS AGREEMENT OR
            ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING
            BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY,
            BREACH OF WARRANTY, MISREPRESENTATIONS OR OTHERWISE, SHALL BE
            LIMITED TO THE AMOUNT PAID TO LICENSOR BY LICENSEE UNDER THIS
            AGREEMENT. THE FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE
            FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
          </li>
        </ol>
        <h2>15. FORCE MAJEURE</h2>
        <ol>
          <li>
            No Breach or Default. In no event will either Party be liable or
            responsible to the other Party, or be deemed to have defaulted under
            or breached this Agreement, for any failure or delay in fulfilling
            or performing any term of this Agreement (except for any payment
            obligation) when and to the extent such failure or delay is caused
            by any circumstances beyond such Party’s reasonable control (a
            “<strong>Force Majeure Event</strong>”), including acts of God,
            flood, fire, earthquake or explosion, war, terrorism, invasion, riot
            or other civil unrest, embargoes or blockades in effect on or after
            the date of this Agreement, national or regional emergency, strikes,
            labor stoppages or slowdowns or other industrial disturbances,
            passage of Law or any action taken by a governmental or public
            authority, including imposing an embargo, export or import
            restriction, quota or other restriction or prohibition or any
            complete or partial government shutdown, or national or regional
            shortage of adequate power or telecommunications or transportation.
            Either Party may terminate this Agreement if a Force Majeure Event
            continues substantially uninterrupted for a period of 30 days or
            more.
          </li>
          <li>
            Affected Party Obligations. In the event of any failure or delay
            caused by a Force Majeure Event, the affected Party shall give
            prompt written notice to the other Party stating the period of time
            the occurrence is expected to continue and use commercially
            reasonable efforts to end the failure or delay and minimize the
            effects of such Force Majeure Event.
          </li>
        </ol>
        <h2>16. MISCELLANEOUS</h2>
        <ol>
          <li>
            Relationship of the Parties. The relationship between the Parties is
            that of independent contractors. Nothing contained in this Agreement
            shall be construed as creating any agency, partnership, joint
            venture or other form of joint enterprise, employment or fiduciary
            relationship between the Parties, and neither Party shall have
            authority to contract for or bind the other Party in any manner
            whatsoever.
          </li>
          <li>
            Public Announcements. Neither Party shall issue or release any
            announcement, statement, press release or other publicity or
            marketing materials relating to this Agreement or otherwise use the
            other Party’s trademarks, service marks, trade names, logos, domain
            names or other indicia of source, affiliation or sponsorship, in
            each case, without the prior written consent of the other Party,
            which consent shall not be unreasonably withheld, conditioned or
            delayed; provided, however, that Licensor may, without Licensee’s
            consent, include Licensee’s name and logo in Licensor’s promotional
            and marketing materials.
          </li>
          <li>
            Notices. All notices, requests, consents, claims, demands, waivers
            and other communications under this Agreement
            (“<strong>Notices</strong>”) have binding legal effect only if in
            writing (including email) and addressed to the Party as follows (or
            to such other address or such other Person that the Party may
            designate from time to time in accordance with this Section 16.3):
          </li>
        </ol>
        <p>
          (a) when received, if delivered by hand, with signed confirmation of
          receipt;
        </p>
        <p>
          (b) when received, if sent by a nationally recognized overnight
          courier, signature required; (c) on the third day after the date
          mailed by certified or registered mail, return receipt requested,
          postage prepaid; or
        </p>
        <p>(d) on the following business day if delivered by email.&nbsp;</p>
        <ol>
          <li>
            Interpretation. The Parties intend this Agreement to be construed
            without regard to any presumption or rule requiring construction or
            interpretation against the Party drafting an instrument or causing
            any instrument to be drafted. Further, the headings used in this
            agreement are for convenience only and are not intended to be used
            as an aid to interpretation.
          </li>
          <li>
            Entire Agreement. This Agreement constitutes the sole and entire
            agreement between the Parties with respect to the subject matter of
            this Agreement and supersedes and merges all prior and
            contemporaneous proposals, understandings, agreements,
            representations and warranties, both written and oral, between the
            Parties relating to such subject matter.
          </li>
          <li>
            Assignment. Licensee shall not assign or otherwise transfer any of
            its rights, or delegate or otherwise transfer any of its obligations
            or performance, under this Agreement, in each case whether
            voluntarily, involuntarily, by operation of law or otherwise,
            without Licensor’s prior written consent. No delegation or other
            transfer will relieve Licensee of any of its obligations or
            performance under this Agreement.&nbsp;
          </li>
          <li>
            No Third Party Beneficiaries. This Agreement is for the sole benefit
            of the Parties and, subject to Section 13 and Section 16.6, their
            respective successors and assigns, and nothing herein, express or
            implied, is intended to or shall confer upon any other Person any
            legal or equitable right, benefit or remedy of any nature whatsoever
            under or by reason of this Agreement.
          </li>
          <li>
            Waiver. No waiver by any Party of any of the provisions hereof shall
            be effective unless explicitly set forth in writing and signed by
            the Party so waiving. Except as otherwise set forth in this
            Agreement, no failure to exercise, or delay in exercising, any
            rights, remedy, power or privilege arising from this Agreement shall
            operate or be construed as a waiver thereof; nor shall any single or
            partial exercise of any right, remedy, power or privilege hereunder
            preclude any other or further exercise thereof or the exercise of
            any other right, remedy, power or privilege.
          </li>
          <li>
            Severability. If any provision of this Agreement is invalid, illegal
            or unenforceable in any jurisdiction, such invalidity, illegality or
            unenforceability shall not affect any other term or provision of
            this Agreement or invalidate or render unenforceable such term or
            provision in any other jurisdiction. Upon such determination that
            any term or other provision is invalid, illegal or unenforceable,
            the Parties shall negotiate in good faith to modify this Agreement
            so as to effect the original intent of the Parties as closely as
            possible in a mutually acceptable manner in order that the
            transactions contemplated by this Agreement be consummated as
            originally contemplated to the greatest extent possible.
          </li>
          <li>
            Governing Law; Submission to Jurisdiction. This Agreement, and any
            dispute, controversy, proceedings or claim of whatever nature
            arising out of or in any way relating to this Agreement or its
            formation (including any non-contractual disputes or claims), shall
            be governed by and construed in accordance with: a) Hong Kong law in
            case you are a resident of any county excluding EU, b) Lithuania law
            in case you are a resident of Eu without giving effect to any choice
            or conflict of law provision or rule that would require or permit
            the application of the laws of any other jurisdiction.
          </li>
          <li>
            Each of the Parties to this Agreement irrevocably agrees that the
            following courts shall have exclusive jurisdiction to hear and
            decide any suit, action or proceedings, and/or to settle any
            disputes, which may arise out of or in connection with this
            Agreement or its formation or validity: a) Hong Kong in case you are
            a resident of all countries excluding EU; b) Lithuania in case you
            are a resident of EU. Each Party irrevocably submits to the
            jurisdiction of the following courts: a) Hong Kong in case you are a
            resident of all countries excluding EU; b) Lithuania in case you are
            a resident of EU.
          </li>
          <li>
            Equitable Relief. Each Party acknowledges and agrees that a breach
            or threatened breach by such Party of any of its obligations under
            Section 10 (Confidentiality) or, in the case of Licensee, Section
            4.3 (Authorization Limitations and Restrictions), Section 5.3
            (Corrective Action and Notice), would cause the other Party
            irreparable harm for which monetary damages would not be an adequate
            remedy and agrees that, in the event of such breach or threatened
            breach, the other Party will be entitled to seek equitable relief,
            including a restraining order, an injunction, specific performance
            and any other relief that may be available from any court, without
            any requirement to post a bond or other security, or to prove actual
            damages or that monetary damages are not an adequate remedy. Such
            remedies are not exclusive and are in addition to all other remedies
            that may be available at law, in equity or otherwise.
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>

</style>
