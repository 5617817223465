<template>
  <div style="opacity: 1; transform: none">
    <div class="HhJYDC">
      <div class="wrapper">
        <div class="iVg6s0">
          <h1><strong>PRIVACY POLICY</strong></h1>
          <p>
            <strong>for AMLBot Website (</strong
            ><a href="https://amlbot.com/"
              ><strong>https://amlbot.com</strong></a
            ><strong>)</strong>
          </p>
          <h2>1. INTRODUCTION</h2>
          <ol>
            <li>
              The Company is committed to being a responsible custodian of the
              information you provide to us and the information we collect in
              the course of operating our business. This Privacy Policy
              describes how the Company may collect and process information
              received by us in association with the operation of the AMLBot
              Website (<a href="https://amlbot.com">https://amlbot.com</a>) and
              the provision of Services outlined in the
              <a href="https://amlbot.com/terms-of-service/">Terms of Use</a>.
              This Policy is addressed to the Company’s clients as well as to
              those individuals who will provide their personal data for
              processing (hereinafter – Data Subjects).
            </li>
            <li>
              Your Personal Data is processed in accordance with the
              <a
                href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02016R0679-20160504&amp;qid=1532348683434"
                >General Data Protection Regulation</a
              >
              (Regulation EU 2016/679, further - “the GDPR”), the
              <a
                href="https://www.legislation.gov.uk/ukpga/2018/12/pdfs/ukpga_20180012_en.pdf"
                >Data Protection Act 2018</a
              >
              and other relevant legislation with respect to the accepted
              principles of good information handling (collectively referred to
              as the “Data Protection Legislation”).
            </li>
            <li>
              This Privacy Policy shall be governed by the laws of Hong Kong.
            </li>
            <li>
              For all intents and purposes, the English language version of this
              Privacy Policy will be the original, governing instrument. In the
              event of any conflict between the English language version of this
              Privacy Policy and any subsequent translation into any other
              language, the English language version will govern and control.
            </li>
          </ol>
          <h2>2. DEFINITIONS</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  <strong>“Company”</strong>,<strong> “We”</strong> means: a)
                  SAFELEMENT LIMITED, a company registered in Hong Kong with
                  registration number 3148041, having its registered office at
                  Room 747, 7/F Star House 3, Salisbury Road Tst, Hong Kong in
                  case you are the resident of any country
                  <strong>excluding European Union</strong>; b) Safe3, UAB
                  having its registered office at Taikos pr. 111-74, LT-94230
                  Klaipėda in case you are
                  <strong>resident of European Union</strong>, which both
                  operate AMLBot website available at https://amlbot.com
                  (referred to as “we” and “us” hereinafter);
                </li>
                <li>
                  “<strong>Website” </strong>means the AMLBot website operated
                  by the Company and available at https://amlbot.com ;
                </li>
                <li>
                  “<strong>Privacy Policy</strong>” means the latest version of
                  the Company’s Privacy Policy which describes our policies and
                  procedures pertaining to the collection, use, and disclosure
                  of your Personal Data;
                </li>
                <li>
                  “<strong>Personal Data</strong>” means any information
                  relating to the User, which identifies or may identify the
                  User;
                </li>
                <li>
                  “<strong>User</strong>” means an individual or a legal entity
                  that has read and agreed to the Terms of Use and the Privacy
                  Policy and uses the services of AMLBot provided by the Company
                  through the Website (referred to as “you” or “yours”
                  hereinafter);
                </li>
                <li>
                  “<strong>Services</strong>” means the services of AMLBot
                  provided by the Company and available to Users via the Website
                  as set out in detail in the Terms of Use.&nbsp;
                </li>
              </ol>
            </ol>
          </ol>
          <h2>3. Scope of the Policy</h2>
          <p>
            The purpose of this policy is to ensure that the Safelement’s staff
            shall comply with the provisions of Hong Kong law and the EU GDPR
            when processing personal data. Any serious infringement will be
            treated seriously and may be considered under disciplinary
            procedures. The company adheres to the principles of data protection
            as laid down by the EU GDPR. In accordance with those principles
            personal data shall be:
          </p>
          <ul>
            <li>
              Processed fairly and lawfully and in a transparent manner in
              relation to the data subject;
            </li>
            <li>
              Processed for specified, explicit and legitimate purposes only and
              not further processed in a manner that is incompatible with those
              purposes;
            </li>
            <li>
              Adequate, relevant and limited to what is necessary in relation to
              the purposes for which they are processed;
            </li>
            <li>Accurate and up to date;</li>
            <li>
              Kept in a form which permits identification of data subjects for
              no longer than is necessary for the purposes for which the
              personal data are processed;
            </li>
            <li>Not kept longer than necessary;</li>
            <li>
              Processed in a manner that ensures appropriate security of the
              personal data;
            </li>
            <li>
              Not transferred outside the countries of the European Economic
              Area or the EU without adequate protection.
            </li>
          </ul>
          <h3>Responsibilities&nbsp;</h3>
          <p>
            (a) SAFELEMENT responsibilities. Safelement is responsible for
            establishing policies and procedures in order to comply with the EU
            GDPR.
          </p>
          <p>
            (b) Data Protection Officer’s responsibilities. Data Protection
            Officer holds responsibility for:
          </p>
          <ul>
            <li>
              drawing up guidance and promoting compliance with this policy in
              such a way as to ensure the easy, appropriate and timely retrieval
              of information;
            </li>
            <li>
              the appropriate compliance with subject access rights and ensuring
              that data is processed in accordance with the Data Protection Act
              2018 and the EU GDPR;
            </li>
            <li>
              ensuring that any data protection breaches are resolved,
              catalogued and reported appropriately in a swift manner;
            </li>
            <li>
              investigating and responding to complaints regarding data
              protection including requests to cease processing personal data.
            </li>
          </ul>
          <p>
            (c) Staff responsibilities. Staff members who process personal data
            must comply with the requirements of this policy. Staff members must
            ensure that:
          </p>
          <ul>
            <li>all personal data is kept securely;</li>
            <li>
              no personal data is disclosed either verbally or in writing,
              accidentally or otherwise, to any unauthorised third party;
            </li>
            <li>
              any queries regarding data protection, including subject access
              requests and complaints, are promptly directed to the Data
              Protection Officer;
            </li>
            <li>
              any data protection breaches are swiftly brought to the attention
              of the Governance Team and that they support the Data Protection
              Officer in resolving breaches;
            </li>
            <li>
              where there is uncertainty around a Data Protection matter advice
              is sought from the Data Protection Officer.
            </li>
          </ul>
          <p>Software and network security.&nbsp;</p>
          <p>
            The Company holds regular vulnerability scans against our full
            infrastructure. We also have external, independent, penetration
            tests conducted on a periodic basis.
          </p>
          <ul>
            <li>
              Our dashboard supports several regimes of secrecy, so that our
              clients could monitor the status of processing without learning
              any personal data of the their customers.
            </li>
            <li>
              Code changes are always peer reviewed and static source code
              reviews are performed systematically and at a high frequency.
            </li>
            <li>
              All engineering and development operations staff are regularly
              trained on system, application and network security.
            </li>
            <li>
              Our IT and container infrastructure is continuously monitored and
              audited for change.
            </li>
            <li>
              Critical systems and information are protected with strong
              authentication mechanisms.
            </li>
            <li>
              All networks connections are protected by firewalls and are
              monitored by cyber security solutions to detect intrusions and
              suspicious activity.
            </li>
            <li>
              Machine learning is used to discover malicious behaviour of
              network endpoints and applications.
            </li>
            <li>
              All our computers, laptops and servers utilise full disk/volume
              encryption and are installed with antivirus/malware protection
              which is automatically updated to the latest version and
              signatures available.
            </li>
          </ul>
          <h2>4. INFORMATION WE COLLECT</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  When you engage with us, we collect and process your Personal
                  Data, which includes as follows:
                </li>
                <ol>
                  <li>
                    Personal identification information, including: name, e-mail
                    address, phone number, country, full address, date of birth,
                    registered address, banking details;
                  </li>
                  <li>
                    Data collected in connection with “Know Your Customer” (KYC)
                    compliance, “Anti-Money Laundering” (AML) compliance and
                    “Counter-Terrorist Financing” (CTF) compliance;
                  </li>
                  <li>
                    Device and website usage data, including: IP addresses;
                    language preferences and other device identifiers;
                    information relating to your access to the Platforms, such
                    as device characteristics, date and time.
                  </li>
                  <li>
                    &nbsp;The Company subjects the personal data to automated
                    reading, verification of the authenticity and other
                    automated processing of photos and scanned copies of
                    documents and with further check against the data in
                    multiple databases, including inter alia International
                    politically exposed persons (PEPs) and Sanctions, Country
                    Specific Sanctions Lists, Criminal Lists and Financial
                    Lists. Once the personal data is not any more necessary for
                    the purposes of applicable compliance rules, the Company
                    shall erase the data completely off its servers without
                    leaving any backup copies or, based on the same condition,
                    transfer the data to the relevant Controller.
                  </li>
                </ol>
              </ol>
            </ol>
          </ol>
          <h2>5. HOW WE COLLECT YOUR DATA</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  We collect Personal Data directly from you when you use our
                  Website or services, communicate with us, or interact directly
                  with us. For example, when you complete the contact form on
                  our Website, Application or when you contact us via
                  email.&nbsp;
                </li>
                <li>
                  We use industry standards for automatically collecting certain
                  information about visitors to our Website. We collect
                  information about you, or information collected by cookies and
                  similar technologies, when you use, access, or interact with
                  our Website. We shall ensure that processing is proportionate
                  and that we have carried out a legitimate interest impact
                  assessment. To the extent that the use of cookies or similar
                  technologies requires your consent, we may also process your
                  Personal Data based on your consent.&nbsp;
                </li>
                <li>
                  We also may collect information about you from third-party
                  sources, including but not limited to, the following
                  channels:&nbsp;
                </li>
                <ol>
                  <li>marketing partners and resellers;</li>
                  <li>advertising partners and analytics providers;</li>
                  <li>
                    public databases, credit bureaus and ID verification
                    partners;
                  </li>
                  <li>social networks (for example, Twitter).&nbsp;</li>
                </ol>
                <li>
                  We protect the Personal Data obtained from third parties
                  according to the practices described in this Privacy Policy
                  and we also apply additional restrictions imposed by the
                  source of data.&nbsp;
                </li>
              </ol>
            </ol>
          </ol>
          <h2>6. DATA SUBJECT’S RIGHTS</h2>
          <p>
            Each Data Subject providing his/her personal data to the Company has
            the following rights that the Company fully respects:
          </p>
          <ul>
            <li>
              Right to obtain confirmation as to whether or not his or her
              personal data are being processed (Article 15 EU GDPR);
            </li>
            <li>
              Right to obtain rectification of inaccurate personal data without
              undue delay (Article 16 EU GDPR);
            </li>
            <li>
              Right to erase personal data or “right to be forgotten” (Article
              17 EU GDPR);
            </li>
            <li>
              Right to restrict data processing, in particular when the accuracy
              of the data is contested (Article 18 EU GDPR);
            </li>
            <li>
              Right to receive communications as to rectification or erasure of
              personal data or restriction on processing (Article 19 EU GDPR);
            </li>
            <li>
              Right to receive personal data in the form that is
              machine-¬readable and ready for transmission to another controller
              (Article 20 EU GDPR);
            </li>
            <li>Right to object data processing (Article 21 EU GDPR);</li>
            <li>
              Right not to be subject to a decision based solely on automated
              processing (Article 22 EU GDPR).&nbsp;
            </li>
          </ul>
          <h2>7. THE PURPOSES OF COLLECTING YOUR PERSONAL DATA</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  The Company collects your Personal Data for the following
                  purposes:
                </li>
                <ol>
                  <li>
                    to enable you to use our&nbsp;Website and the Services
                    provided through them, to create an account or profile, to
                    process information you provide via
                    our&nbsp;Website&nbsp;(including verifying that your email
                    address is active and valid) in accordance with&nbsp;<em
                      >Article 6(1)(a) GDPR</em
                    >;
                  </li>
                  <li>
                    to detect and prevent potentially prohibited or illegal
                    activity relating to the Company’s services in accordance
                    with&nbsp;<em>Article 6(1)(b),(c) and (f) GDPR</em>;&nbsp;
                  </li>
                  <li>
                    to tailor content, recommendations, and advertisements that
                    we and third parties display to you, both on
                    the&nbsp;Platforms&nbsp;and elsewhere online in accordance
                    with&nbsp;<em>Article 6(1)(a) GDPR</em>;
                  </li>
                  <li>
                    to contact you in response to your inquiries, comments and
                    suggestions in accordance with&nbsp;<em
                      >Article 6(1)(b) GDPR</em
                    >;
                  </li>
                  <li>
                    with your consent, to provide you with information,
                    products, or services that we otherwise believe will
                    interest you, including special opportunities from us and
                    our third-party partners in accordance with
                    <em>Article 6(1)(a) GDPR</em>;
                  </li>
                  <li>
                    to contact you with administrative communications and, in
                    our discretion, changes to our Privacy Policy, Terms of Use,
                    or any of our other policies in accordance with&nbsp;<em
                      >Article 6(1)(c) GDPR</em
                    >;
                  </li>
                  <li>
                    for internal business purposes, such as to improve
                    our&nbsp;Website or Application&nbsp;in accordance
                    with&nbsp;<em>Article 6(1)(b) GDPR</em>;&nbsp;
                  </li>
                  <li>
                    to issue invoices and collect fees in accordance
                    with&nbsp;<em>Article 6(1)(f),(b) GDPR</em>;
                  </li>
                  <li>
                    to comply with our policies and obligations, including, but
                    not limited to, disclosures and responses in response to any
                    requests from law enforcement authorities and/or regulators
                    in accordance with any applicable law, rule, regulation,
                    judicial or governmental order in accordance with&nbsp;<em
                      >Article 6(1)(c),(b) GDPR</em
                    >.
                  </li>
                </ol>
                <li>
                  Your Personal Data, whether public or private, will not be
                  sold, exchanged, transferred, or given to any other company
                  for any reason whatsoever, without your consent, other than
                  for the purpose of delivering the requested services and
                  improving our services.
                </li>
              </ol>
            </ol>
          </ol>
          <h2>8. PROVIDING YOUR PERSONAL DATA TO THE THIRD PARTIES</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  As a general principle, we collect and process Personal Data
                  in order to facilitate or improve the Company’s services or
                  offers. We do not sell your Personal Data or share it with
                  third parties, except to the extent stated in this Privacy
                  Policy.
                </li>
                <li>
                  For behaviour statistics and business intelligence we use the
                  services of Google LLC (“Google Analytics”), a company located
                  in the United States. Your Personal Data that we may provide
                  to Google Analytics may include your IP address, and that data
                  is used by Google Analytics to generate information about your
                  usage of our service.
                </li>
                <li>
                  We may share your Personal Data with the following third
                  parties:
                </li>
                <ol>
                  <li>
                    Third-party vendors providing services on our behalf,
                    including advertising, analytics, research, customer
                    service, service support, data storage, validation,
                    security, fraud prevention, and legal services. Such
                    third-party vendors have access to perform these services
                    but are prohibited from using your Personal Data for other
                    purposes;
                  </li>
                  <li>
                    External services or authorities when such disclosure is
                    necessary for compliance with a legal obligation to which we
                    are subject, or in order to protect your vital interests
                    and/or the vital interests of a third-party;
                  </li>
                  <li>Other third parties subject to your consent.</li>
                </ol>
                <li>
                  When we disclose your Personal Data to a third party, we take
                  all reasonable steps to ensure that those third parties are
                  bound by confidentiality and privacy obligations with respect
                  to the protection of your Personal Data. The disclosure is
                  conducted in compliance with legal requirements, including
                  entering into data processing agreements with the relevant
                  third parties, to ensure that Personal Data is only processed
                  in accordance with our instructions, applicable laws and
                  regulations and for the purpose specified by us and to ensure
                  adequate security measures.
                </li>
              </ol>
            </ol>
          </ol>
          <h2>9. STORAGE AND DELETION OF PERSONAL DATA</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  The Company will retain your Personal Data for as long as we
                  deem it necessary to enable you to use
                  the&nbsp;Website&nbsp;and to provide Services to you, to
                  comply with applicable laws (including those regarding
                  document retention), resolve disputes with any parties and
                  otherwise as necessary to allow us to conduct our business.
                </li>
                <li>
                  The legal basis for retaining your Personal Data is the
                  Company’s legitimate interest under&nbsp;<em
                    >GDPR Article 6(1)(f)</em
                  >&nbsp;to protect our rights in the light of potential legal
                  disputes during the limitation period under law.
                </li>
                <li>
                  If we have collected your Personal Data in relation to your
                  inquiry to us, we retain your Personal Data for up to three
                  (3) years from collection, unless the other provided by this
                  Privacy Policy.&nbsp;
                </li>
                <li>
                  Notwithstanding anything to the contrary in this Section, we
                  may retain your Personal Data where such retention is
                  necessary for compliance with a legal obligation to which we
                  are subject to, or in order to protect your vital interests or
                  the vital interests of another natural person in accordance
                  with&nbsp;<em>GDPR Article 6(1)(c)</em>.
                </li>
                <li>
                  When the Company no longer needs to keep your Personal Data,
                  it will securely delete or destroy it.
                </li>
              </ol>
            </ol>
          </ol>
          <h2>10. PROTECTION OF PERSONAL DATA</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  Your Personal data integrity is of high concern to us. We
                  follow the standard practices within the industry to protect
                  the Personal Data that we collect and maintain, including
                  using Transport Layer Security (TLS) to encrypt information as
                  it travels over the internet. We have therefore implemented
                  technology and security policies and procedures intended to
                  reduce the risk of accidental destruction or loss, or the
                  unauthorized disclosure or access to, such information,
                  reasonably appropriate to the nature of the data concerned;
                  unfortunately, however, no data transmission over the Internet
                  can be guaranteed to be 100% secure.
                </li>
                <li>
                  We implemented a number of additional security measures to
                  ensure that your Personal Data is not lost, abused, or
                  altered, including, but not limited to:
                </li>
                <ol>
                  <li>
                    Physical measures, which means that materials containing
                    your Personal Data will be stored in a locked place.
                  </li>
                  <li>
                    Electronic measures, which means that computer data
                    containing your Personal Data will be stored in the computer
                    systems and storage media that are subject to strict log-in
                    restrictions.
                  </li>
                  <li>
                    Management measures, which means that only authorized
                    employees are permitted to come into contact with your
                    Personal Data and such employees must comply with our
                    internal confidentiality rules for Personal Data. We have
                    also imposed strict physical access controls to buildings
                    and files.
                  </li>
                  <li>Technical measures.&nbsp;</li>
                </ol>
                <li>
                  If you suspect that your Personal Data has been compromised,
                  please immediately contact our Customer Support Team at
                  <a href="mailto:info@amlbot.com">info@amlbot.com</a> .
                </li>
              </ol>
            </ol>
          </ol>
          <strong>11. USER’S RIGHTS</strong>
          <ol>
            <ol>
              <ol>
                <li>
                  Users residing in certain countries, including the EU, are
                  afforded certain rights regarding their personal information:
                </li>
                <ol>
                  <li>
                    <strong>the right to access</strong>: you have the right to
                    confirmation as to whether or not we process your Personal
                    Data and, where we do, to access the Personal Data.
                    Providing that the rights and freedoms of others are not
                    affected, we will supply to you a copy of your Personal
                    Data. The first copy will be provided free of charge, but
                    additional copies may be subject to a reasonable fee;
                  </li>
                  <li>
                    <strong>the right to object to processing</strong>: you have
                    the right to object to us processing your Personal Data,
                    citing personal reasons; however, understand that we may
                    still process your Personal Data if we have lawful grounds
                    to do so, but only if our interests in processing your
                    Personal Data are not overridden by your rights, interests,
                    or freedoms;
                  </li>
                  <li>
                    <strong>the right to rectification</strong>: you have the
                    right to have any inaccurate Personal Data about you
                    rectified and, taking into account the purposes of the
                    processing, to have any incomplete Personal Data about you
                    completed;
                  </li>
                  <li>
                    <strong>the right to data portability</strong>: you have the
                    right to obtain and reuse your Personal Data for your own
                    purposes across different services. It allows you to move,
                    copy or transfer Personal Data easily from one IT
                    environment to another in a safe and secure way, without
                    hindrance to usability;
                  </li>
                  <li>
                    <strong>the right to erasure</strong>: you have the right to
                    request that the Company erase your Personal Data under
                    certain conditions;
                  </li>
                  <li>
                    <strong>the right to restrict processing</strong>: you have
                    the right to request that the Company restrict the
                    processing of your Personal Data under certain conditions;
                  </li>
                  <li>
                    <strong>the right to withdraw consent</strong>: to the
                    extent that the legal basis for our processing of your
                    Personal Data is consent, you have the right to withdraw
                    that consent at any time. However, withdrawal will not
                    affect the lawfulness of processing of your Personal Data
                    before the withdrawal.
                  </li>
                </ol>
                <li>
                  You may exercise any of your rights in relation to your
                  Personal Data by contacting our Customer Support. You must
                  note that prior to accessing and making changes to your
                  Рersonal Data, we will need to verify your identity properly.
                </li>
                <li>
                  We will aim to respond to your requests regarding your
                  Personal Data within 1 (one) month of receipt of any such
                  request.
                </li>
              </ol>
            </ol>
          </ol>
          <strong>12. INTERNATIONAL TRANSFER OF PERSONAL DATA</strong>
          <ol>
            <ol>
              <ol>
                <li>
                  We may need to transfer your Рersonal Data to countries which
                  are located outside the European Economic Area (“EEA”), for
                  the purpose of providing the Services to you. You may be
                  located in a country outside of the EEA and therefore we may
                  have no choice but to transfer your Personal Data outside of
                  the EEA.&nbsp;
                </li>
                <li>
                  Any transfer of your personal information outside of the EEA
                  will be subject to a GDPR-compliant guarantee (such a Model
                  Contract Clause approved by the European Commission) that will
                  safeguard your privacy rights and give you remedies in the
                  unlikely event of a security breach.
                </li>
              </ol>
            </ol>
          </ol>
          <h2>13. COOKIES POLICY</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  We use cookies and similar technologies like pixels, tags, and
                  other identifiers in order to remember your preferences, to
                  understand how our Website is used, and to customize our
                  marketing offerings.
                </li>
                <li>
                  A cookie is a small data file containing a string of
                  characters that is sent to your computer when you visit a
                  website. When you visit the websites again, the cookie allows
                  that site to recognize your browser. The length of time a
                  cookie will stay on your computer or mobile device depends on
                  whether it is a “persistent” or “session” cookie. For further
                  information regarding cookies, visit
                  <a href="https://www.allaboutcookies.org/"
                    >allaboutcookies.org</a
                  >.
                </li>
                <li>
                  We use the following types of cookies on our Website:&nbsp;
                </li>
                <ol>
                  <li>
                    <strong>Strictly necessary cookies</strong>: these are
                    essential for you to browse our Website and use its
                    features. Without these cookies, some online services cannot
                    be provided.
                  </li>
                  <li>
                    <strong>Performance cookies</strong>: these collect
                    information about how you use our Website. This data may be
                    used to help optimize our Website and make it easier for you
                    to navigate.
                  </li>
                  <li>
                    <strong>Functional cookies</strong>: these allow our Website
                    to remember the choices you make while browsing the Website
                    and to personalize your experience.&nbsp;
                  </li>
                  <li>
                    <strong>Third-party cookies</strong>: these are placed by
                    websites and/or parties other than us. These cookies may be
                    used on our Website to improve our services or to help us
                    provide more relevant advertising. These cookies are subject
                    to the respective privacy policies for the relevant external
                    services.
                  </li>
                  <li>
                    <strong>Analytics cookies</strong>: these are offered by
                    services like Google Analytics, to help us understand how
                    long a visitor stays on our Website, what pages they find
                    most useful, and how they arrived at
                    <a href="https://amlbot.com/">https://amlbot.com/</a> .
                  </li>
                </ol>
                <li>
                  Most web browsers allow you to control cookies through their
                  settings preferences. However, if you limit the ability of our
                  Website to set cookies, you may impair your overall user
                  experience, as it will no longer be personalized to you.&nbsp;
                </li>
                <li>
                  In addition to cookies, we sometimes use small graphics images
                  known as pixels (also known as web beacons, clear GIFs, or
                  pixel tags). We use pixels in our email communications to you
                  (if you have selected to receive such communications) to help
                  us to understand whether our email communication has been
                  viewed. We also use third-party pixels (such as those from
                  Google, YouTube, and other networks) to help us provide
                  advertising that is relevant to your interests.
                </li>
              </ol>
            </ol>
          </ol>
          <h2>14. CHANGES TO THE PRIVACY POLICY</h2>
          <ol>
            <ol>
              <ol>
                <li>
                  We may update this Privacy Policy from time to time and we
                  encourage you to periodically review this page. If we make any
                  material changes in the way we collect, use, and/or share your
                  Personal Data, we will notify you by posting notice of the
                  changes in a clear and conspicuous manner on the Wallet
                  Checker Website at
                  <a href="https://amlbot.com">https://amlbot.com</a> .
                </li>
              </ol>
            </ol>
          </ol>
          <h2>15. CONTACT INFORMATION</h2>
          <ol>
            <ol>
              <li>
                Should you have any questions regarding this Privacy Policy,
                please do not hesitate to contact us at
                <a href="mailto:info@amlbot.com">info@amlbot.com</a> .
              </li>
            </ol>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.HhJYDC {
    margin-bottom: 16rem;
    padding-top: 12.3rem;
}
.iVg6s0 h1 {
    margin: 0 0 3rem;
    font-size: 5rem;
    line-height: 120%;
}
.iVg6s0 p {
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 150%;
}
.iVg6s0 a {
    color: #0057ff;
}
.iVg6s0 h2 {
    margin: 5rem 0 3rem;
    font-size: 3rem;
    line-height: 120%;
}
.iVg6s0 ol:not([type]) {
    list-style: decimal;
}
.iVg6s0 ol {
    margin-top: 2rem;
    margin-bottom: 3rem;
    margin-left: 3rem;
}
.iVg6s0 ol li:not(:last-child) {
    margin-bottom: 1.5rem;
}
.iVg6s0 ol li {
    font-size: 2rem;
    line-height: 150%;
}
.iVg6s0 ul {
    margin-top: 2rem;
    margin-bottom: 3rem;
    margin-left: 3rem;
    list-style: disc;
}
.iVg6s0 ul li:not(:last-child) {
    margin-bottom: 1.5rem;
}
.iVg6s0 ul li {
    font-size: 2rem;
    line-height: 150%;
}
.iVg6s0 h3 {
    margin: 4rem 0 2rem;
    font-size: 2.4rem;
    line-height: 120%;
}
</style>
